import { Container, PageWrapper } from '@qonsoll/react-design'

import { CONTENT_TYPES } from '__constants__'
import { Content } from 'components'

const TermsAndConditionsOfUse = () => {
  return (
    <PageWrapper
      headingProps={{ title: 'Terms and Conditions of Use', titleSize: 3 }}>
      <Container bounded>
        <Content contentType={CONTENT_TYPES.TERMS_OF_SERVICE} />
      </Container>
    </PageWrapper>
  )
}

export default TermsAndConditionsOfUse
