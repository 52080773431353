import { Account, Box, Text } from '@qonsoll/react-design'

import { Grid } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { avatarLetterFromNameOrEmail } from 'helpers'
import { useGetAccountMenuItems } from './hooks'
import { useTranslations } from 'contexts/Translation'

const AccountMenu = ({ id, avatar, displayName, email }) => {
  const { t } = useTranslations()
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()
  const avatarFirstLetter = avatarLetterFromNameOrEmail(displayName, email)

  const menu = useGetAccountMenuItems(id)

  return (
    <Account
      menuTrigger="click"
      avatar={avatar}
      avatarText={
        <Text
          type="secondary"
          color="var(--ql-header-avatar-text-color)"
          isEllipsis>
          {avatarFirstLetter}
        </Text>
      }
      isEllipsis
      menu={menu}
      menuPlacement="topRight"
      menuArrow={true}
      title={
        <Box display="flex" maxWidth={['130px', '150px', '300px']}>
          {!screens?.xs && (
            <Text
              type="secondary"
              color="var(--ql-header-text-color)"
              isEllipsis>
              {displayName || t('No name')}
            </Text>
          )}
          <Icon
            name="ArrowShortDownFilled"
            size={24}
            pl={[1, 1, 3]}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fill="currentColor"
          />
        </Box>
      }
    />
  )
}

AccountMenu.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string
}

export default AccountMenu
