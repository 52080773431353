import { useCallback } from 'react'
import { STEP_NAMES } from 'domains/NewsTip/components/NewsTipWizard/constants'
import { useDebounceUpdateTips } from 'domains/NewsTip/hooks'
import { useNewspaperConfig } from 'contexts'

const useTagsBaseActions = ({ value, setValue }) => {
  const { newspaperId } = useNewspaperConfig()

  // Getting  the debounce call
  const { debouncedSendRequest } = useDebounceUpdateTips()

  /* Add tags */
  const handleAdd = useCallback(
    (addTag) => {
      if (value?.tags?.includes?.(addTag)) return

      setValue((prev) => {
        const computedSuggestions = prev?.suggestions?.filter(
          (tag) => tag !== addTag
        )

        const tags = [...(prev?.tags || []), addTag]

        debouncedSendRequest({
          data: { ...value, newspaperId, tags },
          step: STEP_NAMES.TAGS,
          newsTipId: value?._id
        })

        return {
          ...prev,
          tags,
          suggestions: [...computedSuggestions]
        }
      })
    },
    [setValue, value?.tags]
  )

  /* Remove tags */
  const handleRemove = useCallback(
    (removeTag) => {
      setValue((prev) => {
        const newTags = [...(prev?.tags || [])]?.filter(
          (item) => item !== removeTag
        )

        const suggestions = prev?.suggestions

        let computedSuggestions = [...(prev?.suggestions || [])]

        if (!suggestions?.includes(removeTag)) {
          computedSuggestions = [...(prev?.suggestions || []), removeTag]
        }

        const tags = [...newTags]

        debouncedSendRequest({
          data: { ...value, newspaperId, tags },
          step: STEP_NAMES.TAGS,
          newsTipId: value?._id
        })

        return {
          ...prev,
          tags,
          suggestions: [...computedSuggestions]
        }
      })
    },
    [setValue]
  )
  return { handleAdd, handleRemove }
}

export default useTagsBaseActions
