import { Col, Container, Row } from '@qonsoll/react-design'
import { MainMenuWrapper, StyledMenu, StyledMenuItem } from './MainMenu.styles'

import PropTypes from 'prop-types'
import { UserBalanceSimpleView } from 'domains/User/components'
import { useGetMenuItems } from './hooks'
import { useGetSelectedMenuItem } from 'components/BoilerplateLayout/components/MainMenu/hooks'
import { useUser } from 'domains/User/context'

const MainMenu = (props) => {
  const { isAuthenticated = false } = props

  const menuItems = useGetMenuItems()
  const selectedMenuItem = useGetSelectedMenuItem()
  const { points: pointsGiven } = useUser()

  return (
    <MainMenuWrapper>
      {isAuthenticated && (
        <Container bounded>
          <Row h="between" v="center" flexWrap="nowrap" noOuterGutters>
            <Col ml={[3, 0]}>
              <StyledMenu
                mode="horizontal"
                defaultSelectedKeys={[selectedMenuItem]}
                selectedKeys={selectedMenuItem}>
                {menuItems?.map((item) => (
                  <StyledMenuItem {...item} key={item?.key}>
                    {item?.text}
                  </StyledMenuItem>
                ))}
              </StyledMenu>
            </Col>

            <Col cw="auto" mr={[3, 0]}>
              <UserBalanceSimpleView pointsGiven={pointsGiven || 0} />
            </Col>
          </Row>
        </Container>
      )}
    </MainMenuWrapper>
  )
}
MainMenu.propTypes = {
  pointsGiven: PropTypes.object,
  menu: PropTypes.object,
  isAuthenticated: PropTypes.bool
}
export default MainMenu
