const ENVIRONMENTS = {
  DEVELOPMENT: 'DEVELOPMENT',
  PRODUCTION: 'PRODUCTION',
  IS_DEV: process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT',
  IS_PROD: process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION'
}

export default ENVIRONMENTS

export { ENVIRONMENTS }
