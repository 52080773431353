import 'firebase/compat/firestore'

import { COLLECTIONS, STATUSES } from '__constants__'
import {
  getDocument,
  updateDocument,
  createDocument
} from 'services/api/firebase'
import { useEffect, useMemo, useState } from 'react'

import NewsTipContext from './NewsTipContext'
import PropTypes from 'prop-types'
import { useUser } from 'domains/User/context'

const NewsTipProvider = ({ children }) => {
  const { user } = useUser()

  /* Create a state for unauthorized user tip data */
  const [draftTipData, setDraftTipData] = useState(null)

  // Preparing context
  const contextData = useMemo(() => {
    return {
      draftTipData,
      setDraftTipData
    }
  }, [draftTipData])

  useEffect(() => {
    const finishWithDraftTip = async (draftTipData) => {
      const { DRAFT, PENDING } = STATUSES.NEWS_TIP_STATUSES

      const draftTipId = draftTipData?._id

      /* The code is using the `getDocument` function from the `firebase` service to retrieve the data
			of a specific news tip from the Firestore database. */
      const draftTipDataFromDatabase = await getDocument(
        COLLECTIONS.NEWS_TIPS,
        draftTipId
      )

      // Check if document exists
      if (Object.keys(draftTipDataFromDatabase || {})?.length > 0) {
        /* The code block is updating the document in the Firestore database for a specific news tip. */
        if (draftTipDataFromDatabase?.status === DRAFT) {
          await updateDocument(COLLECTIONS.NEWS_TIPS, draftTipId, {
            ...draftTipDataFromDatabase,
            // If a user creates or already has an account and wants to receive coins
            isAnonymous: draftTipData?.isAnonymous || false,
            _createdBy: user?._id, // enabling displaying of newly created tip
            status: PENDING // forcing new status, just in case
          })
        }
      } else {
        await createDocument(COLLECTIONS.NEWS_TIPS, draftTipId, {
          ...draftTipData,
          _createdBy: user?._id, // enabling displaying of newly created tip
          status: PENDING // forcing new status, just in case
        })
      }

      /* clearing of the context after function was triggered once */
      setDraftTipData(null)
    }

    if (draftTipData && user) {
      finishWithDraftTip(draftTipData)
    }
  }, [draftTipData, user])

  return (
    <NewsTipContext.Provider value={contextData}>
      {children}
    </NewsTipContext.Provider>
  )
}

NewsTipProvider.propTypes = { children: PropTypes.node }

export default NewsTipProvider
