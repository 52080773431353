import { ENDPOINTS, SESSION_ERROR_CODES_AND_TEXT_MAP } from '__constants__'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

import { message } from 'antd'
import { useConfirmation } from 'domains/Confirmation/context'
import { useLoginWithAnotherRole } from 'domains/Session/hooks'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'

const useSignInWithCredentials = () => {
  const [loading, setLoading] = useState(false)
  const loginWithAnotherRole = useLoginWithAnotherRole()
  const { setIsUserFinishedConfirmation } = useConfirmation()

  const { t } = useTranslations()

  const signInWithCredentials = async (credentials) => {
    // Used this variable to track error code, especially wrong password code
    let signInError = null

    try {
      setLoading(true)
      setIsUserFinishedConfirmation(false)

      const auth = getAuth()
      const { login, password } = credentials

      const isEmail = login?.includes('@')
      const isPhone = login?.includes('+')

      if (isEmail) {
        const authResult = await signInWithEmailAndPassword(
          auth,
          login,
          password
        )

        if (authResult.user?.email && authResult.user?.uid) {
          await loginWithAnotherRole({ email: login, uid: authResult.user.uid })
        }
      } else if (isPhone) {
        const response = await fetch(ENDPOINTS.LOGIN_WITH_PHONE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ phone: login })
        })

        const responseData = await response.json()
        const { data } = responseData || {}

        if (data) {
          await signInWithEmailAndPassword(auth, data, password)
        }
      }
    } catch (e) {
      signInError = e
      let errorMessage = SESSION_ERROR_CODES_AND_TEXT_MAP[e?.code]
      if (!errorMessage)
        errorMessage = `${t('Something went wrong during login')}, ${
          e?.message
        }`

      // Second param is duration in seconds.
      // to make possible read whole error message
      // before disappear
      message.error(errorMessage, 5)
    } finally {
      setIsUserFinishedConfirmation(true)
      setLoading(false)
    }
    return signInError
  }

  return { signInWithCredentials, loading }
}
export default useSignInWithCredentials
