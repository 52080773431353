import { COLLECTIONS, POINTS_OPERATION_TYPES } from '__constants__'
import { collection, query, where } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const useGetSpentPointsHistory = ({ oldestNewsTip, userId, newspaperId }) => {
  let pointsHistoryQuery
  if (userId && newspaperId && oldestNewsTip?._approvedAt) {
    pointsHistoryQuery = query(
      collection(firestore, COLLECTIONS.POINTS_HISTORIES),
      where('userId', '==', userId),
      where('newspaperId', '==', newspaperId),
      where('operationType', '==', POINTS_OPERATION_TYPES.SPENT),
      where('isCountedInPointsExpiration', '==', false),
      where('_createdAt', '>=', oldestNewsTip?._approvedAt)
    )
  }
  return useCollectionData(pointsHistoryQuery)
}

export default useGetSpentPointsHistory
