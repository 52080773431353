import { model, attr, hasOne, hasMany } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  newspaper: yup
    .string()
    .typeError('Field newspaper has wrong type')
    .default(null)
    .nullable(),
  totalCoupons: yup
    .number()
    .typeError('Field totalCoupons has wrong type')
    .default(null)
    .nullable(),
  redeemed: yup
    .boolean()
    .typeError('Field redeemed has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  coupons: yup
    .array()
    .typeError('Field coupons has wrong type')
    .default(null)
    .nullable()
})

const Store = model(
  'store',
  {
    sno: attr('number'),
    name: attr('string'),
    newspaper: hasOne('newspaper'),
    totalCoupons: attr('number'),
    redeemed: attr('boolean'),
    status: hasOne('storeStatus'),
    coupons: hasMany('coupon')
  },
  validationSchema
)

export default Store
