import { Button, Input, PhoneInput } from '@qonsoll/react-design'

import { Form, message } from 'antd'
import PATHS from 'pages/paths'
import { MAX_LENGTH_FIELD, STATUSES } from '__constants__'
import { useHistory } from 'react-router-dom'
import { useNewsTip } from 'domains/NewsTip/context'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useNewspaperConfig } from 'contexts'
import { useDebounceUpdateTips } from 'domains/NewsTip/hooks'
import { STEP_NAMES } from '../NewsTipWizard/constants'
import { useState } from 'react'

const NewsTipConfirmGuestInfoSimpleForm = () => {
  const { UNAUTHENTICATED } = PATHS

  /* Getting translations instance */
  const { t } = useTranslations()

  const [loading, setLoading] = useState(false)

  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()
  const { draftTipData } = useNewsTip()
  const { validatePhoneNumber, transformPhoneNumber } = usePhoneNumber()
  const { newspaperId } = useNewspaperConfig()

  // Getting call to update api
  const { sendRequest } = useDebounceUpdateTips()

  const handleAnonymousUserFormSubmissionFinish = async ({
    phoneNumber,
    guestFirstName,
    guestLastName
  }) => {
    try {
      setLoading(true)
      const { PENDING } = STATUSES.NEWS_TIP_STATUSES

      const { _id } = draftTipData // id of current tip, from the context

      const guestPhoneNumber = transformPhoneNumber(phoneNumber)

      //  To set users phone number and update the status of the old Draft tips
      await sendRequest({
        data: {
          ...draftTipData,
          isAnonymous: true,
          newspaperId,
          guestPhoneNumber,
          guestFirstName,
          guestLastName,
          status: PENDING
        },
        step: STEP_NAMES.ANONYMOUS_USER_FINISH,
        newsTipId: _id
      })

      // redirect to the info screen
      history.push(UNAUTHENTICATED.NEWS_TIP_SUCCESSFULLY_CREATED)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Form
      validateTrigger={['submit']}
      onFinish={handleAnonymousUserFormSubmissionFinish}>
      <Form.Item
        name="phoneNumber"
        initialValue="+47"
        rules={[
          {
            required: true,
            message: t('Please enter phone number')
          },
          () => ({
            validator(_, value) {
              if (validatePhoneNumber(value).valid) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t('Invalid phone number')))
            }
          })
        ]}>
        <PhoneInput
          country="no"
          preferredCountries={['no']}
          specialLabel=""
          autoFormat
          placeholder={t('Phone number')}
        />
      </Form.Item>
      <Form.Item
        name="guestFirstName"
        rules={[
          {
            required: false,
            message: t('Please enter your first name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('First name is too long!') }
        ]}>
        <Input placeholder={t('First name')} />
      </Form.Item>
      <Form.Item
        name="guestLastName"
        rules={[
          {
            required: false,
            message: t('Please enter your last name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('Last name  is too long!') }
        ]}>
        <Input placeholder={t('Last name')} />
      </Form.Item>

      <Button
        id="submit-phone-number-of-anonymous-user-button"
        type="primary"
        htmlType="submit"
        size="large"
        loading={loading}
        block>
        {t('Submit')}
      </Button>
    </Form>
  )
}

NewsTipConfirmGuestInfoSimpleForm.propTypes = {}

export default NewsTipConfirmGuestInfoSimpleForm
