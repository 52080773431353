import { Modal } from 'antd'
import { useState } from 'react'

const useGetUploadMediaPreview = () => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file?.url || file?.preview)
    setPreviewOpen(true)
  }

  const handleCancel = () => setPreviewOpen(false)

  const modal = (
    <Modal
      visible={previewOpen}
      onOk={handleCancel}
      okText="Close"
      cancelButtonProps={{ style: { display: 'none' } }}>
      <img
        alt="example"
        style={{
          width: '100%',
          height: '100%'
        }}
        src={previewImage}
      />
    </Modal>
  )
  return { modal, handlePreview }
}

export default useGetUploadMediaPreview
