import { useTranslations } from 'contexts/Translation'
import { Row, Col, Text } from '@qonsoll/react-design'
import { RewardSimpleView } from 'domains/Reward/components'
import PropTypes from 'prop-types'

const RewardList = (props) => {
  const { rewards, listView, emptyProps, actions, cw = [12, 6, 4, 3] } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Checking if list is empty */
  const isEmpty = !rewards?.length
  /* Computing item width */
  const itemWidth = listView ? 12 : cw

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {!isEmpty &&
        rewards.map((reward, index) => (
          <Col mb={4} key={reward?._id || index} cw={itemWidth}>
            <RewardSimpleView actions={actions} index={index} reward={reward} />
          </Col>
        ))}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" type="secondary">
            {emptyProps?.message || t('No rewards have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

RewardList.propTypes = {
  rewards: PropTypes.array,
  isListWithCreate: PropTypes.bool,
  listView: PropTypes.bool,
  emptyProps: PropTypes.object,
  actions: PropTypes.any,
  cw: PropTypes.any
}

export default RewardList
