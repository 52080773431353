import { deleteObject, ref } from 'firebase/storage'

import { message } from 'antd'
import { storage } from 'services/firebase'
import { useTranslations } from 'contexts/Translation'

const useRemoveFileFromStorage = () => {
  const { t } = useTranslations()

  return (fileUrl) => {
    const storageRef = ref(storage, fileUrl)
    deleteObject(storageRef).catch((error) => {
      message.error(t('Some problem with deleting files'))
    })
  }
}

export default useRemoveFileFromStorage
