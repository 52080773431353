import { useLoginWithAnotherRole, useOldUserLogin } from 'domains/Session/hooks'
import { useCreateUser } from 'domains/User/hooks'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { COLLECTIONS } from '__constants__'

const useSignInWithProviderCredentials = () => {
  const oldUserLogin = useOldUserLogin()
  const createUser = useCreateUser()
  const loginWithAnotherRole = useLoginWithAnotherRole()
  const signInWithProviderCredentials = async (
    userAuthSnapshot,
    appleAuthData
  ) => {
    try {
      const firestore = getFirestore()

      const { uid, email, displayName } = userAuthSnapshot?.user || {}

      let isOldUser = false
      if (email) {
        isOldUser = await oldUserLogin({ email })
      }

      if (!isOldUser) {
        const [firstName, lastName] = displayName?.split(' ') || []

        await createUser(
          uid,
          {
            ...userAuthSnapshot?.user,
            _id: uid,
            firstName,
            lastName
          },
          appleAuthData
        )
      } else {
        if (userAuthSnapshot?.user?.email && userAuthSnapshot?.user?.uid) {
          await loginWithAnotherRole({ uid, email })
        }
      }

      const userDocReference = doc(firestore, COLLECTIONS.USERS, uid)
      const userSnapshot = await getDoc(userDocReference)
      const userData = userSnapshot.data() || {}

      return {
        user: { ...userData, id: uid, userID: uid },
        accountCreated: false
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  return signInWithProviderCredentials
}

export default useSignInWithProviderCredentials
