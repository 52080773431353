import { Col, Container, Row } from '@qonsoll/react-design'

import { LocationForm } from 'domains/Location/components'
import PropTypes from 'prop-types'
import { STEP_NAMES } from '../constants'
import { useDebounceUpdateTips } from 'domains/NewsTip/hooks'
import { useNewspaperConfig } from 'contexts'

const LocationStep = ({ value, setValue }) => {
  const { newspaperId } = useNewspaperConfig()
  // Getting  the debounce call
  const { debouncedSendRequest } = useDebounceUpdateTips()

  const handleChange = (location) => {
    setValue((prev) => ({
      ...prev,
      location: { ...location } ?? null
    }))

    const locationId = location?.locationId ?? null

    debouncedSendRequest({
      data: { ...value, newspaperId, locationId },
      step: STEP_NAMES.LOCATION,
      newsTipId: value?._id
    })
  }

  return (
    <Container mb={4}>
      <Row noGutters>
        <Col cw={12}>
          <LocationForm value={value?.location} onChange={handleChange} />
        </Col>
      </Row>
    </Container>
  )
}

LocationStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func
}

export default LocationStep
