import { useTranslations } from '@qonsoll/translation'
import { message } from 'antd'
import { getAuth, fetchSignInMethodsForEmail } from 'firebase/auth'
import { useCallback, useEffect, useState } from 'react'

const useGetLinkedProviders = () => {
  const [linkedProviders, setLinkedProviders] = useState([])
  const [providersLoading, setProvidersLoading] = useState(false)

  const auth = getAuth()
  const { t } = useTranslations()

  const getLinkedProviders = useCallback(async () => {
    try {
      setProvidersLoading(true)

      const currentUser = auth.currentUser

      const signInMethods = await fetchSignInMethodsForEmail(
        auth,
        currentUser.email
      )

      signInMethods && setLinkedProviders(signInMethods)
    } catch (error) {
      message.error(
        `${t('Something went wrong during fetching auth providers')}, ${
          error?.message
        }`
      )
    } finally {
      setProvidersLoading(false)
    }
  }, [])

  useEffect(() => {
    auth?.currentUser?.email && getLinkedProviders()
  }, [auth?.currentUser?.email])

  return { linkedProviders, providersLoading, getLinkedProviders }
}

export default useGetLinkedProviders
