import { CURRENT_APP, LANGUAGES } from 'domains/Translation/__constants__'
import { ENVIRONMENTS, LS } from '__constants__'
import Provider, { TranslationContext } from '@qonsoll/translation'
import { onValue, ref, update } from 'firebase/database'
import {
  useGetLanguages,
  useGetTranslationLoading
} from 'domains/Translation/hooks'

import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { database } from 'services/firebase'
import firebase from 'firebase/compat/app'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'

const REACT_APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

const TranslationsProvider = (props) => {
  const { children } = props

  const [languages, languagesLoading] = useGetLanguages()
  const [translationLoadingConfig, translationLoadingConfigLoading] =
    useGetTranslationLoading()
  const { app = {} } = useNewspaperConfig()
  const newspaperDefaultLanguage = app?.defaultLanguage || null

  const language = newspaperDefaultLanguage?.value
  const db = useMemo(() => firebase.database(), [])
  const loadingText = useMemo(
    () =>
      translationLoadingConfigLoading
        ? 'Loading data for translation'
        : translationLoadingConfig?.[localStorage.getItem(LS.LANGUAGE)],
    [translationLoadingConfig, translationLoadingConfigLoading]
  )

  const initialLanguages = useMemo(
    () => (languagesLoading ? LANGUAGES : languages),
    [languages, languagesLoading]
  )

  // Set saveNewAutomatically = true if current env is dev, else - set saveNewAutomatically = false
  const saveNewAutomatically =
    REACT_APP_ENVIRONMENT === ENVIRONMENTS.DEVELOPMENT

  // Function is used to read translation, may has listener
  // try/catch block is over handleRead function
  const handleRead = ({ ref: path, setTranslations, options }) => {
    onValue(
      ref(database, path),
      (snapshot) => setTranslations(snapshot?.val() || {}),
      options
    )
  }

  // Function is used to save translation or errors
  // try/catch block is over handleWrite function

  const handleWrite = async ({ ref: reference, value }) => {
    await update(ref(database, reference), value)
  }

  const isAllDataLoaded = useLoading([
    !languagesLoading,
    !translationLoadingConfigLoading
  ])

  return isAllDataLoaded ? (
    <Provider
      languages={initialLanguages}
      defaultLanguage={language}
      currentApp={CURRENT_APP}
      db={db}
      onRead={handleRead}
      onWrite={handleWrite}
      saveNewAutomatically={saveNewAutomatically}>
      <TranslationContext.Consumer>
        {({ loaded }) => (
          <Box width="inherit" height="inherit">
            {!loaded ? (
              <Box
                position="fixed"
                bg="white"
                width="100vw"
                height="100vh"
                zIndex={1000}>
                <Spinner
                  text={loadingText}
                  backgroundColor="var(--ql-body-bg)"
                />
              </Box>
            ) : (
              children
            )}
          </Box>
        )}
      </TranslationContext.Consumer>
    </Provider>
  ) : (
    <Box position="fixed" bg="white" width="100vw" height="100vh" zIndex={1000}>
      <Spinner text={loadingText} backgroundColor="var(--ql-body-bg)" />
    </Box>
  )
}

TranslationsProvider.propTypes = {
  children: PropTypes.any
}

export default TranslationsProvider
