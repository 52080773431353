import { Upload, message } from 'antd'

import { ALLOWED_FILE_FORMATS } from '__constants__'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'

const useBeforeUpload = () => {
  const { t } = useTranslations()

  return useCallback(
    (file) => {
      const isAllowedType =
        file.type.includes('video') ||
        ALLOWED_FILE_FORMATS?.some((format) => file?.type?.includes(format))

      const fileFormat = file?.type?.split('/')?.at(-1)
      if (!isAllowedType) {
        message.error(`*${fileFormat}* ${t('is not allowed format file')}`)
      }

      return isAllowedType || Upload.LIST_IGNORE
    },
    [t]
  )
}

export default useBeforeUpload
