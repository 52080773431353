import { COLLECTIONS, STATUSES } from '__constants__'
import {
  createDocument,
  getDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import PATHS from 'pages/paths'
import { Tip } from 'models'
import firebase from 'firebase/compat/app'
import { message } from 'antd'
import moment from 'moment'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useHistory } from 'react-router-dom'
import { useNewspaperConfig } from 'contexts'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'
import { useVerifyCaptchaPublicWizard } from 'domains/NewsTip/hooks'

const useActionsNewsTipAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)

  const { user } = useUser() || {}
  const { newspaperId } = useNewspaperConfig()

  /* Getting translations instance */
  const { t } = useTranslations()

  const { executeRecaptcha } = useGoogleReCaptcha()
  const { verifyToken } = useVerifyCaptchaPublicWizard()

  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    const { newsTip, isGuestMode } = values

    /* Getting id */
    const newsTipId =
      initialData?.['newsTip']?._id ||
      values?.newsTip?._id ||
      getId(COLLECTIONS.NEWS_TIPS)

    const computedPhotosUrls = newsTip?.media?.files
      ?.map((item) => (item?.type?.includes('image') ? item?.url : false))
      ?.filter(Boolean)

    const computedVideoUrl = newsTip?.media?.files?.find((item) =>
      item?.type?.includes('video')
    )?.url

    const creationTimestamp = firebase.firestore.FieldValue.serverTimestamp()
    const { PENDING } = STATUSES.NEWS_TIP_STATUSES

    const createdByComputed = isGuestMode ? null : user?._id

    /* Preparing tip values */
    const preparedValues = {
      _id: newsTipId,
      ...additionalValues,
      _createdAtMilliSeconds: moment(creationTimestamp).unix(),
      _createdBy: createdByComputed ?? null,
      isCreatedFromWeb: true, // this should be false on mobile for data consistency
      isGuestMode: !!isGuestMode,
      guestPhoneNumber: null,
      newspaperId: newspaperId ?? null,
      title: newsTip?.title ?? null,
      locationId: newsTip?.location?.locationId ?? null,
      status: PENDING ?? null,
      photoUrls: computedPhotosUrls ?? [],
      videoUrl: computedVideoUrl ?? null,
      tags: newsTip?.tags ?? [],
      arePointsExpired: false,
      isAnonymous: newsTip?.isAnonymous ?? false,
      content: newsTip?.content ?? null,
      sno: null,
      rejectReason: null,
      points: 0
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      if (!newspaperId) {
        message.error(t('Please select a newspaper'))
        return
      }

      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent) updateParent(historyState.parent, data._id)

      /* verification of the captcha token START */
      let captchaVerificationError = null
      const token = await executeRecaptcha('submitPublicWizardTips')

      if (!token) {
        captchaVerificationError = t(
          'Token was not obtained, please try again later'
        )
        callback?.(data, captchaVerificationError)

        return
      }

      const { data: verifyTokenData, message: verifyTokenMessage } =
        await verifyToken(token)

      if (!verifyTokenData?.isValid) {
        captchaVerificationError = [
          t('Invalid token'),
          verifyTokenMessage
        ].join(' ')

        callback?.(data, captchaVerificationError)

        return
      }
      /* verification of the captcha token END */

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.NEWS_TIPS,
          initialData.newsTip?._id,
          data
        )

        message.success(t('Tip successfully updated'))
      } else {
        if (!values?.isGuestMode) {
          const draftTipInDatabase = await getDocument(
            COLLECTIONS.NEWS_TIPS,
            data._id
          )

          // If document exist in database,and user finish summary step
          if (Object.values(draftTipInDatabase)?.length > 0) {
            await updateDocument(COLLECTIONS.NEWS_TIPS, values.newsTip?._id, {
              isAnonymous: data?.isAnonymous,
              status: STATUSES.NEWS_TIP_STATUSES.PENDING,
              _createdBy: user?._id
            })
          } else {
            // usual flow if it's not a guest mode
            await createDocument(COLLECTIONS.NEWS_TIPS, data, data._id)
          }

          message.success(t('Tip successfully created'))
        }
      }

      // Final callback
      callback?.(data, captchaVerificationError)
    } catch (error) {
      // Final callback
      callback?.(null, t('Something went wrong during data save'))
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    } finally {
      setLoading(false)
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        title: values['newsTip']?.['title'],
        sno: values['newsTip']?.['sno'],
        name: values['newsTip']?.['name'],
        location: values['newsTip']?.['location']
      }
      Tip.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Tip validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Save data
      await saveForm(formValues)
      // Final callback
      history.push(PATHS.AUTHENTICATED.NEWS_TIP_SUCCESSFULLY_CREATED)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsNewsTipAdvancedForm
