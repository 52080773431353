import { Redirect, Route, Switch } from 'react-router-dom'

import { BoilerplateLayout } from 'components'
import ConfirmEmail from './ConfirmEmail'
import ConfirmPhone from './ConfirmPhone'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import LoginWithEmail from './LoginWithEmail'
import PATHS from '../paths'
import SignUpWithEmail from './SignUpWithEmail'
import TermsAndConditionsOfUse from '../Service/TermsAndConditionsOfUse'
import firebase from 'firebase/compat/app'
import { useConfirmation } from 'domains/Confirmation/context'
import { useMemo } from 'react'
import {
  NewsTipWizard,
  NewsTipShow,
  NewsTipSuccessfullyCreated
} from './NewsTip'

const {
  LOGIN,
  LOGIN_WITH_EMAIL,
  SIGNUP_WITH_EMAIL,
  FORGOT_PASSWORD,
  CONFIRM_EMAIL,
  CONFIRM_PHONE,
  TERMS_AND_CONDITIONS,
  NEWS_TIP_WIZARD,
  NEWS_TIP_SHOW,
  NEWS_TIP_SUCCESSFULLY_CREATED
} = PATHS.UNAUTHENTICATED

const routes = [
  // { key: 'LOGIN', path: LOGIN, component: Login, exact: true },
  // {
  //   key: 'FORGOT_PASSWORD',
  //   path: FORGOT_PASSWORD,
  //   component: ForgotPassword,
  //   exact: true
  // },
  // {
  //   key: 'LOGIN_WITH_EMAIL',
  //   path: LOGIN_WITH_EMAIL,
  //   component: LoginWithEmail,
  //   exact: true
  // },
  // {
  //   key: 'SIGNUP_WITH_EMAIL',
  //   path: SIGNUP_WITH_EMAIL,
  //   component: SignUpWithEmail,
  //   exact: true
  // },
  {
    key: 'CONFIRM_EMAIL',
    path: CONFIRM_EMAIL,
    component: ConfirmEmail,
    exact: true
  },
  {
    key: 'CONFIRM_PHONE',
    path: CONFIRM_PHONE,
    component: ConfirmPhone
  },
  {
    key: 'TERMS_AND_CONDITIONS',
    path: TERMS_AND_CONDITIONS,
    component: TermsAndConditionsOfUse,
    exact: true
  },
  {
    key: 'NEWS_TIP_WIZARD',
    path: NEWS_TIP_WIZARD,
    component: NewsTipWizard,
    exact: true
  },
  // {
  //   key: 'NEWS_TIP_SHOW',
  //   path: NEWS_TIP_SHOW,
  //   component: NewsTipShow,
  //   exact: true
  // },
  {
    key: 'NEWS_TIP_SUCCESSFULLY_CREATED',
    path: NEWS_TIP_SUCCESSFULLY_CREATED,
    component: NewsTipSuccessfullyCreated,
    exact: true
  }
]

const Auth = () => {
  const { isUserFinishedConfirmation } = useConfirmation()

  const isAuthenticated = useMemo(
    () => firebase.auth().currentUser && isUserFinishedConfirmation,
    [isUserFinishedConfirmation]
  )

  return isAuthenticated ? null : (
    <BoilerplateLayout>
      <Switch>
        {routes.map((routeProps) => (
          <Route key={routeProps.key} {...routeProps} />
        ))}
        <Redirect to={PATHS.SERVICE.NOT_FOUND} />
      </Switch>
    </BoilerplateLayout>
  )
}

export default Auth
