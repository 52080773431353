const PATHS = {
  CONFIG: {
    DEFAULT: '/auth/newsTip/wizard',
    AFTER_LOGIN: '/dashboard',
    AFTER_LOGOUT: '/auth/newsTip/wizard',
    AFTER_SIGNUP: '/dashboard'
  },
  SERVICE: {
    ACCESS_DENIED: '/service/access-denied',
    GDPR: '/service/gdpr',
    NOT_FOUND: '/service/404'
  },
  UNAUTHENTICATED: {
    // LOGIN: '/auth',
    // LOGIN_WITH_EMAIL: '/auth/login-with-email',
    // SIGNUP_WITH_EMAIL: '/auth/sign-up-with-email',
    // FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_EMAIL: '/auth/confirm-email',
    CONFIRM_PHONE: '/auth/confirm-phone',
    TERMS_AND_CONDITIONS: '/auth/terms-and-conditions',
    NEWS_TIP_WIZARD: '/auth/newsTip/wizard',
    // NEWS_TIP_SHOW: '/auth/newsTip/:newsTipId',
    NEWS_TIP_SUCCESSFULLY_CREATED: '/auth/newsTips/successfullyCreated'
  },

  AUTHENTICATED: {
    DASHBOARD: '/dashboard',
    USER_SHOW: '/users/:userId',
    USER_EDIT: '/users/:userId/edit',
    TRANSLATIONS: '/translations',
    SETTINGS: '/settings',
    STATISTICS: '/statistics',
    REWARDS_ALL: '/rewards',
    REWARD_SHOW: '/rewards/:rewardId',
    NEWS_TIP_WIZARD: '/newsTip/wizard',
    NEWS_TIP_SHOW: '/newsTip/:newsTipId',
    NEWS_TIP_SUCCESSFULLY_CREATED: '/newsTips/successfullyCreated',
    POINTS_HISTORIES_ALL: '/points-histories',
    ABOUT_US: '/about-us',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_OF_CONDITIONS: '/terms-of-conditions'
  }
}

export default PATHS
