import { Box, Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import {
  ImgStyled,
  VideoStyled,
  TagStyled,
  styles
} from './NewsTipSimpleView.styles'
import { MOMENT_FORMATS, STATUSES_COLORS } from '__constants__'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { SelectableCard } from 'components'
import moment from 'moment'
import noImage from 'assets/NoImage.png'

const NewsTipSimpleView = (props) => {
  const { newsTip } = props

  const history = useHistory()
  const { DATE_FORMATS } = MOMENT_FORMATS
  const { status, _createdAt, title, photoUrls, coverPhotoUrl, videoUrl } =
    newsTip

  const computedImage = coverPhotoUrl || photoUrls?.[0] || videoUrl || noImage

  const isOnlyVideoExist = !coverPhotoUrl && !photoUrls?.[0] && videoUrl

  const formattedData =
    _createdAt && moment?.unix(_createdAt?.seconds)?.format(DATE_FORMATS)

  const handleErrorLoadImage = ({ currentTarget }) => {
    currentTarget.onerror = null
    currentTarget.src = noImage
  }

  const handleGoToNewsTipShow = () =>
    history.push(
      generatePath(PATHS.AUTHENTICATED.NEWS_TIP_SHOW, {
        newsTipId: newsTip?._id
      })
    )

  return (
    <SelectableCard onSelect={handleGoToNewsTipShow}>
      <Container height="100%">
        <Row noGutters height="100%">
          <Col cw={6}>
            <Box height="100%" mr={4}>
              {isOnlyVideoExist ? (
                //Added video tag without controls for preview image on tip, for poster image enough video metadata
                <VideoStyled src={computedImage} preload="metadata" />
              ) : (
                <ImgStyled src={computedImage} onError={handleErrorLoadImage} />
              )}
            </Box>
          </Col>
          <Col cw={6}>
            <Row noGutters height="100%" flexDirection="column">
              <Col mb={2} cw={12}>
                <Title {...styles?.title}>{title}</Title>
              </Col>
              <Col cw={12} mb={2} style={styles?.column}>
                <Text variant="body2" type="secondary" mb={2}>
                  {formattedData}
                </Text>
                <TagStyled color={STATUSES_COLORS?.[status]}>
                  {status}
                </TagStyled>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SelectableCard>
  )
}

NewsTipSimpleView.propTypes = {
  newsTip: PropTypes.object,
  onSelect: PropTypes.func,
  isSelected: PropTypes.bool
}

export default NewsTipSimpleView
