import {
  FileTextOutlined,
  InfoCircleOutlined,
  LockOutlined,
  LogoutOutlined,
  SettingFilled,
  UserOutlined
} from '@ant-design/icons'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import firebase from 'firebase/compat/app'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const useGetAccountMenuItems = (userId) => {
  const { t } = useTranslations()
  const history = useHistory()

  return useMemo(
    () => [
      {
        type: 'item',
        text: t('Profile'),
        icon: <UserOutlined />,
        danger: false,
        disabled: false,
        onClick: () =>
          history.push(generatePath(PATHS.AUTHENTICATED.USER_SHOW, { userId }))
      },
      {
        type: 'item',
        key: PATHS.AUTHENTICATED.TERMS_OF_CONDITIONS,
        icon: <FileTextOutlined />,
        text: t('Terms of conditions'),
        onClick: () => history.push(PATHS.AUTHENTICATED.TERMS_OF_CONDITIONS)
      },
      {
        type: 'item',
        key: PATHS.AUTHENTICATED.PRIVACY_POLICY,
        icon: <LockOutlined />,
        text: t('Privacy policies'),
        onClick: () => history.push(PATHS.AUTHENTICATED.PRIVACY_POLICY)
      },
      {
        type: 'item',
        key: PATHS.AUTHENTICATED.ABOUT_US,
        icon: <InfoCircleOutlined />,
        text: t('About us'),
        onClick: () => history.push(PATHS.AUTHENTICATED.ABOUT_US)
      },
      {
        type: 'item',
        text: t('Log out'),
        icon: <LogoutOutlined />,
        danger: true,
        disabled: false,
        onClick: () => firebase.auth().signOut()
      }
    ],
    [history, t, userId]
  )
}

export default useGetAccountMenuItems
