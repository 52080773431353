import { Col, Row, Text } from '@qonsoll/react-design'

import { Fragment } from 'react'
import { Grid } from 'antd'
import { PointsHistorySimpleView } from 'domains/PointsHistory/components'
import PropTypes from 'prop-types'
import { useTransformPointsHistory } from 'domains/PointsHistory/hooks'
import { useTranslations } from 'contexts/Translation'

const { useBreakpoint } = Grid

const PointsHistoryList = (props) => {
  const { pointsHistories, emptyProps } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  // Used for adaptive layout
  const { lg, xl, xxl } = useBreakpoint()

  // When screen became smaller use this property to make adaptive layout
  const isTabletOrPhoneDimension = !lg && !xl && !xxl

  // Make object with dates as keys and array of points history for this date as a value
  const transformedPointsHistories = useTransformPointsHistory(pointsHistories)

  /* Checking if list is empty */
  const isEmpty =
    !transformedPointsHistories ||
    !Object.keys(transformedPointsHistories)?.length

  return (
    <Row mx="var(--ql-grid-gutter-negative)">
      {!isEmpty &&
        Object.keys(transformedPointsHistories).map(
          (pointsHistoryDate, index) => (
            <Fragment key={index}>
              <Col py={3} v="center" h="center">
                <Text type="secondary">{pointsHistoryDate}</Text>
              </Col>
              {transformedPointsHistories[pointsHistoryDate]?.map(
                (pointsHistory, index) => (
                  <Col key={pointsHistory?._id || index} cw={12} pb={2}>
                    <PointsHistorySimpleView
                      index={index}
                      pointsHistory={{ ...pointsHistory, pointsHistoryDate }}
                      isTabletOrPhoneDimension={isTabletOrPhoneDimension}
                    />
                  </Col>
                )
              )}
            </Fragment>
          )
        )}
      {isEmpty && (
        <Col cw={12}>
          <Text variant="overline" type="secondary">
            {emptyProps?.message ||
              t('No points histories have been created yet')}
          </Text>
        </Col>
      )}
    </Row>
  )
}

PointsHistoryList.propTypes = {
  pointsHistories: PropTypes.array,
  emptyProps: PropTypes.object
}

export default PointsHistoryList
