import * as yup from 'yup'

import { attr, hasMany, hasOne, model } from 'services/model'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .default(null)
    .nullable(),
  phoneNumber: yup
    .string()
    .typeError('Field phoneNumber has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  pointsGiven: yup
    .number()
    .typeError('Field pointsGiven has wrong type')
    .default(null)
    .nullable(),
  pointsUsed: yup
    .number()
    .typeError('Field pointsUsed has wrong type')
    .default(null)
    .nullable(),
  avatarUrl: yup
    .string()
    .typeError('Field avatarUrl has wrong type')
    .default(null)
    .nullable(),
  newsTips: yup
    .array()
    .typeError('Field newsTips has wrong type')
    .default(null)
    .nullable()
})

const User = model(
  'user',
  {
    sno: attr('number'),
    name: attr('string'),
    email: attr('string'),
    phoneNumber: attr('string'),
    status: hasOne('tip'),
    pointsGiven: attr('number'),
    pointsUsed: attr('number'),
    avatarUrl: attr('string'),
    newsTips: hasMany('newsTip')
  },
  validationSchema
)

export default User
