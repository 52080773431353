import { MOMENT_FORMATS } from '__constants__'
import _ from 'lodash'
import moment from 'moment'

const sortNewsTipsByApprovedDate = (newsTips) => {
  const { MONTH_DAYS_YEAR } = MOMENT_FORMATS
  if (newsTips) {
    let newsTipsApprovedAt = newsTips?.map(({ _approvedAt }) =>
      moment.unix(_approvedAt?.seconds).format(MOMENT_FORMATS.MONTH_DAYS_YEAR)
    )

    newsTipsApprovedAt = _?.uniq(newsTipsApprovedAt)

    let sortedNewsTipsByApprovedDate = newsTipsApprovedAt?.map(
      (approvedData) => {
        const newsTipsByApprovedData = newsTips?.filter(
          ({ _approvedAt }) =>
            moment.unix(_approvedAt?.seconds).format(MONTH_DAYS_YEAR) ===
            approvedData
        )

        return [approvedData, newsTipsByApprovedData]
      }
    )

    return Object.fromEntries(sortedNewsTipsByApprovedDate)
  }
}

export default sortNewsTipsByApprovedDate
