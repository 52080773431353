import styled from 'styled-components'

const CloseWrapper = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
`

const GDPRPopupWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: 288px;
  padding: 40px;
  background: var(--popup-bg);
  border-radius: var(--popup-border-radius);
  box-shadow: var(--popup-box-shadow);
  z-index: 1100;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export { CloseWrapper, GDPRPopupWrapper, ContentWrapper }
