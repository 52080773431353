import { Avatar } from '@qonsoll/react-design'
import styled from 'styled-components'

const StyledAvatar = styled(Avatar)`
  border-radius: var(--ql-border-radius-full);
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-typography {
    display: flex;
  }
  align-self: center;
`

export { StyledAvatar }
