import { useEffect, useMemo } from 'react'

import _ from 'lodash'
import { useGetImageTags } from 'domains/Tags/hooks'

const useGetImageSuggestions = ({ value, setValue, loading }) => {
  const coverPhoto = useMemo(
    () =>
      value?.media?.files?.filter(
        (item) => item?.type?.includes('image') && item?.url
      )?.[0],
    [value?.media]
  )

  const [tags, tagsLoading] = useGetImageTags(coverPhoto, value?._id, loading)

  useEffect(() => {
    !tagsLoading &&
      tags &&
      setValue((prev) => {
        // Computed selected tags
        let selectedTags = coverPhoto ? prev?.tags : []

        // Get image suggestions, sort if there are selected tags among the suggestions.
        const computedSuggestions = selectedTags?.length
          ? _.difference(tags, selectedTags)
          : tags

        // If the filtered suggestions length is 20, then clear the selectedTags, since no matches were found.
        if (computedSuggestions?.length === 20) {
          selectedTags = []
        }

        return {
          ...prev,
          tags: [...selectedTags],
          suggestions: [...computedSuggestions]
        }
      })
  }, [tagsLoading, tags, setValue, coverPhoto])

  return [tags, tagsLoading]
}

export default useGetImageSuggestions
