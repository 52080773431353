import { AccountMenu, Logo, MainMenu } from '../../components'
import { Box, Col, Container, Row } from '@qonsoll/react-design'
import { addProtocolToUrl, createFullUserName } from 'helpers'

import { LanguageMenu } from 'domains/Translation/components'
import PropTypes from 'prop-types'
import { StyledHeader } from './LayoutHeader.styles'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useUser } from 'domains/User/context'

const LayoutHeader = ({ isAuthenticated }) => {
  const { app, menu } = useNewspaperConfig()
  const { user } = useUser()

  const displayName = createFullUserName(user?.firstName, user?.lastName)

  const newspaperFrontpageAddress = app?.newspaperFrontpageAddress
  const checkedForProtocolLink = addProtocolToUrl(newspaperFrontpageAddress)

  const handleLogoClick = () => {
    if (!newspaperFrontpageAddress) return

    if (checkedForProtocolLink) {
      window.open(checkedForProtocolLink, '_blank')
    }
  }

  return (
    <>
      <StyledHeader px={3} isAuthenticated={isAuthenticated}>
        <Container bounded>
          <Row v="center" noGutters px={[3, 0]}>
            <Col cw="auto" mr="auto">
              <Logo
                logoUrl={app?.logoUrl}
                onClick={handleLogoClick}
                style={{ cursor: newspaperFrontpageAddress ? 'pointer' : null }}
              />
            </Col>
            {isAuthenticated ? (
              <Col cw="auto" className="accountWrapper">
                <Box display="flex" alignItems="center">
                  <LanguageMenu color={'var(--ql-header-text-color)'} />
                  <AccountMenu
                    id={user?._id}
                    avatar={user?.avatarUrl}
                    displayName={displayName}
                    email={user?.email}
                  />
                </Box>
              </Col>
            ) : (
              <Col cw="auto">
                <Box display="flex" alignItems="center">
                  <LanguageMenu color={`var(--ql-header-text-color)`} />
                </Box>
              </Col>
            )}
          </Row>
        </Container>
      </StyledHeader>
      <MainMenu
        menu={menu}
        isAuthenticated={isAuthenticated}
        pointsGiven={user?.pointsGiven}
      />
    </>
  )
}

LayoutHeader.propTypes = {
  isAuthenticated: PropTypes.bool
}

export default LayoutHeader
