import { Button, Col, Input, Row, Text } from '@qonsoll/react-design'
import { CheckOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const NewsTipCommentsSectionHeader = (props) => {
  const {
    commentText,
    editCommentId,
    handleCommentSubmit,
    handleResetCommentEdit,
    handleCurrentCommentChange
  } = props

  const { t } = useTranslations()

  const isSubmitDisabled = !commentText?.trim()?.length

  return (
    <Row v="center" noGutters>
      <Col cw={12}>
        <Text variant="overline" type="secondary">
          {t('Comments')}
        </Text>
      </Col>
      <Col mr={2}>
        <Input
          px={0}
          bordered={false}
          maxLength={1000}
          placeholder={`${t('Add a comment')}...`}
          value={commentText}
          onChange={handleCurrentCommentChange}
          onPressEnter={handleCommentSubmit}
        />
      </Col>
      <Col cw="auto">
        <Button
          type="text"
          disabled={isSubmitDisabled}
          onClick={handleCommentSubmit}>
          {editCommentId ? <CheckOutlined /> : <SendOutlined />}
        </Button>
      </Col>
      {editCommentId && (
        <Col cw="auto" ml={2}>
          <Button onClick={handleResetCommentEdit} type="text">
            <CloseOutlined />
          </Button>
        </Col>
      )}
    </Row>
  )
}

NewsTipCommentsSectionHeader.propTypes = {
  commentText: PropTypes.string,
  editCommentId: PropTypes.string,
  handleCommentSubmit: PropTypes.func,
  handleResetCommentEdit: PropTypes.func,
  handleCurrentCommentChange: PropTypes.func
}

export default NewsTipCommentsSectionHeader
