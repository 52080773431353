import PATHS from 'pages/paths'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useTranslations } from 'contexts/Translation'

const useGetMenuItems = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const newspaperData = useNewspaperConfig()

  return useMemo(
    () => [
      {
        key: PATHS.AUTHENTICATED.DASHBOARD,
        text: t('Dashboard'),
        onClick: () => history.push(PATHS.AUTHENTICATED.DASHBOARD)
      },
      {
        key: PATHS.AUTHENTICATED.NEWS_TIP_WIZARD,
        text: t('Create tips'),
        onClick: () => history.push(PATHS.AUTHENTICATED.NEWS_TIP_WIZARD)
      },
      {
        key: PATHS.AUTHENTICATED.REWARDS_ALL, // Change path
        text: t('Use my points'),
        onClick: () => history.push(PATHS.AUTHENTICATED.REWARDS_ALL) // Change path
      },
      {
        key: PATHS.AUTHENTICATED.POINTS_HISTORIES_ALL,
        text: t('Points history'),
        onClick: () => history.push(PATHS.AUTHENTICATED.POINTS_HISTORIES_ALL)
      }
    ],
    [history, t, newspaperData]
  )
}

export default useGetMenuItems
