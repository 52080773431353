import 'firebase/auth'
import 'firebase/database'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import '@qonsoll/react-design/dist/styles/antd/index.css'
import '@qonsoll/react-design/dist/styles/vars/index.css'
import '@qonsoll/react-design/dist/styles/styles.css'
import './styles/styles.css'

import App from './App'
import { ConfirmationProvider } from 'domains/Confirmation/context'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { NewsTipProvider } from 'domains/NewsTip/context'
import { NewspaperConfigProvider } from 'contexts'
import { QueryParamProvider } from 'use-query-params'
import ReactDOM from 'react-dom'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { BrowserRouter as Router } from 'react-router-dom'
import { UserProvider } from 'domains/User/context'
import firebase from 'firebase/compat/app'
import firebaseConfigs from 'configs/firebase'
import reportWebVitals from './reportWebVitals'

const INITIAL_LANGUAGE = navigator.language?.split('-')?.[0]
const SITE_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_API_KEY

/* This is the code that initializes the firebase app. */
firebase.initializeApp(firebaseConfigs)

ReactDOM.render(
  <Router>
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <NewspaperConfigProvider>
        <ConfirmationProvider>
          <UserProvider>
            <GoogleReCaptchaProvider
              reCaptchaKey={SITE_KEY}
              language={INITIAL_LANGUAGE}
              useEnterprise>
              <NewsTipProvider>
                <App />
              </NewsTipProvider>
            </GoogleReCaptchaProvider>
          </UserProvider>
        </ConfirmationProvider>
      </NewspaperConfigProvider>
    </QueryParamProvider>
  </Router>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
