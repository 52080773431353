import { EmailAuthProvider, getAuth, linkWithCredential } from 'firebase/auth'

import { message } from 'antd'
import { useConfirmation } from 'domains/Confirmation/context'
import { useCreateUser } from 'domains/User/hooks'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'

const useSignUpWithCredentials = () => {
  const [loading, setLoading] = useState(false)

  const { confirmationObject, setIsUserFinishedConfirmation } =
    useConfirmation()
  const { t } = useTranslations()
  const createUser = useCreateUser()

  const signUpWithCredentials = async (data, otherCredentials) => {
    try {
      setLoading(true)
      setIsUserFinishedConfirmation(false)
      const { code, email, password } = data

      if (confirmationObject && code) {
        await confirmationObject?.confirm(code)?.catch(() => {
          message.error(t('Verification code not valid'))
        })

        const auth = await getAuth()
        const currentUser = auth.currentUser

        const credentials = EmailAuthProvider.credential(email, password)

        await linkWithCredential(currentUser, credentials)?.catch((e) => {
          return
        })

        const { uid } = currentUser

        await createUser(uid, data, otherCredentials)
      }
    } catch (e) {
      message.error(t('Something went wrong during sign up'))
    } finally {
      setLoading(false)
      setIsUserFinishedConfirmation(true)
    }
  }

  return { signUpWithCredentials, loading }
}
export default useSignUpWithCredentials
