import { useCallback, useMemo } from 'react'
import debounce from 'lodash/debounce'
import { ENDPOINTS } from '__constants__'

const WAIT = 1_000

const useDebounceUpdateTips = () => {
  // memoize the callback with useCallback
  // we need it since it's a dependency in useMemo below
  const sendRequest = useCallback(async (options) => {
    try {
      await fetch(ENDPOINTS.PUBLIC_NEWS_TIPS, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(options)
      })
    } catch (error) {
      console.error('Error during sendRequest', error.message)
    }
  }, [])

  // memoize the debounce call with useMemo
  const debouncedSendRequest = useMemo(
    () => debounce(sendRequest, WAIT),
    [sendRequest]
  )

  return { debouncedSendRequest, sendRequest }
}

export default useDebounceUpdateTips
