import styled from 'styled-components'

export const HoverableBox = styled.div`
  width: 100%;
  height: 100%;
  transition: 0.3s;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    background-color: var(--ql-color-dark-t-lighten5);
  }
`
export const boxStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  border: '1px dashed var(--ql-color-dark-t-lighten3)',
  cursor: 'pointer'
}
