const STATUSES = {
  NEWS_TIP_STATUSES: {
    DRAFT: 'Draft',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    APPROVED: 'Approved'
  }
}

export { STATUSES }
export default STATUSES
