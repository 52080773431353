import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  content: yup
    .string()
    .typeError('Field content has wrong type')
    .default(null)
    .nullable()
})

const Page = model(
  'page',
  {
    title: attr('string'),
    content: attr('string')
  },
  validationSchema
)

export default Page
