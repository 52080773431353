import { PageWrapper } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { SignupForm } from 'domains/Session/components'
import { useHandleError } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useSessionActions } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'

const SignUpWithEmail = (props) => {
  const { isGuestMode = false, guestModeActions = null } = props

  const { t } = useTranslations()
  const onError = useHandleError()
  const { signup } = useSessionActions()
  const history = useHistory()

  const goBack = () => history.goBack()

  const onBackActionComputed = isGuestMode
    ? guestModeActions?.handleSignUpFormBack
    : goBack
  const backBtnPropsComputed = isGuestMode ? { margin: '24px 16px 0' } : null
  const isBackButtonDividedComputed = !isGuestMode

  return (
    <PageWrapper
      onBack={onBackActionComputed}
      backBtnProps={backBtnPropsComputed}
      divided={isBackButtonDividedComputed}
      alignMiddle
      height="100%"
      headingProps={{
        title: t('Sign up with email'),
        subTitle: t('Please, enter your email and password'),
        marginBottom: 40
      }}
      contentWidth={['100%', '100%', 400]}>
      <SignupForm onError={onError} signup={signup} />
    </PageWrapper>
  )
}

SignUpWithEmail.propTypes = {
  isGuestMode: PropTypes.bool,
  guestModeActions: PropTypes.shape({
    showPhoneConfirmationForm: PropTypes.func,
    showLoginWithEmailForm: PropTypes.func,
    handleSignUpFormBack: PropTypes.func
  })
}

export default SignUpWithEmail
