import { Img } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import logoTips from 'assets/TipsLogo.png'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'

const Logo = (props) => {
  const { logoUrl, ...rest } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const onErrorCallback = useCallback(({ currentTarget }) => {
    currentTarget.onerror = null
    currentTarget.src = logoTips
  }, [])

  return (
    <Img
      onError={onErrorCallback}
      maxWidth={[120, 'unset']}
      src={logoUrl}
      alt={t('Tipps web')}
      height="30px"
      {...rest}
    />
  )
}

Logo.propTypes = {
  logoUrl: PropTypes.string
}

export default Logo
