import { Box, Button, Text } from '@qonsoll/react-design'
import { Dropdown, Menu } from 'antd'
import {
  iconLanguageSelectedStyles,
  iconLanguageStyles
} from './LanguageButtonStyled.style'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useGetInitialLanguage } from 'domains/Translation/hooks'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'

const LanguageMenu = ({ color }) => {
  const { language, languages } = useTranslations()

  const languageCode = language?.toUpperCase()
  const { app = {} } = useNewspaperConfig()
  const newspaperDefaultLanguage = app?.defaultLanguage || null
  const shortCodeToUpperCase = (item) => item?.shortCode?.toUpperCase()

  const { handleChange } = useGetInitialLanguage()

  const languageMenu = (
    <Menu defaultSelectedKeys={newspaperDefaultLanguage} selectable>
      {languages?.map((item) => (
        <Menu.Item
          key={item?.shortCode}
          onClick={() => {
            handleChange(item?.shortCode)
          }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Text>{shortCodeToUpperCase(item)}</Text>
            {language === item?.shortCode && (
              <Icon {...iconLanguageSelectedStyles} />
            )}
          </Box>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={languageMenu} placement="bottomRight" trigger="click">
      <Button color={color} type="text" width="70px">
        <Icon {...iconLanguageStyles} />
        <Text color="inherit">{languageCode}</Text>
      </Button>
    </Dropdown>
  )
}
LanguageMenu.propTypes = {
  color: PropTypes.string
}

export default LanguageMenu
