import { Card } from 'antd'
import { Img } from '@qonsoll/react-design'
import styled from 'styled-components'

const ContentCard = styled(Card)`
  height: 100%;

  .ant-card-head-title {
    padding: 0;
  }
`

const StyledImg = styled(Img)`
  max-height: 280px;
  object-fit: cover;
  width: 100%;
  border-radius: var(--card-radius);
`

const VideoStyled = styled.video`
  width: 100%;
  height: 280px;
  border-radius: var(--card-radius);
  object-fit: cover;
`

const styles = {
  emptyStyles: { withCreate: false, withText: false },
  columnStyles: { cw: 12, mb: 2 },
  textStyles: { type: 'secondary', variant: 'body2' },
  contentCardHeadStyles: {
    borderBottom: 0,
    marginBottom: 8,
    paddingTop: 24
  },
  contentCardBodyStyles: {
    paddingTop: 0,
    overflow: 'auto',
    maxHeight: '186px'
  }
}

export { ContentCard, StyledImg, VideoStyled, styles }
