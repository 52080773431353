import { COLLECTIONS } from '__constants__'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'

const useGetNewsTipComments = (newsTipId) => {
  const ref = useMemo(
    () =>
      newsTipId && {
        ref: COLLECTIONS.NEWS_TIPS_COMMENTS,
        where: [['tipsId', '==', newsTipId]]
      },
    [newsTipId]
  )

  const [newsTipComments, loading, error] = useCollection(ref)

  return [newsTipComments, loading, error]
}

export default useGetNewsTipComments
