import {
  Box,
  Card,
  Col,
  Container,
  Icon,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'
import {
  ContentCard,
  StyledImg,
  styles,
  VideoStyled
} from './NewsTipAdvancedView.styles'
import {
  NewsTipCommentSection,
  NewsTipMediaList
} from 'domains/NewsTip/components'

import { Checkbox } from 'antd'
import { Empty } from 'components'
import EmptyImage from 'assets/empty.svg'
import { Grid } from 'antd'
import { NewsTipStatus } from 'domains/NewsTip/components'
import PropTypes from 'prop-types'
import { TagsList } from 'domains/Tags/components'
import { WarningOutlined } from '@ant-design/icons'
import { getFormattedDateAndTime } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const { useBreakpoint } = Grid
const NewsTipAdvancedView = (props) => {
  const { newsTip, location, value, setValue, isPreview } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  // Used to add margin bottom for left Card when user use mobile
  // This margin not used on bigger sizes to avoid wrong Card size
  const { xs: isMobile } = useBreakpoint()
  /* Getting formatted date and time */
  const dateAndTime = getFormattedDateAndTime(newsTip?._createdAt?.seconds)

  const computedCoverPhoto = newsTip?.coverPhotoUrl || newsTip?.photoUrls?.[0]

  const handleErrorLoadImage = ({ currentTarget }) => {
    currentTarget.onerror = null
    currentTarget.src = EmptyImage
  }

  const handleChangeCheckbox = (event) => {
    const isAnonymous = event.target.checked
    setValue?.((prev) => ({
      ...prev,
      isAnonymous
    }))
  }

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={[12, 12, 4]}>
          <Card height="100%" mb={isMobile ? 3 : 0}>
            <Row noGutters mb={3} v="center">
              <Col h="left">
                {computedCoverPhoto ? (
                  <StyledImg
                    onError={handleErrorLoadImage}
                    src={computedCoverPhoto}
                  />
                ) : newsTip?.videoUrl ? (
                  //Added video tag without controls for preview image on tip, for poster image enough video metadata
                  <VideoStyled src={newsTip?.videoUrl} preload="metadata" />
                ) : (
                  <Empty
                    {...styles.emptyStyles}
                    message={t('No image provided')}
                  />
                )}
              </Col>
            </Row>
            <Row noOuterGutters mb={2} h="center">
              <Col p={0}>
                <Title wordBreak="break-all" level={5}>
                  {newsTip?.title}
                </Title>
              </Col>
              <Col cw="auto">
                <NewsTipStatus status={newsTip?.status} />
              </Col>
            </Row>

            <Row mb={2} noGutters>
              <Col {...styles.columnStyles}>
                <Text {...styles.textStyles}>{t('Location:')}</Text>
                <Text variant={'body1'}>{location?.formattedAddress}</Text>
              </Col>

              <Col {...styles.columnStyles}>
                <Text {...styles.textStyles}>{t('Posted on:')}</Text>
                <Text variant={'body1'}>{dateAndTime}</Text>
              </Col>
            </Row>

            <Row mb={2} noGutters>
              <Col cw={12}>
                <Text {...styles.textStyles} mb={2}>
                  {t('Tags:')}
                </Text>
                <Box {...styles.overflowStyles} maxHeight="116px">
                  <TagsList tags={newsTip?.tags} />
                </Box>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col cw={[12, 12, 8]}>
          <Row noGutters>
            <Col cw={12} mb={3}>
              <ContentCard
                // Used to make this section structure same like for Comments
                // also makes scrollbar placement better and header fixed
                // like in comments section
                headStyle={styles.contentCardHeadStyles}
                bodyStyle={styles.contentCardBodyStyles}
                title={
                  <Row noGutters>
                    <Col cw={12}>
                      <Text variant="overline" type="secondary">
                        {t('Content')}
                      </Text>
                    </Col>
                  </Row>
                }>
                <Row noGutters>
                  <Col cw={12}>
                    {newsTip?.content ? (
                      <Text>{newsTip?.content}</Text>
                    ) : (
                      <Text type="secondary">{t('There is no content')}</Text>
                    )}
                  </Col>
                </Row>
              </ContentCard>
            </Col>
            <Col cw={12} mb={3}>
              <NewsTipMediaList
                photoUrls={newsTip?.photoUrls}
                videoUrl={newsTip?.videoUrl}
              />
            </Col>
            <Col cw={12}>
              {/* if this is a preview on the wizard, display isAnonymous instead of comments */}
              {isPreview ? (
                <Card>
                  <Box mb={1}>
                    <Checkbox
                      onChange={handleChangeCheckbox}
                      checked={!!value?.isAnonymous}>
                      <Text>{t('Remain anonymous')}</Text>
                    </Checkbox>
                  </Box>
                  <Box display="flex" h="center" v="center">
                    <Icon
                      mr={2}
                      color={'var(--ql-color-warning)'}
                      component={<WarningOutlined />}
                      size={[16]}
                      pt={'2px'}
                    />
                    <Text type="secondary" variant={'body2'}>
                      {t(
                        "Remember, if checked, you won't get credits for the tipp if it's posted in article!"
                      )}
                    </Text>
                  </Box>
                </Card>
              ) : (
                <NewsTipCommentSection />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

NewsTipAdvancedView.propTypes = {
  isPreview: PropTypes.bool,
  value: PropTypes.object,
  setValue: PropTypes.func,
  newsTip: PropTypes.shape({
    _id: PropTypes.string,
    _createdAt: PropTypes.object,
    videoUrl: PropTypes.string,
    photoUrls: PropTypes.array,
    title: PropTypes.string,
    content: PropTypes.string,
    tags: PropTypes.array,
    coverPhotoUrl: PropTypes.string,
    location: PropTypes.string,
    status: PropTypes.string,
    isBlocked: PropTypes.bool
  }),
  location: PropTypes.shape({
    formattedAddress: PropTypes.string
  })
}

export default NewsTipAdvancedView
