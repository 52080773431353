import styled from 'styled-components'
import { Img } from '@qonsoll/react-design'

const StyledUserBalance = styled.div`
  background-color: var(--gold-6);
  padding: 6px 12px;
  display: flex;
  align-items: center;
  border-radius: 20px;

  span {
    color: var(--ql-typography-text-color-primary-inverse);
    font-weight: var(--ql-font-weight-semibold);
  }
`
const StyledImg = styled(Img)`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`

export { StyledUserBalance, StyledImg }
