import { useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { onAuthStateChanged } from 'firebase/auth'
import { useHandleError } from 'hooks'
import { useConfirmation } from 'domains/Confirmation/context'

const useInitializeUser = () => {
  /* If the user is logged in, fetch the user's data from Firestore. */
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  /* The above code is a function that takes in a callback function as an argument.
  The callback function is then called with the error as an argument. */
  const handleError = useHandleError()
  const { isUserFinishedConfirmation } = useConfirmation()

  useEffect(() => {
    let unsubscribeUserListener = null
    const unsubscribeAuthListener = onAuthStateChanged(
      firebase.auth(),
      (currentAuth) => {
        if (currentAuth) {
          unsubscribeUserListener = firebase
            .firestore()
            .collection(COLLECTIONS.USERS)
            .doc(currentAuth.uid)
            .onSnapshot({
              next: (snapshot) => {
                try {
                  if (snapshot?.exists) {
                    const data = snapshot.data()
                    setUser(data)
                    setLoading(false)
                  } else {
                    // Logout if there is no such user and user finished confirmation
                    isUserFinishedConfirmation && firebase.auth().signOut()
                  }
                } catch (e) {
                  handleError(e)
                }
              },
              error: setError
            })
        } else {
          setUser(null)
          setLoading(false)
          unsubscribeUserListener?.()
        }
      }
    )

    return () => {
      unsubscribeAuthListener?.()
      unsubscribeUserListener?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserFinishedConfirmation])

  return [user, loading, error]
}

export default useInitializeUser
