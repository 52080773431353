import styled from 'styled-components'

const StyledVideoPlayer = styled.div`
  display: flex;
  background: var(--btn-default-bg);
  justify-content: center;
  align-items: center;
  height: 100%;
  border-radius: var(--ql-border-radius-default);

  video {
    width: 100%;
  }
`
const StyledVideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: var(--ql-border-radius-default);
`
export { StyledVideoPlayer, StyledVideoWrapper }
