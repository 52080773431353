import { ENDPOINTS } from '__constants__'
import { getAuth } from 'firebase/auth'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'

const useLoginWithAnotherRole = () => {
  const { t } = useTranslations()

  const loginWithAnotherRole = async ({ uid, email }) => {
    try {
      const jwtToken = await getAuth().currentUser.getIdToken()

      const requestsConfig = {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          'Content-Type': 'application/json'
        }
      }

      const userAuthSnap = await fetch(
        ENDPOINTS.GET_USER_CUSTOM_CLAIMS_AND_UID,
        {
          ...requestsConfig,
          body: JSON.stringify({ email })
        }
      )

      const userAuthData = await userAuthSnap.json()

      const customClaims = userAuthData?.data?.customClaims

      // Check is user admin or editor
      if (customClaims?.admin || customClaims?.editor) {
        // Create user record
        const userCreationResponse = await fetch(
          ENDPOINTS.CREATE_USER_DOCUMENT_FOR_ADMIN_OR_EDITOR,
          {
            ...requestsConfig,
            body: JSON.stringify({ email, uid, ...customClaims })
          }
        )

        if (!userCreationResponse.ok) {
          message.error(t('Something went wrong during login'))
        }
      }
    } catch (e) {
      message.error(t('Something went wrong during login'))
    }
  }

  return loginWithAnotherRole
}

export default useLoginWithAnotherRole
