import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .default(null)
    .nullable(),
  value: yup
    .string()
    .typeError('Field value has wrong type')
    .default(null)
    .nullable()
})

const Coupon = model(
  'coupon',
  {
    title: attr('string'),
    description: attr('string'),
    value: attr('string')
  },
  validationSchema
)

export default Coupon
