import { Col, Container, Row } from '@qonsoll/react-design'
import { FooterStyled, StyledTextWrapper } from './Footer.styles'

import { useNewspaperConfig } from 'contexts/NewspaperConfig'

const Footer = () => {
  const { footer } = useNewspaperConfig()

  const { mainText } = footer || {}

  return (
    <FooterStyled>
      <Container bounded>
        <Row>
          <Col px={['24px', 2, '12px', 3]} cw={12}>
            <StyledTextWrapper dangerouslySetInnerHTML={{ __html: mainText }} />
          </Col>
        </Row>
      </Container>
    </FooterStyled>
  )
}

export default Footer
