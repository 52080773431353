import {
  useBeforeUpload,
  useOnUploadMedia,
  useRemoveFileFromStorage
} from 'hooks'
import { useCallback, useEffect, useState } from 'react'

import { UPLOADING_STATUSES } from '__constants__'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useUploadMedia = (props) => {
  const {
    value,
    onChange,
    storagePath,
    setUploadLoading,
    tipId,
    setStep,
    isGuestMode
  } = props
  const [files, setFiles] = useState(value || null)
  const { t } = useTranslations()

  const [loadings, setLoadings] = useState([])

  useEffect(() => {
    const isUploading = loadings?.find((item) => item === false)
    const mediaWithoutUrl = value?.files?.find((item) => !item?.url)
    !mediaWithoutUrl && setUploadLoading(null)

    loadings?.length &&
      !isUploading &&
      !mediaWithoutUrl &&
      setStep((step) => step + 1)
  }, [value, setUploadLoading])

  /* A callback function that is used to upload the file to the storage. */
  const { onUpload } = useOnUploadMedia({
    setLoadings,
    setFiles,
    onChange,
    storagePath,
    tipId,
    isGuestMode
  })

  const beforeUpload = useBeforeUpload()
  const removeFileFromStorage = useRemoveFileFromStorage()

  /**
   * `handleChange` is a function that takes an object as an argument, and that object has a property
   * called `fileList` which is assigned to a variable called `newFileList`
   */

  const handleChange = useCallback((e) => {
    const { fileList: newFileList, file } = e

    if (!file?.response && file?.status !== UPLOADING_STATUSES.UPLOADING) return

    const computedStatus = file?.file?.status || file?.status
    if (computedStatus === UPLOADING_STATUSES.REMOVED) return

    setFiles((prev) => {
      onChange?.({ ...prev, files: newFileList })
      return { ...prev, files: newFileList }
    })
  }, [])

  const customRequest = (uploadValue) => {
    setFiles((prev) => {
      const newData = {
        ...prev,
        uploadFiles: [...(prev?.uploadFiles || []), uploadValue]
      }
      onChange(newData)
      return newData
    })

    setTimeout(() => {
      uploadValue?.onSuccess('ok')
    }, 0)
  }

  const handleUpload = useCallback(() => {
    setUploadLoading(true)
    const filterUploadData = files?.uploadFiles

    const images = value?.files?.filter(({ type }) =>
      type?.includes('image')
    )?.length

    const video = value?.files?.filter(({ type }) =>
      type?.includes('video')
    )?.length

    if (images > 3 || video > 1) {
      setUploadLoading(false)
      return message.error(
        `${t(
          'It is impossible to upload the file, the maximum number of files: 3 photos and 1 video'
        )}.`
      )
    }

    filterUploadData?.length &&
      setLoadings(new Array(...Array(filterUploadData?.length).fill(false)))

    filterUploadData?.map((uploadData) => onUpload(uploadData))
  }, [onUpload, value, setUploadLoading, t])

  // It removes the file from the local state
  const handleRemoveLocalState = (file) => {
    setFiles((prev) => {
      let newData = { ...prev }

      const computedFiles = newData?.files?.filter(
        (item) => item?.uid !== file?.uid
      )
      const computedUploadFiles = newData?.uploadFiles?.filter(
        (item) => item?.file?.uid !== file?.uid
      )

      const computedData = {
        uploadFiles: [...computedUploadFiles],
        files: [...computedFiles]
      }

      onChange(computedData)

      return computedData
    })
  }

  const handleRemoveFile = (file) => {
    if (file?.url) {
      removeFileFromStorage(file?.url)
    }

    handleRemoveLocalState(file)
  }

  return {
    files,
    onUpload,
    customRequest,
    beforeUpload,
    handleUpload,
    handleChange,
    handleRemoveFile
  }
}

export default useUploadMedia
