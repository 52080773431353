import styled from 'styled-components'

const FooterStyled = styled.footer`
  background-color: var(--ql-footer-bg);
  color: var(--ql-footer-text-color);
  width: 100%;
  padding: 24px 0;
`
const StyledTextWrapper = styled.div`
  & > p {
    margin: 0;
  }
`
export { FooterStyled, StyledTextWrapper }
