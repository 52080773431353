import { useEffect, useState, useCallback } from 'react'
import { getDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

/**
 * It fetches a newspaper document from Firestore and returns it
 * @param user - The user object that we're getting the newspaper for.
 * @returns An array with four values. The first value is the newspaper object, and the second value is a
 * boolean that indicates whether the newspaper is loading.
 */
const useGetUserNewspaper = (user) => {
  /* State for data */
  const [newspaper, setNewspaper] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!user?.newspaperAssociated)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getNewspaper = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(
        COLLECTIONS.NEWSPAPERS,
        user.newspaperAssociated
      )
      setNewspaper(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [user?.newspaperAssociated])

  useEffect(() => {
    user?.newspaperAssociated && getNewspaper()
  }, [user?.newspaperAssociated, getNewspaper])

  return [newspaper, loading, error, getNewspaper]
}

export default useGetUserNewspaper
