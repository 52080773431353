export const WRONG_PASSWORD_ERROR_CODE = 'auth/wrong-password'
export const USER_NOT_FOUND_ERROR_CODE = 'auth/user-not-found'
export const TOO_MANY_REQUESTS_ERROR_CODE = 'auth/too-many-requests'
export const ACCOUNT_EXIST_WITH_OTHER_PROVIDER_ERROR_CODE =
  'auth/account-exists-with-different-credential'
export const AUTH_CANCELED_ERROR_CODE = 'auth/popup-closed-by-user'
export const AUTH_CREDENTIALS_ALREADY_USE = 'auth/credential-already-in-use'

export const SESSION_ERROR_CODES_AND_TEXT_MAP = {
  [WRONG_PASSWORD_ERROR_CODE]: 'Error, wrong password',
  [USER_NOT_FOUND_ERROR_CODE]: 'Error, user not found',
  [TOO_MANY_REQUESTS_ERROR_CODE]:
    'Error, too many requests, please try again later or reset password to gain access immediately',
  [ACCOUNT_EXIST_WITH_OTHER_PROVIDER_ERROR_CODE]:
    'Error, this user already registered with another authorization method, try to use email and password method for login',
  [AUTH_CANCELED_ERROR_CODE]: 'Error, authorization was canceled',
  [AUTH_CREDENTIALS_ALREADY_USE]:
    'Error, these credentials are already in use by another account, please try logging in using another provider'
}
