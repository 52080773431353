const STEP_NAMES = {
  TITLE: 'title',
  MEDIA: 'media',
  LOCATION: 'location',
  TAGS: 'tags',
  CONTENT: 'content',
  SUMMARY: 'summary',
  ANONYMOUS_USER_FINISH: 'anonymousUserFinish'
}

export default STEP_NAMES
