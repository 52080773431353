import { useTranslations } from '@qonsoll/translation'
import { PageWrapper } from '@qonsoll/react-design'
import { Content } from 'components'
import { CONTENT_TYPES } from '__constants__'

const PrivacyPolicy = () => {
  const { t } = useTranslations()

  const headingProps = {
    title: t('Privacy policy'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper headingProps={headingProps}>
      <Content contentType={CONTENT_TYPES.PRIVACY_POLICY} />
    </PageWrapper>
  )
}

export default PrivacyPolicy
