import { PageWrapper } from '@qonsoll/react-design'
import { NewsTipAdvancedView } from 'domains/NewsTip/components'

import { Back } from 'components'
import { CloseOutlined } from '@ant-design/icons'
import { useGetNewsTipInitialValues } from 'domains/NewsTip/hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const NewsTipShow = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { newsTipId } = useParams()

  /* Fetching news tip data from useGetNewsTipInitialValues hook */
  const [newsTipData] = useGetNewsTipInitialValues(newsTipId)

  const { newsTip, location } = newsTipData

  /* Configuring page header */
  const headingProps = {
    title: t('News tip show'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      action={
        <Back bg="var(--ql-color-dark-t-lighten5)" icon={<CloseOutlined />} />
      }
      headingProps={headingProps}>
      <NewsTipAdvancedView newsTip={newsTip} location={location} />
    </PageWrapper>
  )
}

export default NewsTipShow
