const CONTENT_TYPES = {
  ABOUT_US: 'ABOUT_US',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  PRIVACY_POLICY: 'PRIVACY_POLICY'
}

const CONTENT = {
  ABOUT_US: 'About Us',
  TERMS_OF_SERVICE: 'Terms Of Service',
  PRIVACY_POLICY: 'Privacy Policy'
}

export { CONTENT_TYPES, CONTENT }
