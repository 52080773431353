import { STATUSES } from './'

const STATUSES_COLORS = {
  [STATUSES.NEWS_TIP_STATUSES.APPROVED]: 'var(--ql-color-success)',
  [STATUSES.NEWS_TIP_STATUSES.REJECTED]: 'var(--ql-color-danger)',
  [STATUSES.NEWS_TIP_STATUSES.PENDING]: 'var(--ql-color-info)'
}

export { STATUSES_COLORS }
export default STATUSES_COLORS
