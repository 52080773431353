import { Box } from '@qonsoll/react-design'
import { Button } from 'antd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import UploadStyled from './Upload.styles'
import { useGetUploadMediaPreview } from './hooks'
import { useUploadMedia } from 'hooks'

const UploadMedia = (props) => {
  const {
    value,
    onChange,
    storagePath,
    readOnly,
    fileList,
    disabled,
    accept = '*',
    maxCount,
    setUploadLoading,
    uploadLoading,
    tipId,
    setStep,
    isGuestMode,
    ...rest
  } = props

  const {
    handleChange,
    files,
    handleRemoveFile,
    beforeUpload,
    handleUpload,
    customRequest
  } = useUploadMedia({
    maxCount,
    value,
    onChange,
    storagePath,
    setUploadLoading,
    uploadLoading,
    tipId,
    setStep,
    isGuestMode
  })

  const { modal, handlePreview } = useGetUploadMediaPreview()

  return (
    <Box overflowY="auto" whiteSpace="nowrap">
      {modal}
      <UploadStyled
        {...rest}
        disabled={disabled || uploadLoading}
        multiple
        accept={accept}
        beforeUpload={beforeUpload}
        listType="picture-card"
        fileList={files?.files || value}
        onChange={handleChange}
        customRequest={customRequest}
        onRemove={handleRemoveFile}
        onPreview={handlePreview}>
        <Icon name="Plus1Outlined" />
      </UploadStyled>

      <Button
        style={{ display: 'none' }}
        id="uploadMedia"
        onClick={handleUpload}
      />
    </Box>
  )
}

UploadMedia.propTypes = {
  accept: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  storagePath: PropTypes.string,
  readOnly: PropTypes.bool,
  fileList: PropTypes.arrayOf(PropTypes.object),
  setDocumentLoading: PropTypes.func,
  disabled: PropTypes.bool,
  maxCount: PropTypes.number,
  tipId: PropTypes.string,
  setUploadLoading: PropTypes.func,
  uploadLoading: PropTypes.bool,
  setStep: PropTypes.func,
  isGuestMode: PropTypes.bool
}

export default UploadMedia
