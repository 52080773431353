import { StringParam, useQueryParam } from 'use-query-params'
import { useEffect, useMemo, useState } from 'react'

import { message } from 'antd'
import { useGetNewspaperConfig } from 'domains/NewspaperConfig/hooks'
import { useStateWithStorage } from 'hooks'

const NEWSPAPER_DOMAIN_NAME = 'newspaperDomainName'

const useGetDomainName = ({
  domainNameFromHost,
  isDomainNameFromHostExist,
  isDev,
  isProd
}) => {
  const [isLoading, setIsLoading] = useState(null)
  const [domainNameToFetch, setDomainNameToFetch] = useState(null)

  const isNoDomainNameForFetching = useMemo(
    () => domainNameToFetch === null,
    [domainNameToFetch]
  )

  // Fetch database in domain name from url params if dev and on domain name if prod
  const [newspaperConfig, newspaperConfigLoading, newspaperConfigError] =
    useGetNewspaperConfig(domainNameToFetch)
  const [newspaperDomainName] = useQueryParam(
    NEWSPAPER_DOMAIN_NAME,
    StringParam
  )

  /**
   * @function isNewspaperDomainNameExist
   * @description Checks if the newspaper domain name exists
   * @param {string} newspaperDomainName - The domain name of the newspaper
   * @returns {boolean} - Returns true if the newspaper domain name exists, false otherwise
   */
  const isNewspaperDomainNameExist = useMemo(
    () =>
      typeof newspaperDomainName === 'string' &&
      newspaperDomainName?.length > 0,
    [newspaperDomainName]
  )

  /* The newspaper domain name is stored in localStorage. */
  const [newspaperDomainNameInStorage, setNewspaperDomainNameInStorage] =
    useStateWithStorage(null, NEWSPAPER_DOMAIN_NAME)

  /**
   * @function isNewspaperDomainNameInStorageExist
   * @description Checks if the newspaper domain name exists
   * @param {string} newspaperDomainNameInStorage - The domain name of the newspaper
   * @returns {boolean} - Returns true if the newspaper domain name exists, false otherwise
   */
  const isNewspaperDomainNameInStorageExist = useMemo(
    () =>
      typeof newspaperDomainNameInStorage === 'string' &&
      newspaperDomainNameInStorage?.length > 0,
    [newspaperDomainNameInStorage]
  )

  const isNewspaperConfigDataLoaded = useMemo(() => {
    const isDataLoaded =
      isLoading !== null &&
      !newspaperConfigLoading &&
      (newspaperConfig ||
        newspaperConfigError ||
        (!newspaperConfig && !newspaperConfigError))

    // If data was fetched from firestore need total loading set to false
    if (isDataLoaded) {
      setIsLoading(false)
      return !!isDataLoaded
    }

    return !!isDataLoaded
  }, [isLoading, newspaperConfig, newspaperConfigError, newspaperConfigLoading])

  /**
   * @description This function is used to set the domain name for fetching newspaper configuration.
   * @param {boolean} isDev - A boolean value to check if the environment is development.
   * @param {boolean} isNewspaperDomainNameExist - A boolean value to check if the newspaper domain name exists.
   * @param {boolean} isNewspaperDomainNameInStorageExist - A boolean value to check if the newspaper domain name in storage exists.
   * @param {string} newspaperDomainName - The newspaper domain name.
   * @param {string} newspaperDomainNameInStorage - The newspaper domain name in storage.
   * @param {function} setDomainNameForFetch - A function to set the domain name for fetching newspaper configuration.
   * @param {function} setIsLoading - A function to set the loading state.
   * @param {function} message.warn - A function to display a warning message.
   */
  useEffect(() => {
    if (isDev) {
      if (
        (isNewspaperDomainNameExist || isNewspaperDomainNameInStorageExist) &&
        isNoDomainNameForFetching
      ) {
        newspaperDomainName
          ? setDomainNameToFetch(newspaperDomainName)
          : setDomainNameToFetch(newspaperDomainNameInStorage)
      } else {
        // No needed t function as it's only for development
        if (isNoDomainNameForFetching) {
          message.warn('No data for fetching newspaper configuration')
          setIsLoading?.(false)
        }
      }
    }
  }, [
    isNoDomainNameForFetching,
    isNewspaperDomainNameExist,
    isNewspaperDomainNameInStorageExist,
    newspaperDomainName,
    newspaperDomainNameInStorage
  ])

  /**
   * @description This useEffect hook is used to set the domainNameForFetch if the conditions are met.
   * @param {boolean} isProd - A boolean value to check if the environment is production.
   * @param {boolean} isNewspaperConfigDataLoaded - A boolean value to check if the newspaper config data is loaded.
   * @param {boolean} isDomainNameFromHostExist - A boolean value to check if the domain name from host exists.
   * @param {string} domainNameForFetch - A string value to store the domain name for fetch.
   * @param {string} domainNameFromHost - A string value to store the domain name from host.
   */
  useEffect(() => {
    if (
      isProd &&
      !isNewspaperConfigDataLoaded &&
      isDomainNameFromHostExist &&
      isNoDomainNameForFetching
    ) {
      setDomainNameToFetch(domainNameFromHost)
    }
  }, [
    domainNameToFetch,
    isNewspaperConfigDataLoaded,
    isNoDomainNameForFetching
  ])

  // marking of loading state, due to domain configurations data fetch
  useEffect(() => {
    if (isLoading === null && newspaperConfigLoading) {
      setIsLoading(true)
    }
  }, [isLoading, newspaperConfigLoading])

  return {
    newspaperConfig,
    isNewspaperDomainNameExist,
    isNewspaperDomainNameInStorageExist,
    setNewspaperDomainNameInStorage,
    isLoading,
    isNewspaperConfigDataLoaded
  }
}

export default useGetDomainName
