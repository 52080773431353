const styles = {
  buttonBlockStyles: {
    position: 'sticky',
    bottom: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
    py: 3
  },
  summaryButtonWrapperStyles: {
    borderRadius: 'var(--btn-border-radius-base)',
    backgroundColor: 'var(--ql-color-white)'
  },
  modalBodyStyles: {
    borderRadius: '20px',
    height: '80vh',
    width: '100%',
    margin: 0,
    overflow: 'hidden',
    padding: 0
  }
}
export { styles }
