import {
  Box,
  Col,
  Container,
  Img,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'

import { Button } from '@qonsoll/react-design'
import IllustrationSuccessCreate from 'assets/complete.svg'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const NewsTipCreatedSimpleView = (props) => {
  const { timeToClose = 8000, isGuestMode = false } = props
  const { AUTHENTICATED, UNAUTHENTICATED } = PATHS

  const { t } = useTranslations()
  const history = useHistory()

  const navigateAfterWizardClose = () => {
    const pathComputed = isGuestMode
      ? UNAUTHENTICATED.NEWS_TIP_WIZARD
      : AUTHENTICATED.DASHBOARD

    return history.push(pathComputed)
  }
  const handlePrimaryActionButtonClick = () => {
    timer && clearTimeout(timer) // clearing the timer

    /* going back to the main page (tip creation wizard)
			after successful tip submission */
    navigateAfterWizardClose()
  }

  const timer = setTimeout(navigateAfterWizardClose, timeToClose)
  const primaryActionButtonTextComputed = t(
    isGuestMode ? 'Back to main page' : 'Go back to Dashboard'
  )

  return (
    <Container>
      <Row>
        <Col cw={12}>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems={['stretch', 'center']}
            justifyContent={['stretch', 'center']}>
            <Img
              src={IllustrationSuccessCreate}
              alt="Success create"
              width={['100%', 400, 480]}
              mt={['auto', 0]}
              mb={[3, 4]}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={[4, 64]}>
              <Title variant={['h3', 'h1']} textAlign="center" mb={[2, 3]}>
                {t('Hurray your material successfully created')}
              </Title>
              <Text textAlign="center" mb={[3, '20px']}>
                {`${t(
                  'Editors are already reviewing it, as soon as they finish, you will receive a notification'
                )}.`}
              </Text>
              <Box>
                <Button
                  type="primary"
                  size="medium"
                  block
                  onClick={handlePrimaryActionButtonClick}>
                  {primaryActionButtonTextComputed}
                </Button>
              </Box>
            </Box>
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

NewsTipCreatedSimpleView.propTypes = {
  timeToClose: PropTypes.number,
  isGuestMode: PropTypes.bool
}

export default NewsTipCreatedSimpleView
