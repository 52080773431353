import 'firebase/auth'

import { useHistory, useLocation } from 'react-router-dom'

import { Box } from '@qonsoll/react-design'
import PATHS from './paths'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useConfirmation } from 'domains/Confirmation/context'
import { useEffect } from 'react'
import { useHandleError } from 'hooks'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useTranslations } from 'contexts/Translation'

const RoutesRedirect = ({ children }) => {
  const { t } = useTranslations()
  const history = useHistory()
  const location = useLocation()
  const handleError = useHandleError()
  const [user, loading, error] = useAuthState(firebase.auth())
  const {
    isUserHasAccess,
    isNewspaperDomainNameExist,
    isNewspaperDomainNameInStorageExist,
    isNewspaperConfigDataLoaded
  } = useNewspaperConfig()

  const { isUserFinishedConfirmation } = useConfirmation()

  const unauthenticatedPaths = Object.values(PATHS.UNAUTHENTICATED)
  const servicePaths = Object.values(PATHS.SERVICE)

  // Making decision how to redirect
  useEffect(() => {
    /* This code is checking if the current path is in the unauthenticatedPaths array. */
    const isUnauthenticatedPath = unauthenticatedPaths.includes(
      location.pathname
    )

    const isServicePath = servicePaths.includes(location.pathname)

    /* If the user is logged in, and the user's email is verified, then the user is logged in. */
    const isLoggedIn =
      isUnauthenticatedPath &&
      !isServicePath &&
      user &&
      !loading &&
      isUserFinishedConfirmation
    const isLoggedOut =
      !(isUnauthenticatedPath || isServicePath) && !user && !loading

    /* If the user is logged in, redirect to the config page. If the user is logged out, redirect to
    the logout page. If the user's email is not verified, redirect to the email confirmation page.
    */
    isLoggedIn && history.push(PATHS.CONFIG.AFTER_LOGIN)
    isLoggedOut && history.push(PATHS.CONFIG.AFTER_LOGOUT)
  }, [
    history,
    user,
    loading,
    location.pathname,
    location.search,
    isUserFinishedConfirmation
  ])

  useEffect(() => {
    // If the user is not logged in, then do not redirect to access denied, to avoid problems with user login/registration, on localhost
    // if (!user) return
    if (!isNewspaperDomainNameExist && !isNewspaperDomainNameInStorageExist) {
      history.push(PATHS.SERVICE.ACCESS_DENIED)
    } else if (
      isNewspaperDomainNameInStorageExist &&
      !isUserHasAccess &&
      isNewspaperConfigDataLoaded
    ) {
      history.push(PATHS.SERVICE.ACCESS_DENIED)

      //   this else if needed in case when user is on access denied route and admin turn  isWebTippsEnabled to true.
      //   Need  redirect to correct route. If user authorized - dashboard,else to auth route
      //   If this code delete. When admin turn  isWebTippsEnabled to true.
      //   Authorized and unauthorized user will have possibility to press button "Go back"
    } else {
      if (
        location.pathname === PATHS.SERVICE.ACCESS_DENIED &&
        isUserHasAccess &&
        isNewspaperConfigDataLoaded
      ) {
        history.push('/')
      }

      if (isNewspaperConfigDataLoaded && !isUserHasAccess) {
        // newspaper config has been fetched and user doesn't have an access
        history.push(PATHS.SERVICE.ACCESS_DENIED)
      }
    }
  }, [
    location.pathname,
    isNewspaperConfigDataLoaded,
    isUserHasAccess,
    isNewspaperDomainNameExist,
    isNewspaperDomainNameInStorageExist,
    history
  ])

  // Session fetching error handling
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  return loading ? (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Spinner text={t('Loading')} />
    </Box>
  ) : (
    children
  )
}

RoutesRedirect.propTypes = {
  children: PropTypes.element
}

export default RoutesRedirect
