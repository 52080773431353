import { Col } from '@qonsoll/react-design'
import styled from 'styled-components'

export const SelectableCol = styled(Col)`
  cursor: pointer;
  padding: 8px;
  background: ${(props) =>
    props.selected ? 'var(--input-bg)' : 'var(--card-background)'};
  border-right: ${(props) =>
    props.isLast ? 'none' : '1px solid var(--ql-color-dark-t-lighten5)'};
`
