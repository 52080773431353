import { ForgotPasswordForm } from 'domains/Session/components'
import { PageWrapper } from '@qonsoll/react-design'
import { useHandleError } from 'hooks'
import { useSessionActions } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'

const ForgotPassword = (props) => {
  const { isGuestMode = false, guestModeActions = null } = props

  const { t } = useTranslations()
  const { resetPassword } = useSessionActions()
  const onError = useHandleError()

  const onBackActionComputed = isGuestMode
    ? guestModeActions?.handleForgotPasswordFormBack
    : false
  const backBtnPropsComputed = isGuestMode ? { margin: '24px 16px 0' } : null
  const isBackButtonDividedComputed = !isGuestMode

  return (
    <PageWrapper
      onBack={onBackActionComputed}
      backBtnProps={backBtnPropsComputed}
      divided={isBackButtonDividedComputed}
      alignMiddle
      height="100%"
      headingProps={{
        title: t('Restore password'),
        subTitle: t('Enter the email address associated with your account'),
        textAlign: 'center',
        marginBottom: 40
      }}
      contentWidth={['100%', '100%', 400]}>
      <ForgotPasswordForm resetPassword={resetPassword} onError={onError} />
    </PageWrapper>
  )
}

ForgotPassword.propTypes = {
  isGuestMode: PropTypes.bool,
  guestModeActions: PropTypes.shape({
    showPhoneConfirmationForm: PropTypes.func,
    showLoginWithEmailForm: PropTypes.func,
    showForgotPasswordForm: PropTypes.func,
    handleForgotPasswordFormBack: PropTypes.func
  })
}

export default ForgotPassword
