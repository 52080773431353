import { NewsTipCreatedSimpleView } from 'domains/NewsTip/components'
import { PageWrapper } from '@qonsoll/react-design'

const NewsTipSuccessfullyCreated = () => {
  return (
    <PageWrapper>
      <NewsTipCreatedSimpleView />
    </PageWrapper>
  )
}

export default NewsTipSuccessfullyCreated
