import { Col, Container, Row } from '@qonsoll/react-design'

import { PlacesAutocomplete } from 'domains/Location/components'
import { Spinner } from 'components'
import { useLoadScript } from '@react-google-maps/api'

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY

const LocationForm = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: ['places']
  })

  return (
    <Container>
      <Row width="100%" noGutters>
        <Col cw={12}>
          {!isLoaded ? <Spinner /> : <PlacesAutocomplete {...props} />}
        </Col>
      </Row>
    </Container>
  )
}

export default LocationForm
