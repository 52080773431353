import { model, attr, hasOne } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  newspaper: yup
    .string()
    .typeError('Field newspaper has wrong type')
    .default(null)
    .nullable(),
  operationPoints: yup
    .number()
    .typeError('Field operationPoints has wrong type')
    .default(null)
    .nullable(),
  operationType: yup
    .string()
    .typeError('Field operationType has wrong type')
    .default(null)
    .nullable(),
  pointsBeforeOperation: yup
    .number()
    .typeError('Field pointsBeforeOperation has wrong type')
    .default(null)
    .nullable(),
  remainderPoints: yup
    .number()
    .typeError('Field remainderPoints has wrong type')
    .default(null)
    .nullable(),
  user: yup
    .string()
    .typeError('Field user has wrong type')
    .default(null)
    .nullable(),
  newsTip: yup
    .string()
    .typeError('Field newsTip has wrong type')
    .default(null)
    .nullable()
})

const PointsHistory = model(
  'pointsHistory',
  {
    sno: attr('number'),
    newspaper: hasOne('newspaper'),
    operationPoints: attr('number'),
    operationType: attr('string'),
    pointsBeforeOperation: attr('number'),
    remainderPoints: attr('number'),
    user: hasOne('user'),
    newsTip: hasOne('newsTip')
  },
  validationSchema
)

export default PointsHistory
