import { Card } from '@qonsoll/react-design'
import styled from 'styled-components'

export const cardStyles = {
  selected: {
    backgroundColor: 'var(--ql-card-background)',
    border: 'var(--ql-card-border-selected)',
    borderRadius: 'var(--card-radius)'
  },
  default: {
    border: 'var(--ql-card-border-default)',
    borderRadius: 'var(--card-radius)'
  }
}

export const StyledCard = styled(Card)`
  height: 100%;
  &:hover {
    cursor: pointer;
  }
  .ant-card-body {
    height: 100%;
  }
`
