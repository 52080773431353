import {
  createDocument,
  deleteDocument,
  updateDocument
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { message } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useNewsTipCommentActions = (tipComments, newsTipId) => {
  const { user } = useUser()
  const { t } = useTranslations()

  const [commentText, setCommentText] = useState()
  const [editCommentId, setEditCommentId] = useState(false)

  // Update input state, prevent multiple sets of same text to state
  const handleCurrentCommentChange = ({ target }) => {
    target?.value !== commentText && setCommentText(target?.value)
  }

  // Dispatch edit action, by placing selected comment text to input
  const handleEditComment = (commentId, commentText) => {
    // Mark comment that user editing now
    setEditCommentId(commentId)

    // Set comment that user editing to input
    setCommentText(commentText)
  }

  // Close editing process
  const handleResetCommentEdit = () => {
    setCommentText(null)
    setEditCommentId(null)
  }

  // Create comment
  const handleSendComment = async () => {
    try {
      const commentData = {
        text: commentText.trim(),
        tipsId: newsTipId,
        _createdBy: user?._id,
        _updatedBy: null,
        _updatedAt: null
      }

      await createDocument(COLLECTIONS.NEWS_TIPS_COMMENTS, commentData)
      setCommentText(null)
    } catch (e) {
      message.error(t('Error during comment creation, please try again'))
    }
  }

  // Delete comment
  const handleDeleteComment = async (commentId) => {
    try {
      await deleteDocument(COLLECTIONS.NEWS_TIPS_COMMENTS, commentId)

      // When user started edit of comment and before submit decided to delete this comment
      // Clear input and editCommentId state to prevent errors like 'can`t update document that not exist'
      if (editCommentId && commentId === editCommentId) {
        setCommentText(null)
        setEditCommentId(null)
      }
    } catch (e) {
      message.error(t('Error during comment deletion, please try again'))
    }
  }

  // Update existing comment text
  const handleUpdateComment = async () => {
    try {
      const commentPreviousText = tipComments?.find(
        (comment) => comment?._id === editCommentId
      )?.text

      // Just reset input when comment was submitted and not changed
      if (commentText === commentPreviousText) {
        handleResetCommentEdit()
        return
      }

      const updatedCommentData = {
        text: commentText?.trim(),
        _updatedBy: user?._id,
        _updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        isUpdated: true
      }

      await updateDocument(
        COLLECTIONS.NEWS_TIPS_COMMENTS,
        editCommentId,
        updatedCommentData
      )

      handleResetCommentEdit()
    } catch (e) {
      message.error(t('Error during comment update, please try again'))
    }
  }

  // Submit action that define which action we should use update or create comment
  const handleCommentSubmit = () => {
    // Needed when input was submitted without text using enter action
    if (!commentText?.trim()) return

    editCommentId ? handleUpdateComment() : handleSendComment()
  }

  return {
    // State variables
    commentText,
    editCommentId,

    // Actions
    handleCurrentCommentChange,
    handleEditComment,
    handleDeleteComment,
    handleResetCommentEdit,
    handleCommentSubmit
  }
}

export default useNewsTipCommentActions
