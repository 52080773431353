import styled from 'styled-components'
import { Button } from '@qonsoll/react-design'

const ButtonStyled = styled(Button)`
  color: var(--apple-button-default-color);
  background-color: var(--apple-button-default-bg);

  &:hover,
  &:focus {
    color: var(--apple-button-hover-color);
    background-color: var(--apple-button-hover-bg);
  }
`

export default ButtonStyled
