import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { NewsTipWizard } from 'domains/NewsTip/components'
import { useTranslations } from 'contexts/Translation'

const NewsTipWizardPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Configuring page header */
  const headingProps = {
    title: t('Tip Wizard - Guest Mode'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'center'
  }

  return (
    <PageWrapper headingProps={headingProps}>
      <Row noGutters h="center">
        <Col cw={12}>
          <NewsTipWizard isGuestMode={true} />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default NewsTipWizardPage
