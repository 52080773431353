import { Box, Text } from '@qonsoll/react-design'
import styled from 'styled-components'

export const Wrapper = styled(Box)`
  background-color: var(--ql-color-accent1);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 36px;
`

export const TimeText = styled(Text)`
  color: var(--ql-typography-text-color-primary-reverse);
`
