export const ADDED = 'ADDED'
export const SUBTRACT = 'SUBTRACT'
export const SPENT = 'SPENT'

const POINTS_OPERATION_TYPES = {
  ADDED,
  SUBTRACT,
  SPENT
}
export { POINTS_OPERATION_TYPES }
export default POINTS_OPERATION_TYPES
