import { Col, Row } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { STATUSES_COLORS } from '__constants__'
import TagStyled from './NewsTipStatus.styles'

const NewsTipStatus = ({ status }) => {
  return (
    <Row noGutters>
      <Col cw={12}>
        <TagStyled color={STATUSES_COLORS?.[status]}>{status}</TagStyled>
      </Col>
    </Row>
  )
}

NewsTipStatus.propTypes = {
  status: PropTypes.string
}
export default NewsTipStatus
