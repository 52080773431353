import { Box, Card, Col, Row, Text, Title } from '@qonsoll/react-design'
import styles, {
  PointsHistoryImgStyled
} from './PointsHistorySimpleView.styles'

import { EuroCircleFilled } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { useTransformPointsHistoryItemData } from 'domains/PointsHistory/hooks'

const PointsHistorySimpleView = (props) => {
  const { pointsHistory, isTabletOrPhoneDimension } = props

  // Format all fields values to needed structure
  const pointsHistoryItemComputed =
    useTransformPointsHistoryItemData(pointsHistory)

  return (
    <Card bodyStyle={styles?.cardBodyStyle}>
      <Row noOuterGutters v="center">
        {!isTabletOrPhoneDimension && (
          <Col cw="auto">
            <Text type="secondary">
              {
                pointsHistoryItemComputed?.pointsHistoryItemCreationTimeFormatted
              }
            </Text>
          </Col>
        )}
        <Col cw="auto">
          <PointsHistoryImgStyled
            {...pointsHistoryItemComputed?.imageParameters}
          />
        </Col>
        <Col>
          <Row NoGutters v="center">
            <Col cw={[12, 12, 12, 8, 7]} pb={isTabletOrPhoneDimension ? 2 : 0}>
              <Title variant="h5" wordBreak="break-all">
                {pointsHistoryItemComputed?.title || 'n/a'}
              </Title>
            </Col>
            <Col>
              <Text>{pointsHistoryItemComputed?.text}</Text>
            </Col>
            {isTabletOrPhoneDimension && (
              <Col cw={12} pt={2}>
                <Text type="secondary">
                  {
                    pointsHistoryItemComputed?.pointsHistoryItemCreationTimeFormatted
                  }
                </Text>
              </Col>
            )}
          </Row>
        </Col>
        <Col cw={[4, 4, 3, 2, 2]} h="right">
          <Box display="flex" flexDirection="row">
            <Title variant={isTabletOrPhoneDimension ? 'h5' : 'h4'} pr={1}>
              {pointsHistoryItemComputed?.operationPoints}
            </Title>
            <EuroCircleFilled style={styles?.pointsIconStyle} />
          </Box>
        </Col>
      </Row>
    </Card>
  )
}

PointsHistorySimpleView.propTypes = {
  pointsHistory: PropTypes.object,
  index: PropTypes.number,
  isTabletOrPhoneDimension: PropTypes.bool
}

export default PointsHistorySimpleView
