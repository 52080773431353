import { Col, Row, Text } from '@qonsoll/react-design'
import { cloneElement, useRef, useState } from 'react'

import { AddItemCard } from 'components'
import PropTypes from 'prop-types'
import { useClickAway } from 'ahooks'
import { useTranslations } from 'contexts/Translation'

const SettingsCombinedList = (props) => {
  const {
    items,
    children,
    listView,
    onClickAction,
    hideAddCard,
    responsiveColWidthArr,
    height,
    defaultItem,
    dataPropName = 'item',
    isMultiple,
    styledProps,
    ...rest
  } = props

  const [selectedItem, setSelectedItem] = useState(null)

  const onSelectedItem = (item) => item?._id === selectedItem

  const onSingleSelectedItem = (item, onChange) => {
    setSelectedItem?.(item)
    onChange?.(item)
  }

  const onMultipleSelectedItem = (item) =>
    selectedItem?.find((selectedItem) => selectedItem === item?._id)

  const onMultipleSelect = (item, onChange) => {
    const unselectedItem = selectedItem?.filter(
      (selectedItem) => selectedItem !== item
    )
    const hasSelectedItem = selectedItem?.find(
      (selectedItem) => selectedItem === item
    )

    const result = hasSelectedItem ? unselectedItem : [...selectedItem, item]

    setSelectedItem(result)
    onChange?.(result)
  }

  const { t } = useTranslations()

  const AddItemCardWrapper = useRef()

  useClickAway(() => {
    setSelectedItem(null)
  }, AddItemCardWrapper)

  const computedRef = isMultiple ? null : AddItemCardWrapper
  const computedColWidth = listView ? 12 : responsiveColWidthArr || [12, 6, 4]

  return (
    <Row negativeBlockMargin {...styledProps}>
      {!hideAddCard && (
        <Col ref={computedRef} cw={computedColWidth} mb={4}>
          <AddItemCard onClickAction={onClickAction} height={height} />
        </Col>
      )}
      {items?.length > 0 ? (
        items?.map((item, index) => (
          <Col mb={4} key={item?._id || index} cw={computedColWidth}>
            {cloneElement(children, {
              [dataPropName]: item,
              index,
              onSelect: isMultiple ? onMultipleSelect : onSingleSelectedItem,
              isSelected: isMultiple
                ? onMultipleSelectedItem(item)
                : onSelectedItem(item),
              defaultItem,
              selectedItem,
              setSelectedItem,
              ...rest
            })}
          </Col>
        ))
      ) : (
        <Col cw={12}>
          <Text variant="overline" type="secondary">
            {t(`No items have been created yet`)}
          </Text>
        </Col>
      )}
    </Row>
  )
}
SettingsCombinedList.propTypes = {
  styledProps: PropTypes.object,
  children: PropTypes.node,
  items: PropTypes.array,
  listView: PropTypes.bool,
  hideAddCard: PropTypes.bool,
  onClickAction: PropTypes.func,
  responsiveColWidthArr: PropTypes.array,
  height: PropTypes.number,
  defaultItem: PropTypes.object,
  dataPropName: PropTypes.string.isRequired,
  isMultiple: PropTypes.bool
}

export default SettingsCombinedList
