import { collection, query, where } from 'firebase/firestore'

import COLLECTIONS from '__constants__/collections'
import firestore from 'services/firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetNewspaperConfig = (domainName) => {
  const computedQuery = useMemo(
    () =>
      domainName
        ? query(
            collection(firestore, COLLECTIONS.WEB_TIPS_CONFIGURATIONS),
            where('domainName', '==', domainName)
          )
        : undefined,
    [domainName]
  )
  const [newspaperConfigs, newspaperConfigLoading, newspaperConfigError] =
    useCollectionData(computedQuery)

  return useMemo(
    () => [
      newspaperConfigs?.length ? newspaperConfigs?.pop() : null,
      newspaperConfigLoading,
      newspaperConfigError
    ],
    [newspaperConfigError, newspaperConfigLoading, newspaperConfigs]
  )
}

export default useGetNewspaperConfig
