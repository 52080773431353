import {
  ADDED,
  COLLECTIONS,
  MOMENT_FORMATS,
  SPENT,
  SUBTRACT
} from '__constants__'

import { formatPointsNumber } from 'domains/PointsHistory/helpers'
import moment from 'moment'
import noImage from 'assets/NoImage.png'
import { useGetDocumentById } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const useTransformPointsHistoryItemData = ({
  _createdAt,
  remainderPoints,
  pointsBeforeOperation,
  operationPoints,
  operationType,
  newsTipId,
  rewardId,
  dateOfPointsEarned
}) => {
  const { t } = useTranslations()

  const [tips] = useGetDocumentById(COLLECTIONS.NEWS_TIPS, newsTipId)

  const [reward] = useGetDocumentById(COLLECTIONS.REWARDS, rewardId)

  const pointsHistoryItemCreationTimeFormatted =
    _createdAt &&
    moment(_createdAt?.toDate()).format(MOMENT_FORMATS.HOURS_MINUTES)

  const imageUrlComputed =
    SPENT === operationType ? reward?.imageUrl : tips?.photoUrl?.[0]

  const handleErrorLoadImage = ({ currentTarget }) => {
    currentTarget.onerror = null
    currentTarget.src = noImage
  }

  const imageParameters = {
    src: imageUrlComputed || noImage,
    onError: handleErrorLoadImage
  }

  const formattedDateOfPointsEarned =
    dateOfPointsEarned &&
    moment(dateOfPointsEarned?.toDate())?.format(
      MOMENT_FORMATS?.MONTH_DAYS_YEAR
    )

  const computedSubtractText = dateOfPointsEarned
    ? `${t('Points you earned on')} ${formattedDateOfPointsEarned} ${t(
        'were expired'
      )}`
    : `${t('Tipp cost was reduced')}`

  const computedSubtractTitle = dateOfPointsEarned
    ? `${t('Points were lost')} 😢`
    : tips?.title

  const messageArray = {
    [ADDED]: {
      text:
        remainderPoints - pointsBeforeOperation < operationPoints
          ? `${t('Tipp cost was increased')}`
          : `${t('Tipp was approved')}`,
      title: tips && tips?.title,
      operationPoints: `+${formatPointsNumber(
        remainderPoints - pointsBeforeOperation,
        2
      )}`
    },
    [SUBTRACT]: {
      text: computedSubtractText,
      title: computedSubtractTitle,
      operationPoints: formatPointsNumber(
        remainderPoints - pointsBeforeOperation,
        2
      )
    },
    [SPENT]: {
      text: `${t('The reward was redeemed')}`,
      title: reward && reward?.name,
      operationPoints: `-${formatPointsNumber(operationPoints, 2)}`
    }
  }

  return {
    pointsHistoryItemCreationTimeFormatted,
    imageParameters,
    ...messageArray[operationType]
  }
}
export default useTransformPointsHistoryItemData
