import { useCallback, useEffect, useMemo, useState } from 'react'

import { ENDPOINTS } from '__constants__'
import { message } from 'antd'
import { useUser } from 'domains/User/context'

const useGetImageTags = (photo, tipId, isMediaUploading) => {
  const { user } = useUser()
  const [imageTags, setImageTags] = useState([])
  const [loading, setLoading] = useState(true)

  const photoId = photo?.id
  const usedPhoto =
    !!photoId && `${photoId}_${tipId}_${user?._id || 'guest_mode'}_photo`

  const fetchImageTags = useCallback(async () => {
    if (!usedPhoto) {
      setLoading(false)
      setImageTags([])
    } else {
      setLoading(true)
      try {
        const fileName = usedPhoto
        const tags = await fetch(ENDPOINTS.GET_IMAGE_SUGGESTIONS, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fileName
          })
        })
        const result = tags && (await tags.json())
        result && setImageTags(result?.data?.map((tag) => tag?.name))
      } catch (error) {
        message.error('Error during get image suggestions')
      } finally {
        setLoading(false)
      }
    }
  }, [usedPhoto])

  useEffect(() => {
    let isMounted = true

    isMounted && fetchImageTags()

    return () => {
      isMounted = false
    }
  }, [fetchImageTags])

  const computedLoading = useMemo(
    () => loading || isMediaUploading,
    [isMediaUploading, loading]
  )

  return [imageTags, computedLoading]
}

export default useGetImageTags
