import { Box, Button, Col, Row, Text } from '@qonsoll/react-design'
import { HoverableBox, boxStyles } from './AddItemCard.styles'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const AddItemCard = (props) => {
  const { height = '100%', onClick, message, withMessage = true } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  return (
    <HoverableBox>
      <Box {...boxStyles} height={height} onClick={onClick}>
        <Row v="center" noGutters>
          <Col v="center" h="center">
            <Button
              type="link"
              onClick={onClick}
              icon={<Icon name="Plus1Outlined" />}
            />
            {withMessage && <Text variant="body1">{message || t('Add')}</Text>}
          </Col>
        </Row>
      </Box>
    </HoverableBox>
  )
}

AddItemCard.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  height: PropTypes.string,
  withMessage: PropTypes.bool
}

export default AddItemCard
