import {
  BackgroundBoxStyled,
  CloseButtonStyled,
  ContentContainer,
  EmojiContainerStyled,
  EmojiRowStyled,
  modalBodyStyles,
  RewardImageStyled
} from './RewardStoreModal.styled'

import { CloseOutlined } from '@ant-design/icons'
import { Emoji, Stopwatch } from 'components'
import { Fragment } from 'react'
import { Modal } from 'antd'
import PropTypes from 'prop-types'
import { useTranslations } from '@qonsoll/translation'
import { Title } from '@qonsoll/react-design'

const RewardStoreModal = (props) => {
  const { isModalVisible, setIsModalVisible, reward } = props

  const { t } = useTranslations()

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const emojiChunksAmount = new Array(30).fill(1)

  const pointsText = `${reward?.points} kr`

  return (
    <Modal
      centered
      footer={null}
      visible={isModalVisible}
      okText={t('Confirm')}
      cancelText={t('Cancel')}
      title={null}
      closable={false}
      bodyStyle={modalBodyStyles}
      onCancel={handleCancel}>
      <BackgroundBoxStyled>
        <CloseButtonStyled onClick={handleCancel} shape="circle">
          <CloseOutlined />
        </CloseButtonStyled>
        <EmojiContainerStyled>
          {reward?.emoji &&
            emojiChunksAmount.map((_, index) => (
              <Fragment key={index}>
                <EmojiRowStyled index={index}>
                  <Emoji emoji={reward?.emoji} />
                  <Emoji emoji={reward?.emoji} />
                </EmojiRowStyled>
                <Emoji emoji={reward?.emoji} />
              </Fragment>
            ))}
        </EmojiContainerStyled>
        <ContentContainer>
          <Stopwatch isModalVisible={isModalVisible} />
          <RewardImageStyled src={reward?.imageUrl} />
          <Title level={4}>{pointsText}</Title>
          <Title level={5}>{reward?.name}</Title>
        </ContentContainer>
      </BackgroundBoxStyled>
    </Modal>
  )
}

RewardStoreModal.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  reward: PropTypes.object,
  userPoints: PropTypes.number
}

export default RewardStoreModal
