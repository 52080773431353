import { AppleOutlined } from '@ant-design/icons'
import ButtonStyled from './AppleButton.styled'
import PropTypes from 'prop-types'

const AppleButton = (props) => {
  const { text, ...rest } = props

  return (
    <ButtonStyled size="large" block icon={<AppleOutlined />} {...rest}>
      {text}
    </ButtonStyled>
  )
}
AppleButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
}

export default AppleButton
