import { AppleButton, FacebookButton } from 'components'
import { Box, Button, Col, Divider, Row, Text } from '@qonsoll/react-design'
import { Link, useHistory } from 'react-router-dom'
import {
  useAppleLogin,
  useFacebookLogin,
  useSessionActions
} from 'domains/Session/hooks'

import { Icon } from '@qonsoll/icons'
import { LastSessionUser } from 'domains/Session/components'
import { MailOutlined } from '@ant-design/icons'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const LoginMethods = (props) => {
  const {
    authTypeText,
    // googleAuth,
    redirectToFormWithEmail,
    authSwitchText,
    authSwitchLinkText,
    authSwitchPath,
    lastSession,
    isGuestMode = false,
    guestModeActions
  } = props

  const { t } = useTranslations()
  const history = useHistory()
  const { getLastSessionProvider } = useSessionActions()
  const { facebookLogin, loading: facebookLoginLoading } = useFacebookLogin()
  const { appleLogin, loading: appleLoginLoading } = useAppleLogin()
  const sessionProvider = getLastSessionProvider()

  const redirectToTipCreationWizard = () => {
    history.push(PATHS.UNAUTHENTICATED.NEWS_TIP_WIZARD)
  }
  const handleAnonymousAuthButtonClick = (e) => {
    if (isGuestMode) {
      guestModeActions?.showPhoneConfirmationForm?.()
    } else {
      redirectToTipCreationWizard()
    }
  }
  const emailClickAction = () => {
    isGuestMode
      ? guestModeActions?.showLoginWithEmailForm()
      : redirectToFormWithEmail()
  }

  const facebookButtonText = `${authTypeText} ${t('with Facebook')}`
  const appleButtonText = `${authTypeText} ${t('with Apple')}`
  const anonymousAuthButtonTextComputed = isGuestMode
    ? t('Continue as guest')
    : t('Tip in the Guest Mode') + '!'
  const anonymousAuthButtonTypeComputed = isGuestMode ? 'primary' : 'secondary'

  return (
    <>
      <FacebookButton
        text={facebookButtonText}
        onClick={facebookLogin}
        loading={facebookLoginLoading}
        mb={3}
      />
      <AppleButton
        onClick={appleLogin}
        loading={appleLoginLoading}
        text={appleButtonText}
      />
      <Box>
        <Divider>
          <Text variant="overline" type="secondary">
            {t('Or')}
          </Text>
        </Divider>
      </Box>

      {sessionProvider === 'email' && lastSession?.email ? (
        <LastSessionUser
          lastSession={lastSession}
          onChangeAccount={emailClickAction}
          redirectTo={emailClickAction}
        />
      ) : (
        <Button
          size="large"
          block
          icon={<MailOutlined />}
          onClick={emailClickAction}>
          {authTypeText} {t('with email')}
        </Button>
      )}

      <Row noGutters justifyContent="center" mt={24}>
        <Col>
          <Button
            type={anonymousAuthButtonTypeComputed}
            size="large"
            block
            icon={<Icon mr={2} name="ImageUserXOutlined" fill="currentColor" />}
            onClick={handleAnonymousAuthButtonClick}>
            {anonymousAuthButtonTextComputed}
          </Button>
        </Col>
      </Row>

      <Row noGutters justifyContent="center" mt={24}>
        <Col cw="auto">
          <Box display="flex">
            <Text type="secondary" mr={1}>
              {authSwitchText}
            </Text>
            {isGuestMode ? (
              <Link onClick={guestModeActions?.showSignUpForm}>
                {authSwitchLinkText}
              </Link>
            ) : (
              <Link to={authSwitchPath}>{authSwitchLinkText}</Link>
            )}
          </Box>
        </Col>
      </Row>
    </>
  )
}

LoginMethods.propTypes = {
  authSwitchLinkText: PropTypes.any,
  authSwitchPath: PropTypes.any,
  authSwitchText: PropTypes.any,
  authTypeText: PropTypes.any,
  lastSession: PropTypes.any,
  redirectToFormWithEmail: PropTypes.any,
  isGuestMode: PropTypes.bool,
  guestModeActions: PropTypes.shape({
    showPhoneConfirmationForm: PropTypes.func,
    showLoginWithEmailForm: PropTypes.func,
    showSignUpForm: PropTypes.func
  })
}

export default LoginMethods
