import { useTranslations } from '@qonsoll/translation'
import { SESSION_ERROR_CODES_AND_TEXT_MAP } from '__constants__'
import { message } from 'antd'
import {
  getAuth,
  FacebookAuthProvider,
  linkWithPopup,
  unlink
} from 'firebase/auth'
import { useState } from 'react'

const useLinkFacebookProvider = (getLinkedProviders) => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslations()

  const unlinkFacebookProvider = async () => {
    try {
      setLoading(true)

      const auth = getAuth()
      const currentUser = auth.currentUser

      const provider = new FacebookAuthProvider()

      await unlink(currentUser, provider?.providerId)

      message.success(t('You successfully unlinked Facebook from your account'))

      getLinkedProviders?.()
    } catch (error) {
      message.error(
        t('Something went wrong during Facebook provider unlinking')
      )
    } finally {
      setLoading(false)
    }
  }

  const linkFacebookProvider = async () => {
    try {
      setLoading(true)

      const auth = getAuth()
      const currentUser = auth.currentUser

      const provider = new FacebookAuthProvider()

      await linkWithPopup(currentUser, provider)

      message.success(t('Facebook successfully linked to your account'))

      getLinkedProviders?.()
    } catch (error) {
      const errorMessage = SESSION_ERROR_CODES_AND_TEXT_MAP?.[error?.code]

      message.error(
        errorMessage
          ? t(errorMessage)
          : `${t(
              'Something went wrong during connecting facebook provider'
            )}, ${error?.message}`
      )
    } finally {
      setLoading(false)
    }
  }

  return { linkFacebookProvider, unlinkFacebookProvider, loading }
}

export default useLinkFacebookProvider
