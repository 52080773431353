import { Img } from '@qonsoll/react-design'
import styled from 'styled-components'

export const PointsHistoryImgStyled = styled(Img)`
  height: 64px;
  width: 64px;
  border-radius: var(--card-radius);
  object-fit: contain;
`

export const styles = {
  fieldTitleProps: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px',
    whiteSpace: 'nowrap'
  },
  fieldRowProps: {
    justifyContent: 'center',
    alignItems: 'center',
    py: '4px',
    mb: 2
  },
  pointsIconStyle: { fontSize: '24px', color: '#ffcc33' },
  cardBodyStyle: { padding: '12px' }
}

export default styles
