import PATHS from 'pages/paths'
import { useEvent } from 'hooks'
import { generatePath, useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useUser } from 'domains/User/context'

const useProfileBaseActions = () => {
  /* Getting history instance */
  const history = useHistory()
  const { user } = useUser()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    history.push(PATHS.AUTHENTICATED.USER_SHOW)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    history.push(
      generatePath(PATHS.AUTHENTICATED.USER_EDIT, { userId: user?._id })
    )
  })
  /* Handling open change password */
  const handleChangePassword = useEvent(() => {
    history.push(PATHS.AUTHENTICATED.RESET_PASSWORD)
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onChangePassword: handleChangePassword
    }),
    [handleChangePassword, handleBack, handleEdit, handleOpen]
  )
}

export default useProfileBaseActions
