import { useState } from 'react'
import { OAuthProvider, signInWithPopup } from 'firebase/auth'
import { useConfirmation } from 'domains/Confirmation/context'
import { auth } from 'services/firebase'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { useSignInWithProviderCredentials } from 'domains/Session/hooks'

const useAppleLogin = () => {
  const [loading, setLoading] = useState(false)
  const { setIsUserFinishedConfirmation } = useConfirmation()
  const signInWithProviderCredentials = useSignInWithProviderCredentials()
  const { t } = useTranslations()
  const appleLogin = async () => {
    try {
      setIsUserFinishedConfirmation(false)
      setLoading(true)

      const provider = new OAuthProvider('apple.com')
      const credentials = await signInWithPopup(auth, provider)

      await signInWithProviderCredentials(credentials, credentials?.user)
    } catch (e) {
      message.error(
        `${t('Something went wrong during apple login')}, ${e?.message}`
      )
    } finally {
      setLoading(false)
      setIsUserFinishedConfirmation(true)
    }
  }

  return { appleLogin, loading }
}

export default useAppleLogin
