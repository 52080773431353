import { StyledText } from './Emoji.styled'
import PropTypes from 'prop-types'

const Emoji = (props) => {
  const { emoji } = props

  return (
    <StyledText>
      {emoji ? String.fromCodePoint(parseInt(emoji, 16)) : ''}
    </StyledText>
  )
}

Emoji.propTypes = {
  emoji: PropTypes.string
}
export default Emoji
