import { ENDPOINTS, USER_EMAIL_EXISTENCE_RDB } from '__constants__'
import { get, getDatabase, ref } from 'firebase/database'
import { getAuth, getIdTokenResult } from 'firebase/auth'

import MD5 from 'md5'

const useOldUserLogin = () => {
  const oldUserLogin = async ({ email }) => {
    let emailToDraftRecordsExistence
    const auth = getAuth()
    const database = getDatabase()

    try {
      // Hash user email to check if it exists in RDB draft users collection
      const hash = MD5(email).toString()

      // Check user hashed email existence in RDB
      const snapEmailToDraftRecordsExistence = ref(
        database,
        `${USER_EMAIL_EXISTENCE_RDB}/${hash}`
      )

      const snapshot = await get(snapEmailToDraftRecordsExistence)

      emailToDraftRecordsExistence = snapshot.exists()
    } catch (error) {
      throw new Error(error)
    }

    if (!emailToDraftRecordsExistence) return false

    // If email exists in draft users collection - call API
    // to attach all user old data to current account
    try {
      const idTokenResult = await getIdTokenResult(auth.currentUser)

      const body = {
        email,
        token: idTokenResult.token
      }

      const result = await fetch(ENDPOINTS.ON_OLD_USER_LOGIN, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })

      const responseData = await result.json()

      return responseData
    } catch (error) {
      throw new Error(error)
    }
  }

  return oldUserLogin
}

export default useOldUserLogin
