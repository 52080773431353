import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { useState } from 'react'
import { getAuth, getIdToken } from 'firebase/auth'
import { ENDPOINTS } from '__constants__'

const useRemoveUser = () => {
  const { t } = useTranslations()

  const [removeUserLoading, setRemoveUserLoading] = useState(false)

  const handleRemoveUser = async () => {
    try {
      setRemoveUserLoading(true)
      const auth = getAuth()
      const { currentUser } = auth
      const jwtToken = await getIdToken(currentUser, false)

      const response = await fetch(ENDPOINTS.DELETE_USER, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${jwtToken}`
        })
      })

      const data = await response.json()

      if (data?.statusCode === 200 && response?.ok) {
        message.success(t('User has been removed successfully'))
        return
      }

      throw new Error(data?.error || 'Something went wrong')
    } catch (error) {
      message.error(`${t('Error during remove user')}, ${error.message}`)
    } finally {
      setRemoveUserLoading(false)
    }
  }

  return { handleRemoveUser, removeUserLoading }
}

export default useRemoveUser
