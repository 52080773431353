import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { PageActions, Spinner } from 'components'
import { RewardList, RewardSortMenu } from 'domains/Reward/components'
import { useFilter, useListView } from 'hooks'
import { useGetRewards, useRewardBaseActions } from 'domains/Reward/hooks'

import { ExpiredPointsSimpleView } from 'domains/ExpiredPoints/components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useTranslations } from 'contexts/Translation'

const RewardsAllPage = (props) => {
  const { isListWithCreate, withHeader = true } = props

  const { rewardsList } = useNewspaperConfig()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* State for controlling list view with localStorage remembering */
  const [listView, setListView] = useListView('reward')
  /* Getting filter params */
  const filterParams = useFilter('reward')
  /* Destructuring filter params */
  const { filterData, setFilterData } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching rewards */
  const [rewards, loading] = useGetRewards(ref)

  /* Getting base actions */
  const listActions = useRewardBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: rewardsList?.title || t('Rewards'),
    titleSize: 3,
    subTitle: rewardsList?.description,
    marginBottom: 2,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    // Temporarily hidden
    // filter: true,
    // sort: true,
    // listView: true,
    create: Boolean(
      PATHS.AUTHENTICATED.REWARD_CREATE || PATHS.AUTHENTICATED.REWARD_WIZARD
    )
  }

  /* Extracting base actions */
  const { onCreate } = listActions

  return (
    <PageWrapper
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            listView={listView}
            setListView={setListView}
            onCreate={onCreate}
            actions={actions}
            createText={t('Create reward')}
            sortMenu={
              <RewardSortMenu
                filteredData={filterData}
                setFilteredData={setFilterData}
              />
            }
          />
        ) : null
      }
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {/* Temporarily hidden
         {filterVisibility && (
          <Col mr={filterVisibility ? 4 : undefined} cw="auto">
            <RewardFilter {...filterParams} />
          </Col>
        )} */}
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Reward loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <ExpiredPointsSimpleView />

            <RewardList
              listView={listView}
              isListWithCreate={isListWithCreate}
              actions={{ onCreate }}
              rewards={rewards}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

RewardsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default RewardsAllPage
