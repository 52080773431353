import { FacebookOutlined } from '@ant-design/icons'
import ButtonStyled from './FacebookButton.styled'
import PropTypes from 'prop-types'

const FacebookButton = (props) => {
  const { text, size = 'large', ...rest } = props

  return (
    <ButtonStyled size={size} block icon={<FacebookOutlined />} {...rest}>
      {text}
    </ButtonStyled>
  )
}
FacebookButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string
}

export default FacebookButton
