import {
  LoadingContainerStyled,
  LoadingTextStyled,
  OverlayContainerStyled
} from './LoadingOverlay.styled'

import PropTypes from 'prop-types'
import { Spin } from 'antd'

const LoadingOverlay = (props) => {
  const { loadingText = null, loadingTextComponent = null } = props

  return (
    <OverlayContainerStyled>
      <LoadingContainerStyled>
        <Spin size="large" />
        {loadingTextComponent || (
          <LoadingTextStyled>{loadingText ?? null}</LoadingTextStyled>
        )}
      </LoadingContainerStyled>
    </OverlayContainerStyled>
  )
}

LoadingOverlay.propTypes = {
  loadingText: PropTypes.string,
  loadingTextComponent: PropTypes.node
}

export default LoadingOverlay
