import { Box, Col, Row } from '@qonsoll/react-design'
import { CommentsCard, styles } from './NewsTipCommentSection.styles'
import {
  NewsTipCommentsList,
  NewsTipCommentsSectionHeader
} from 'domains/NewsTip/components'
import {
  useGetNewsTipComments,
  useNewsTipCommentActions
} from 'domains/NewsTip/hooks'

import { Spinner } from 'components'
import { useParams } from 'react-router-dom'

const NewsTipCommentSection = () => {
  const { newsTipId } = useParams()

  const [tipComments, tipCommentsLoading] = useGetNewsTipComments(newsTipId)

  const {
    // State variables
    commentText,
    editCommentId,

    // Actions
    handleCurrentCommentChange,
    handleEditComment,
    handleDeleteComment,
    handleResetCommentEdit,
    handleCommentSubmit
  } = useNewsTipCommentActions(tipComments, newsTipId)

  return (
    <CommentsCard
      headStyle={styles?.commentsCardHeadStyles}
      bodyStyle={styles?.commentsCardBodyStyles}
      // Used title property for input and section header
      // to make header fixed and scrollbar placement better
      title={
        <NewsTipCommentsSectionHeader
          commentText={commentText}
          editCommentId={editCommentId}
          handleCommentSubmit={handleCommentSubmit}
          handleResetCommentEdit={handleResetCommentEdit}
          handleCurrentCommentChange={handleCurrentCommentChange}
        />
      }>
      <Row noGutters>
        <Col cw={12}>
          {/* Loading section */}
          {tipCommentsLoading && (
            <Box {...styles.loadingStyles}>
              <Spinner text={'Comments loading'} />
            </Box>
          )}

          {!tipCommentsLoading && !!tipComments?.length && (
            <NewsTipCommentsList
              tipComments={tipComments}
              handleEditComment={handleEditComment}
              handleDeleteComment={handleDeleteComment}
            />
          )}
        </Col>
      </Row>
    </CommentsCard>
  )
}

export default NewsTipCommentSection
