import { Redirect, Route, Switch } from 'react-router-dom'
import { BoilerplateLayout } from 'components'

import AccessDenied from './AccessDenied'
import GDPRPage from './GDPRPage'
import NotFound from './NotFound'
import PATHS from '../paths'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'

const { ACCESS_DENIED, NOT_FOUND, GDPR } = PATHS.SERVICE

const routes = [
  {
    key: 'ACCESS_DENIED',
    path: ACCESS_DENIED,
    component: AccessDenied,
    exact: true
  },
  { key: 'NOT_FOUND', path: NOT_FOUND, component: NotFound, exact: true },
  { key: 'GDPR', path: GDPR, component: GDPRPage, exact: true }
]

const Service = () => {
  const { isUserHasAccess } = useNewspaperConfig()

  const serviceContent = (
    <Switch>
      {routes.map((routeProps) => (
        <Route key={routeProps.key} {...routeProps} />
      ))}
      <Redirect to={PATHS.SERVICE.NOT_FOUND} />
    </Switch>
  )

  if (isUserHasAccess)
    return <BoilerplateLayout>{serviceContent}</BoilerplateLayout>

  /* The `return serviceContent` statement is returning the JSX content of the `Service` component. If
	the `isUserHasAccess` variable is true, it wraps the `serviceContent` JSX with the
	`BoilerplateLayout` component. If `isUserHasAccess` is false, it returns the `serviceContent` JSX
	as is. */
  return serviceContent
}

export default Service
