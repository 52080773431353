import { Col, Row, Text } from '@qonsoll/react-design'
import { Image, VideoPlayerWrapper } from './NewsTipMediaList.styles'

import { Card } from 'antd'
import PropTypes from 'prop-types'
import { VideoPlayer } from 'components'
import { useTranslations } from 'contexts/Translation'

const NewsTipMediaList = (props) => {
  const { photoUrls, videoUrl } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Card>
      <Row noOuterGutters>
        <Col cw={12} mb={2}>
          <Text variant="overline" type="secondary">
            {t('Media')}
          </Text>
        </Col>
      </Row>
      <Row noOuterGutters flexWrap="nowrap" overflowX="scroll">
        {photoUrls?.length || videoUrl ? (
          <>
            {photoUrls?.map((url) => (
              <Col cw={[6, 6, 6, 4, 3]} key={url} mb={2} px={2}>
                <Image
                  src={url}
                  // Fix border radius for state with dark bg on hover
                  preview={{ maskClassName: 'image-preview-br' }}
                />
              </Col>
            ))}
            {videoUrl && (
              <Col cw={[6, 6, 6, 4, 3]} mb={2} px={2}>
                <VideoPlayerWrapper>
                  <VideoPlayer src={videoUrl} />
                </VideoPlayerWrapper>
              </Col>
            )}
          </>
        ) : (
          <Col cw={12}>
            <Text type="secondary">{t('There is no media')}</Text>
          </Col>
        )}
      </Row>
    </Card>
  )
}

NewsTipMediaList.propTypes = {
  photoUrls: PropTypes.array,
  videoUrl: PropTypes.string
}
export default NewsTipMediaList
