export const REWARD_TYPES = {
  DONATION: 'DONATION',
  MAIL: 'MAIL',
  EMAIL: 'EMAIL',
  STORE: 'STORE'
}

export const REWARDS_WITH_CONFIRMATION_MODAL = [
  REWARD_TYPES.MAIL,
  REWARD_TYPES.EMAIL
]

export default REWARD_TYPES
