import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { getAuth, RecaptchaVerifier } from 'firebase/auth'
import { usePhoneNumber } from 'hooks'
import { useConfirmation } from 'domains/Confirmation/context'
import PATHS from 'pages/paths'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import firebase from 'firebase/compat/app'

const useChangeUserPhone = () => {
  const [loading, setLoading] = useState(false)

  const { t } = useTranslations()
  const { transformPhoneNumber } = usePhoneNumber()
  const { setIsPhoneUpdate, setIsUserFinishedConfirmation } = useConfirmation()
  const history = useHistory()

  // Getting auth instance
  const auth = getAuth()

  // Create provider for phone auth
  const provider = new firebase.auth.PhoneAuthProvider()

  const changeUserPhone = async (phone, userData) => {
    try {
      if (!phone) return

      setLoading(true)

      // Create recaptcha verifier
      const appVerifier = new RecaptchaVerifier(
        'verify-phone-button',
        {
          size: 'invisible'
        },
        auth
      )

      // Transforming phone number to the correct format
      const transformedPhoneNumber = transformPhoneNumber(phone)

      // Sending verification code to the user phone number
      const verification = await provider.verifyPhoneNumber(
        transformedPhoneNumber,
        appVerifier
      )

      setLoading(false)

      setIsPhoneUpdate(verification)

      setIsUserFinishedConfirmation(false)

      // Redirecting to the confirmation page
      history.push(PATHS.UNAUTHENTICATED.CONFIRM_PHONE, { phone, ...userData })
    } catch (error) {
      message.error(t('Something went wrong during phone change'))
    }
  }

  return { changeUserPhone, loading }
}

export default useChangeUserPhone
