import { Box, Img, Text, Title } from '@qonsoll/react-design'

import arrowImage from 'assets/arrowCroped.png'
import noTippsImage from 'assets/no-tipps.png'
import styles from './TippsNoData.styles'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useTranslations } from '@qonsoll/translation'

const TippsNoData = () => {
  const { t } = useTranslations()
  const { dashboard } = useNewspaperConfig()

  const dashboardGreetings = dashboard?.title || t('Hi!')

  const dashboardMessage =
    dashboard?.description ||
    `${t('Your first tip is just a click away')}. ${t(
      'Tap on the + to send your first tip'
    )}`
  return (
    <Box {...styles.wrapper}>
      <Box {...styles.imageWrapper} alignSelf="flex-end">
        <Img src={arrowImage} {...styles.image} />
      </Box>

      <Box {...styles.imageWrapper} width={[140, 200, 280]} mb={2}>
        <Img src={noTippsImage} {...styles.image} />
      </Box>
      <Title wordBreak="break-all" level={4} my={1} textAlign="center">
        {dashboardGreetings}
      </Title>
      <Text my={1} wordBreak="break-all" textAlign="center">
        {dashboardMessage}
      </Text>
    </Box>
  )
}

export default TippsNoData
