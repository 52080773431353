import { model, hasOne, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  location: yup
    .string()
    .typeError('Field location has wrong type')
    .default(null)
    .nullable()
})

const Tip = model(
  'tip',
  {
    status: hasOne('tipStatus'),
    title: attr('string'),
    sno: attr('number'),
    name: attr('string'),
    location: attr('string')
  },
  validationSchema
)

export default Tip
