import { doc, onSnapshot } from 'firebase/firestore'
import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import firestore from 'services/firebase/firestore'

/**
 * It takes a Firestore document reference and returns the document's data, a loading state, and an
 * error state
 * @param ref {string} - The document reference
 * @param where {array} - The query where clause
 * @returns An array with the value, loading, and error.
 */
const useListenDocument = (props) => {
  const [value, setValue] = useState()
  const [loading, setLoading] = useState(!!props.ref)
  const [error, setError] = useState()

  useEffect(() => {
    try {
      const unsubscribe = onSnapshot(doc(firestore, props.ref), (doc) => {
        setValue(doc.data())
        setLoading(false)
      })
      return () => {
        unsubscribe?.()
      }
    } catch (err) {
      setError(err)
    }
  }, [props?.ref])

  return [value, loading, error]
}

useListenDocument.propTypes = {
  ref: PropTypes.string.isRequired,
  where: PropTypes.array
}

export default useListenDocument
