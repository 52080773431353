import { Modal } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import {
  useGetConfirmationModalContent,
  useRedeemReward
} from 'domains/Reward/hooks'
import { Box, Text } from '@qonsoll/react-design'
import { StyledInput } from './RewardRedeemConfirmationModal.styled'
import { useUser } from 'domains/User/context'
import PropTypes from 'prop-types'
import { REWARD_OVERVIEW_STATUSES, REWARD_TYPES } from '__constants__'
import { LocationForm } from 'domains/Location/components'

const RewardRedeemConfirmationModal = (props) => {
  const { isModalVisible, setIsModalVisible, reward, userPoints } = props

  const { t } = useTranslations()
  const { user } = useUser()

  const { redeemReward, loading } = useRedeemReward()
  const [inputValue, setInputValue, confirmationText, inputPlaceholder] =
    useGetConfirmationModalContent(reward?.type, user?.email, isModalVisible)

  const onInputValueChange = (data) => {
    reward?.type === REWARD_TYPES.MAIL
      ? setInputValue(data?.locationId)
      : setInputValue(data?.target?.value)
  }

  const handleOk = async () => {
    /* There is no need for a try/catch block because it is already inside the function redeemReward */
    await redeemReward(
      { ...reward, _status: REWARD_OVERVIEW_STATUSES.PENDING },
      { inputValue, userPoints }
    )
    setIsModalVisible(false)
    setInputValue('')
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInputValue('')
  }

  return (
    <Modal
      centered
      visible={isModalVisible}
      okText={t('Confirm')}
      cancelText={t('Cancel')}
      okButtonProps={{
        loading,
        disabled: !inputValue
      }}
      title={t('Confirm data')}
      onOk={handleOk}
      onCancel={handleCancel}>
      <Text mb="8px">{confirmationText}</Text>
      {reward?.type === REWARD_TYPES.MAIL ? (
        <Box mt={3}>
          <LocationForm
            value={inputValue}
            onChange={onInputValueChange}
            withTitle={false}
            withSubtitle={false}
          />
        </Box>
      ) : (
        <StyledInput
          placeholder={t(inputPlaceholder)}
          value={inputValue}
          onChange={onInputValueChange}
        />
      )}
    </Modal>
  )
}

RewardRedeemConfirmationModal.propTypes = {
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  reward: PropTypes.object,
  userPoints: PropTypes.number
}

export default RewardRedeemConfirmationModal
