import { Checkbox } from 'antd'
import styled from 'styled-components'

const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    border-radius: var(--ql-border-radius-small);
  }
  .ant-checkbox-checked::after {
    border-radius: var(--ql-border-radius-small);
  }
`
export { StyledCheckbox }
