import { MOMENT_FORMATS } from '__constants__'
import moment from 'moment'
import { useMemo } from 'react'

const useTransformPointsHistory = (pointsHistories) => {
  const transformedPointsHistories = useMemo(
    () =>
      pointsHistories?.length &&
      pointsHistories.reduce((result, currentItem) => {
        const itemCreationDateFormatted = moment(
          currentItem?._createdAt?.toDate()
        ).format(MOMENT_FORMATS.MONTH_DAYS_YEAR)
        if (!Object.keys(result).includes(itemCreationDateFormatted)) {
          result[itemCreationDateFormatted] = [currentItem]
        } else {
          result[itemCreationDateFormatted].push(currentItem)
        }
        return result
      }, {}),
    [pointsHistories]
  )

  return transformedPointsHistories
}

export default useTransformPointsHistory
