import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useUser } from 'domains/User/context'

/**
 * It returns an array of values that are used to render the pointsHistory
 * @returns An array of values.
 */
const useGetPointsHistories = () => {
  const { newspaperId } = useNewspaperConfig()
  const { user } = useUser()

  /* Getting collection data */
  const [value, loading, error] = useCollectionData(
    newspaperId &&
      user?._id &&
      firebase
        .firestore()
        .collection(COLLECTIONS.POINTS_HISTORIES)
        .where('userId', '==', user?._id)
        .where('newspaperId', '==', newspaperId)
        .orderBy('_createdAt', 'desc')
  )

  return [value, loading, error]
}

export default useGetPointsHistories
