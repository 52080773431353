import { useTranslations } from '@qonsoll/translation'
import { message } from 'antd'
import { collection, doc, serverTimestamp, setDoc } from 'firebase/firestore'
import { getRewardDataField } from 'domains/Reward/helpers'
import { useState } from 'react'
import { firestore } from 'services/firebase'
import { COLLECTIONS } from '__constants__'
import { useUser } from 'domains/User/context'

const useRedeemReward = () => {
  const [loading, setLoading] = useState(false)
  const { user, points, setPoints } = useUser()
  const { t } = useTranslations()

  const redeemReward = async (reward, inputData) => {
    try {
      setLoading(true)

      const {
        _id: rewardId,
        type: rewardType,
        newspaperId,
        storeId,
        points: redeemPoints,
        _status
      } = reward

      const [usedField, nullableField] = getRewardDataField(rewardType)

      const { inputValue = null } = inputData || {}

      if (Number(points) < Number(redeemPoints)) {
        message.error(t("You don't have enough points"))
        return
      }

      const _id = doc(collection(firestore, COLLECTIONS.REWARD_OVERVIEW)).id

      const data = {
        _id,
        rewardId,
        rewardType,
        newspaperId,
        storeId,
        redeemPoints,
        sno: null,
        _createdAt: serverTimestamp(),
        _updatedAt: null,
        status: _status,
        userId: user?._id,
        [usedField]: inputValue,
        [nullableField]: null
      }

      await setDoc(doc(firestore, COLLECTIONS.REWARD_OVERVIEW, _id), data)

      setPoints((prev) => Number(prev) - Number(redeemPoints))

      message.success(t('You successfully redeemed reward'))
    } catch (e) {
      message.error(
        `${t('Something went wrong during reward redeeming')}, ${t(
          'please try again later'
        )}`
      )
    } finally {
      setLoading(false)
    }
  }

  return { redeemReward, loading }
}

export default useRedeemReward
