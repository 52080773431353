import { Button, Row } from 'antd'
import { Box, Img } from '@qonsoll/react-design'
import styled from 'styled-components'

export const BackgroundBoxStyled = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;
  background-color: var(--ql-color-grey-9);
`

export const RewardImageStyled = styled(Img)`
  width: 200px;
  height: 200px;
  margin: 12px;
  border-radius: 24px;
`

export const CloseButtonStyled = styled(Button)`
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;

  // Set background without alfa
  &,
  &:hover,
  &:focus {
    background-color: #d9dbdc;
  }
`

export const EmojiRowStyled = styled(Row)`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const EmojiContainerStyled = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;

  animation: fly-up 20s linear infinite;

  @keyframes fly-up {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-50%);
    }
    100% {
      transform: translateY(-100%);
    }
  }
`
export const modalBodyStyles = {
  borderRadius: '20px',
  height: '80vh',
  width: '100%',
  margin: 0,
  overflow: 'hidden',
  padding: 0
}

export const ContentContainer = styled(Box)`
  position: absolute;
  width: 328px;
  height: 328px;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background-color: var(--btn-white-bg);
  border-radius: 24px;
  border: 1px solid var(--ql-color-grey);
`
