import { NewsTipSimpleView } from 'domains/NewsTip/components'
import { PropTypes } from 'prop-types'
import { SettingsCombinedList } from 'domains/Settings/components'

const NewsTipsList = (props) => {
  const { newsTips } = props

  return (
    <SettingsCombinedList
      responsiveColWidthArr={[12, 12, 6, 6, 4, 4]}
      hideAddCard
      items={newsTips}
      dataPropName="newsTip">
      <NewsTipSimpleView />
    </SettingsCombinedList>
  )
}

NewsTipsList.propTypes = {
  newsTips: PropTypes.array
}

export default NewsTipsList
