const { innerHeight: height } = window

const tabBarStyle = {
  overflowX: 'hidden',
  overflowY: 'hidden'
}

// Height: window height - 144, give tabs a minimum height at which they must stretch
// to the entire screen and to avoid appearing vertical scroll
// 144 and 264 - values of parts of the page height occupied by other elements/offsets on the page
// (depending on other content)
const styles = {
  tabBar: {
    minHeight: height - 264,
    ...tabBarStyle
  },
  tabBarAuth: {
    minHeight: height - 352,
    ...tabBarStyle
  }
}

export { styles }
