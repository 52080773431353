import { DEFAULT_LANGUAGE } from 'domains/Translation/__constants__'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useEffect } from 'react'
import { useTranslations } from 'contexts/Translation'
import moment from 'moment'
import { LS } from '__constants__'

const useGetInitialLanguage = () => {
  const { languages, setCurrentLanguage } = useTranslations()
  const { app = {} } = useNewspaperConfig()
  const newspaperDefaultLanguage = app?.defaultLanguage || null

  const language = newspaperDefaultLanguage?.value

  const handleChange = (value) => {
    setCurrentLanguage(value)
    moment.locale(value === DEFAULT_LANGUAGE ? 'nb' : value)
  }

  const getLanguageFromLocalStorage = () => {
    const languageFromLocalStorage = localStorage.getItem(LS.LANGUAGE)

    return languages?.find(({ value }) => value === languageFromLocalStorage)
  }

  const initDefaultLanguage = () => {
    const languageFromLocalStorage = getLanguageFromLocalStorage()

    if (!languageFromLocalStorage) {
      const defaultLanguage = languages?.find(
        ({ value }) => value === DEFAULT_LANGUAGE
      )

      handleChange?.(defaultLanguage?.shortCode)

      return
    }

    handleChange?.(languageFromLocalStorage?.shortCode)
  }

  useEffect(() => {
    if (newspaperDefaultLanguage === null) {
      initDefaultLanguage()
    }
    if (newspaperDefaultLanguage !== null) {
      const defaultLanguage = languages?.find(
        ({ value }) => value === newspaperDefaultLanguage?.value
      )

      //In case when default language in webTippsConfig was removed but in the same time was selected
      if (!defaultLanguage) return initDefaultLanguage()

      handleChange?.(defaultLanguage?.shortCode)
    }
  }, [languages, language])

  return { handleChange }
}

export default useGetInitialLanguage
