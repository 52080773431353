import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button } from '@qonsoll/react-design'
import { PropTypes } from 'prop-types'
import { useBackButtonVisibility } from './hooks'
import { useHistory } from 'react-router-dom'

const Back = (props) => {
  const { icon, ...rest } = props
  const history = useHistory()
  const isButtonVisible = useBackButtonVisibility()

  const goBack = () => history.goBack()
  const iconBack = icon || <ArrowLeftOutlined />
  return (
    <>
      {isButtonVisible ? (
        <Button
          type="text"
          {...rest}
          shape="circle"
          icon={iconBack}
          onClick={goBack}
        />
      ) : null}
    </>
  )
}

Back.propTypes = {
  icon: PropTypes.node
}

export default Back
