import PropTypes from 'prop-types'
import { TagsForm } from 'domains/Tags/components'

const TagsStep = (props) => {
  const { value, setValue, suggestionsLoading } = props

  return (
    <TagsForm
      value={value}
      setValue={setValue}
      suggestionsLoading={suggestionsLoading}
    />
  )
}

TagsStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  suggestionsLoading: PropTypes.bool
}

export default TagsStep
