import { Box, Col, Row } from '@qonsoll/react-design'

import { CONTENT } from 'domains/Content/constants'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { Tabs } from 'antd'
import { styles } from './ContentSimpleView.styles'
import { useGetContent } from 'domains/Content/hooks'
import { useTranslations } from '@qonsoll/translation'

const { TabPane } = Tabs

const ContentSimpleView = (props) => {
  const { isAuthenticated } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  const [contents, contentLoading] = useGetContent()

  return contentLoading ? (
    <Spinner text={t('Content loading')} />
  ) : (
    <Box mx={40} mb={4}>
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        tabBarStyle={isAuthenticated ? styles.tabBarAuth : styles.tabBar}>
        {contents.map(({ contentType, content }, index) => (
          <TabPane tab={CONTENT[contentType]} key={`content-${index}`}>
            <Row>
              <Col>
                <Box dangerouslySetInnerHTML={{ __html: content }} />
              </Col>
            </Row>
          </TabPane>
        ))}
      </Tabs>
    </Box>
  )
}

ContentSimpleView.propTypes = {
  isAuthenticated: PropTypes.bool
}

export default ContentSimpleView
