import {
  Box,
  Button,
  Img,
  Layout,
  LayoutSystemProvider,
  Text
} from '@qonsoll/react-design'

import IllustrationAccessDenied from 'assets/NotAllowedSite.svg'
import { ThemeProvider } from 'styled-components'
import breakpoints from 'styles/app/breakpoints'
import { useHistory } from 'react-router-dom'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useTranslations } from 'contexts/Translation'

const AccessDenied = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const layoutConfig = {}
  const { isUserHasAccess, logoUrl, domainName } = useNewspaperConfig()

  const goBack = () => history.goBack()

  return (
    <ThemeProvider theme={breakpoints}>
      <LayoutSystemProvider {...layoutConfig}>
        <Layout>
          {logoUrl ? (
            <Img
              src={logoUrl}
              alt="newspaper logo"
              height="80"
              position="absolute"
              top={16}
              left={24}
            />
          ) : null}
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems={['stretch', 'center']}
            justifyContent="space-around">
            <Img
              src={IllustrationAccessDenied}
              alt="Not Found"
              width={['100%', 400, 480]}
              mb={[3, 4]}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={[4, 32]}>
              <Text
                fontFamily="var(--ql-font-family-headings)"
                variant={['h3', 'h1']}
                textAlign="center"
                mb={[2, 3]}>
                {`${t('Oops')}... ${t(
                  'Looks like the web tipps is not allowed for'
                )} ${domainName ? domainName : ''} ${t('newspaper')}`}
              </Text>
              <Text textAlign="center">
                {t(`The page you're trying to access has restricted access`)}
              </Text>
            </Box>
            {isUserHasAccess && (
              <Button
                type="primary"
                size="large"
                block
                onClick={goBack}
                width="25%">
                {t('Go back')}
              </Button>
            )}
          </Box>
        </Layout>
      </LayoutSystemProvider>
    </ThemeProvider>
  )
}

export default AccessDenied
