import { Card } from 'antd'
import styled from 'styled-components'

export const CommentsCard = styled(Card)`
  height: 100%;

  .ant-card-head-title {
    padding: 0;
  }
`

export const styles = {
  commentsCardHeadStyles: {
    borderBottom: 0,
    marginBottom: 8,
    paddingTop: 24
  },
  commentsCardBodyStyles: {
    paddingTop: 0,
    overflowY: 'auto',
    maxHeight: '412px'
  },
  loadingStyles: {
    width: '100%',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}
