import {
  Box,
  Button,
  Col,
  Container,
  Img,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'
import {
  StyledBox,
  StyledCard,
  StyledUserInfoBox,
  styles
} from './UserAdvancedView.styled'
import {
  useGetLinkedProviders,
  useGetUserNewspaper,
  useLinkFacebookProvider,
  useRemoveUser
} from 'domains/User/hooks'

import { AvatarWithLetter } from 'components'
import Euro from '../../../../assets/euro.png'
import { Popconfirm } from 'antd'
import { Spinner, FacebookButton } from 'components'
import { createFullUserName } from 'helpers'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { usePhoneNumber, useLoading } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'
import { useMemo } from 'react'
import { SIGN_IN_METHODS } from '__constants__'

const UserAdvancedView = () => {
  const { user, loading } = useUser()
  const { t } = useTranslations()
  const { handleRemoveUser, removeUserLoading } = useRemoveUser()
  const { newspaperId } = useNewspaperConfig()
  const { linkedProviders, providersLoading, getLinkedProviders } =
    useGetLinkedProviders()
  const {
    linkFacebookProvider,
    unlinkFacebookProvider,
    loading: facebookLoading
  } = useLinkFacebookProvider(getLinkedProviders)

  const userFullName = createFullUserName(user?.firstName, user?.lastName)

  const { transformPhoneNumber } = usePhoneNumber()
  const userPhoneNumber = transformPhoneNumber(user?.phoneNumber)

  const [newspaper, newspaperLoading] = useGetUserNewspaper({
    newspaperAssociated: newspaperId
  })

  const userAssociatedNewsPaper = newspaper?.name || 'N/A'
  const userPoints = user?.pointsGiven?.[newspaperId] || 0
  const isFacebookProviderLinked = useMemo(
    () => linkedProviders?.includes(SIGN_IN_METHODS.FACEBOOK),
    [linkedProviders]
  )

  const combinedLoading = useLoading([
    loading,
    newspaperLoading,
    providersLoading
  ])

  return combinedLoading ? (
    <Spinner height="70vh" text={t('Profile is  loading')} />
  ) : (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)" justifyContent="center">
        <Col cw="auto">
          <StyledCard width={['300px', '350px', '350px', '350px', '350px']}>
            <AvatarWithLetter size={[60, 80, 100, 120]} user={user} />
            <StyledBox>
              <StyledUserInfoBox>
                <Text type="secondary">{t('Name')}:</Text>
                <Title style={{ wordBreak: 'break-all' }} level={5} mb={2}>
                  {userFullName}
                </Title>

                <Text type="secondary">{t('Mobile number')}:</Text>
                <Title level={5} mb={2} style={{ wordBreak: 'break-all' }}>
                  {userPhoneNumber}
                </Title>

                <Text type="secondary">{t('Email')}:</Text>

                <Title wordBreak="break-all" level={5}>
                  {user?.email}
                </Title>
              </StyledUserInfoBox>
              <Box display="flex" p={16} flexDirection="column">
                <Box display="flex" alignItems="center" mb={3}>
                  <Img
                    src={newspaper?.logoUrl}
                    {...styles.newsPaperLogoStyles}
                  />
                  <Box display="flex" flexDirection="column">
                    <Text type="secondary">{t('Newspaper')}:</Text>

                    <Text {...styles.textStyles}>
                      <Text wordBreak="break-all">
                        {userAssociatedNewsPaper}
                      </Text>
                    </Text>
                  </Box>
                </Box>
                <Box display="flex" alignItems="center">
                  <Img src={Euro} {...styles.coinStyles} />
                  <Box display="flex" flexDirection="column">
                    <Text type="secondary">{t('Points')}:</Text>

                    <Text {...styles.textStyles}>{userPoints}</Text>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt="4px">
                <FacebookButton
                  text={t(
                    isFacebookProviderLinked
                      ? 'Unlink Facebook'
                      : 'Link Facebook'
                  )}
                  size="medium"
                  loading={facebookLoading}
                  onClick={
                    isFacebookProviderLinked
                      ? unlinkFacebookProvider
                      : linkFacebookProvider
                  }
                  width="174px"
                  mb="6px"
                />

                <Popconfirm
                  okType="danger"
                  title={`${t('Are you sure to delete account')}?`}
                  onConfirm={handleRemoveUser}
                  okText={t('Yes')}
                  cancelText={t('No')}>
                  <Button
                    width="174px"
                    type="danger"
                    loading={removeUserLoading}>
                    {t('Delete account')}
                  </Button>
                </Popconfirm>
              </Box>
            </StyledBox>
          </StyledCard>
        </Col>
      </Row>
    </Container>
  )
}

export default UserAdvancedView
