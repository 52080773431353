import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { Spinner } from 'components'
import { UserAdvancedForm } from 'domains/User/components'
import { useProfileBaseActions } from 'domains/User/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const UserEdit = () => {
  const { onBack } = useProfileBaseActions()
  const { t } = useTranslations()
  const { user } = useUser()

  const headingProps = {
    title: t('Edit profile'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper onBack={onBack} headingProps={headingProps}>
      <Row noGutters justifyContent="center">
        {!user ? (
          <Col>
            <Spinner height="70vh" text={t('Profile is  loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <UserAdvancedForm initialData={{ user }} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default UserEdit
