import { Text, Title, Row, Col, Card } from '@qonsoll/react-design'
import { useRewardBaseActions } from 'domains/Reward/hooks'
import { CardDropdown } from 'components'
import PropTypes from 'prop-types'
import { useGetStoreInitialValues } from 'domains/Store/hooks'
import { ImgStyled } from './RewardSimpleView.styles'
import { useTranslations } from 'contexts/Translation'
import noImage from 'assets/NoImageSmall.png'

const RewardSimpleView = (props) => {
  const { reward, index, actions } = props

  const { t } = useTranslations()

  const [store] = useGetStoreInitialValues(reward?.storeId)

  /* Getting base actions */
  const { onOpen } = useRewardBaseActions({
    rewardId: reward?._id,
    actions
  })

  const computedImage = reward?.imageUrl || noImage

  return (
    <CardDropdown
      onClick={onOpen}
      document={reward}
      index={index}
      data-testid="rewardListItem"
      {...actions}>
      <Card>
        <Row noGutters>
          <Col cw={12} mb={2}>
            <ImgStyled src={computedImage} />
          </Col>
          <Col cw={12}>
            <Text type="secondary" isEllipsis>
              {store?.name}
            </Text>
          </Col>
          <Col cw={12} mb={3}>
            <Text isEllipsis>{reward?.name}</Text>
          </Col>
          <Col cw={12}>
            <Row noGutters>
              <Col cw="auto" mr={1}>
                <Title level={5} isEllipsis>
                  {reward?.points}
                </Title>
              </Col>
              <Col cw="auto">
                <Text type="secondary">{t('points')}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </CardDropdown>
  )
}

RewardSimpleView.propTypes = {
  reward: PropTypes.object,
  index: PropTypes.number,
  actions: PropTypes.object
}

export default RewardSimpleView
