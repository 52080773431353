import { model, attr, hasOne } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  addedOn: yup
    .string()
    .typeError('Field addedOn has wrong type')
    .default(null)
    .nullable(),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .default(null)
    .nullable(),
  newspaper: yup
    .string()
    .typeError('Field newspaper has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable()
})

const RejectReason = model(
  'rejectReason',
  {
    sno: attr('number'),
    title: attr('string'),
    addedOn: attr('string'),
    email: attr('string'),
    newspaper: attr('string'),
    status: hasOne('rejectReasonStatus')
  },
  validationSchema
)

export default RejectReason
