import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

const useGetStoreInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [store, storeLoading] = useDocument({
    ref: id ? COLLECTIONS.STORES + '/' + id : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(() => store, [store])

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!store, storeLoading] : []),
    [id, store, storeLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetStoreInitialValues
