const UPLOADING_STATUSES = {
  RUNNING: 'running',
  UPLOADING: 'uploading',
  REMOVED: 'removed',
  DONE: 'done',
  ERROR: 'error'
}

export { UPLOADING_STATUSES }
export default UPLOADING_STATUSES
