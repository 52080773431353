import { Container, Layout, LayoutSystemProvider } from '@qonsoll/react-design'
import { Footer, LayoutHeader } from './components'

import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import breakpoints from 'styles/app/breakpoints'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useConfirmation } from 'domains/Confirmation/context'
import { useMemo } from 'react'

// import styled from 'styled-components'

const BoilerplateLayout = ({ children }) => {
  const [authUser, loading] = useAuthState(firebase.auth())
  const { isUserFinishedConfirmation } = useConfirmation()

  /* If the user is authenticated, the component will render the children. Otherwise, it will render
  the fallback component. */
  const isAuthenticated = useMemo(
    () => authUser?.email && isUserFinishedConfirmation && !loading,
    [authUser?.email, loading, isUserFinishedConfirmation]
  )

  /* The layoutConfig is memoized to only re-render when isAuthenticated changes. */
  const layoutConfig = {
    isAsideLeft: isAuthenticated,
    asideLeftCollapseVariant: 'full',
    asideLeftOuter: false,
    isHeaderSticky: true
  }

  // [TODO] To remove commented code below on 11.03.2024
  // this is a temporary solution which partly covers app theme color changing functionality.
  // const LayoutWrapperStyled = styled.div`
  //   h3.ant-typography {
  //     /* Check if 'color' prop is passed and update the variable */
  //     ${({ color }) =>
  //       color &&
  //       `
  // 				color: ${color};
  // 			`}
  //   }
  // `

  return (
    <ThemeProvider theme={breakpoints}>
      {/* <LayoutWrapperStyled color={color}> */}
      {isAuthenticated ? (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            header={
              <LayoutHeader
                isAuthenticated={isAuthenticated}
                authUser={authUser}
              />
            }
            asideLeft={<></>}
            footer={<Footer />}>
            <Container bounded>{children}</Container>
          </Layout>
        </LayoutSystemProvider>
      ) : (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            header={<LayoutHeader isAuthenticated={isAuthenticated} />}
            footer={<Footer />}>
            {children}
          </Layout>
        </LayoutSystemProvider>
      )}
      {/* </LayoutWrapperStyled> */}
    </ThemeProvider>
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
