import { TimeText, Wrapper } from './Stopwatch.styled'
import { useStopwatchTime } from './hooks'
import PropTypes from 'prop-types'

const Stopwatch = (props) => {
  const { isModalVisible } = props

  const time = useStopwatchTime(isModalVisible)

  return (
    <Wrapper>
      <TimeText>{time}</TimeText>
    </Wrapper>
  )
}

Stopwatch.propTypes = {
  isModalVisible: PropTypes.bool
}

export default Stopwatch
