import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetDocumentById = (collectionName, documentId) => {
  // memo is used to prevent the hook from re-running when the documentId changes
  const query = useMemo(
    () => documentId && doc(firestore, collectionName, documentId),
    [collectionName, documentId]
  )

  const [documentData, loading, error] = useDocumentData(query)

  return [documentData, loading, error]
}

export default useGetDocumentById
