import { CONTENT_TYPES } from '__constants__'
import { Content } from 'components'
import { PageWrapper } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'

const TermsOfConditions = () => {
  const { t } = useTranslations()

  const headingProps = {
    title: t('Terms of conditions'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper headingProps={headingProps}>
      <Content contentType={CONTENT_TYPES.TERMS_OF_SERVICE} />
    </PageWrapper>
  )
}

export default TermsOfConditions
