import { ref } from 'firebase/storage'
import { storage } from 'services/firebase'

const getUploaderRef = (file, path, customFileName) => {
  const filePath = customFileName || file.uid + '_' + file.name

  return ref(storage, `${path}/${filePath}`)
}

export default getUploaderRef
