import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { STORAGE_PATHS } from '__constants__'
import { UploadMedia } from 'components'
import { useTranslations } from 'contexts/Translation'
import { useDebounceUpdateTips } from 'domains/NewsTip/hooks'
import { STEP_NAMES } from '../constants'
import { useNewspaperConfig } from 'contexts'

const MediaContentStep = (props) => {
  const { value, setValue, setUploadLoading, setStep, isGuestMode } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const { newspaperId } = useNewspaperConfig()

  // Getting  the debounce call
  const { debouncedSendRequest } = useDebounceUpdateTips()

  /* Handling changes */
  const handleChangeValue = (media) => {
    setValue((prev) => ({ ...prev, media }))

    const photoUrls = media?.files
      ?.map((item) => (item?.type?.includes('image') ? item?.url : false))
      ?.filter(Boolean)

    const videoUrl = media?.files?.find((item) =>
      item?.type?.includes('video')
    )?.url

    debouncedSendRequest({
      data: {
        ...value,
        photoUrls,
        videoUrl,
        newspaperId
      },
      step: STEP_NAMES.MEDIA,
      newsTipId: value?._id
    })
  }

  return (
    <Container mb={4}>
      <Row>
        <Col cw={12}>
          <Title textAlign="center" variant="h4" mb={1}>
            {t('Add media content')}
          </Title>
          <Text mb={3} textAlign="center">
            {`${t(
              'Maximum 3 photos and 1 video Only JPG, JPEG 1 and PNG file formats are accepted'
            )}. ${t(
              'Photos sent to the newspaper can be used for other matters later without payment'
            )}
            .`}
          </Text>
        </Col>
      </Row>

      <Row noGutters h="center" mb={2}>
        <Col h="center">
          <UploadMedia
            maxCount={4}
            listType="picture"
            width="auto"
            readOnly
            value={value?.media}
            tipId={value?._id}
            onChange={handleChangeValue}
            setStep={setStep}
            setUploadLoading={setUploadLoading}
            storagePath={STORAGE_PATHS.TIPS}
            isGuestMode={isGuestMode}
          />
        </Col>
      </Row>
    </Container>
  )
}

MediaContentStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  setUploadLoading: PropTypes.func,
  setStep: PropTypes.func,
  isGuestMode: PropTypes.bool
}

export default MediaContentStep
