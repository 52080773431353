import { useEffect, useState } from 'react'

import { REWARD_TYPES } from '__constants__'
import { useTranslations } from '@qonsoll/translation'

const useGetModalContent = (rewardType, email, isModalVisible) => {
  const [inputValue, setInputValue] = useState('')

  const { t } = useTranslations()

  useEffect(() => {
    rewardType === REWARD_TYPES.EMAIL && setInputValue(email)
  }, [email, rewardType, isModalVisible])

  const confirmationText =
    rewardType === REWARD_TYPES.EMAIL
      ? `${t('Please enter your email')}. ${t(
          'The reward will be sent to this email'
        )}.`
      : `${t('Please enter your postal address')}. ${t(
          'The reward will be sent directly to this adress'
        )}.`

  const inputPlaceholder =
    rewardType === REWARD_TYPES.EMAIL ? 'email' : 'postal address'

  return [inputValue, setInputValue, confirmationText, inputPlaceholder]
}

export default useGetModalContent
