import {
  useCompletedUploading,
  useErrorUploading,
  useGetProcessUploading
} from 'hooks'

import { getUploaderRef } from 'helpers'
import { uploadBytesResumable } from 'firebase/storage'
import { useCallback } from 'react'
import { useUser } from 'domains/User/context'

const useOnUploadMedia = ({
  setLoadings,
  setFiles,
  onChange,
  storagePath,
  tipId,
  isGuestMode
}) => {
  const { user } = useUser()
  const { _id = isGuestMode ? 'guest_mode' : null } = user || {}

  const processUploading = useGetProcessUploading({ setFiles })
  /* A callback function that is called when the upload fails. */
  const errorUploading = useErrorUploading({
    onChange
  })
  /* A callback function that is called when the upload is successful. */
  const completedUploading = useCompletedUploading({
    onChange,
    setFiles,
    setLoadings
  })

  const onUpload = useCallback(
    (data) => {
      const { file, onSuccess } = data

      const isVideo = file?.type?.includes('video')

      const typeUrl = !isVideo ? `photo` : 'video'

      const customName = `${file?.uid}_${tipId}_${_id}_${typeUrl}`

      const metadata = {
        customMetadata: {
          hasPublicAccess: isGuestMode,
          _createdBy: _id
        }
      }

      const storageRef = getUploaderRef(file, storagePath, customName)
      const uploadMedia = uploadBytesResumable(storageRef, file, metadata)

      uploadMedia?.on(
        'state_changed',
        /* Updating the state of the file. */
        (snapshot) => processUploading(snapshot, file),
        /* A callback function that is called when the upload fails. */
        () => errorUploading(file),
        /* A callback function that is called when the upload is successful. */
        () => completedUploading(uploadMedia, file, onSuccess)
      )
    },
    [
      completedUploading,
      errorUploading,
      processUploading,
      storagePath,
      tipId,
      _id
    ]
  )

  return { onUpload }
}

export default useOnUploadMedia
