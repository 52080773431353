import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { useGetStoreInitialValues } from 'domains/Store/hooks'

const useGetRewardInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [reward, rewardLoading] = useDocument({
    ref: id ? COLLECTIONS.REWARDS + '/' + id : null
  })

  /* Fetching data using useDocument hook */
  const [store, storeLoading] = useGetStoreInitialValues(reward?.storeId)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      reward,
      store
    }),
    [reward, store]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!reward, rewardLoading, storeLoading] : []),
    [id, reward, rewardLoading, storeLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetRewardInitialValues
