import { transformCollectionName, transformName } from '../helpers'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import PATHS from 'pages/paths'
import { getDocument } from 'services/api/firebase'
import pluralize from 'pluralize'
import { useTranslations } from 'contexts/Translation'

const { AUTHENTICATED } = PATHS
const breadcrumbNames = Object.fromEntries(
  Object.entries(AUTHENTICATED).map((a) => a.reverse())
)

const useExtraBreadcrumbsItems = () => {
  const [extraItems, setExtraItems] = useState(null)

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting locations instance */
  const location = useLocation()
  /* Getting params instance */
  const params = useParams()

  useEffect(() => {
    const extractItems = async () => {
      const pathSnippets = location.pathname.split('/').filter((i) => i)
      const [collectionName] = pathSnippets
      const modelName = pluralize.singular(
        collectionName.includes('-')
          ? transformCollectionName(collectionName)
          : collectionName
      )

      const documentId = params?.[`${modelName}Id`]
      if (documentId) pathSnippets[1] = `:${modelName}Id`

      const promises = pathSnippets.map(async (snippet, index) => {
        const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
        const isId = snippet.includes('Id')

        const transformedUrl = breadcrumbNames[url] ? url : pluralize(url)
        const transformedName = transformName(breadcrumbNames[transformedUrl])
        let name = t(transformedName)

        if (isId) {
          const document = await getDocument(collectionName, documentId)
          const documentName =
            document?.name || document?.title || transformedName
          name = documentId ? documentName : t(transformedName)
        }

        return {
          name,
          key: transformedUrl,
          path: transformedUrl.replace(`:${modelName}Id`, documentId)
        }
      })

      const results = await Promise.all(promises).then((values) => values)
      setExtraItems(results || [])
    }

    extractItems()
  }, [location.pathname, params, t])

  return [extraItems]
}

export default useExtraBreadcrumbsItems
