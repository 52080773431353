import { Avatar, Box, Card, Col, Row, Text, Title } from '@qonsoll/react-design'
import { Dropdown, Menu } from 'antd'

import { MOMENT_FORMATS } from '__constants__'
import { MoreOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import moment from 'moment'
import { tipsCommentCardBodyStyles } from './NewsTipCommentsList.styles'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const NewsTipCommentsList = (props) => {
  const { tipComments, handleEditComment, handleDeleteComment } = props

  const { user } = useUser()
  const { t } = useTranslations()

  const commentActionsMenu = (commentId, commentText) => (
    <Menu>
      <Menu.Item
        key="edit"
        onClick={() => handleEditComment(commentId, commentText)}>
        {t('Edit')}
      </Menu.Item>
      <Menu.Item key="delete" onClick={() => handleDeleteComment(commentId)}>
        {t('Delete')}
      </Menu.Item>
    </Menu>
  )

  return (
    <Row noGutters>
      {tipComments?.map((comment, index) => (
        <Col cw={12} mt={index ? 3 : 0} key={comment._id}>
          <Box display="flex" flexDirection="row">
            <Avatar src={user?.avatarUrl} mr={1} />
            <Card
              bodyStyle={tipsCommentCardBodyStyles}
              background="var(--ql-color-grey-9)">
              <Row noGutters>
                <Col v="center" mb={2} mr={2}>
                  <Text wordBreak="break-all">{comment?.text}</Text>
                </Col>
                <Col cw="auto" mb={2}>
                  <Dropdown
                    arrow
                    trigger="click"
                    overlay={() =>
                      commentActionsMenu(comment?._id, comment?.text)
                    }>
                    <Title variant="h4" cursor="pointer">
                      <MoreOutlined />
                    </Title>
                  </Dropdown>
                </Col>
                <Col h="right" cw={12}>
                  <Text type="secondary" variant="caption1">
                    {moment(comment?._createdAt?.toDate()).format(
                      MOMENT_FORMATS.DATE_FORMATS
                    )}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Box>
        </Col>
      ))}
    </Row>
  )
}

NewsTipCommentsList.propTypes = {
  tipComments: PropTypes.array,
  handleEditComment: PropTypes.func,
  handleDeleteComment: PropTypes.func
}
export default NewsTipCommentsList
