import { model, attr, hasOne } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  points: yup
    .string()
    .typeError('Field points has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  newspaper: yup
    .string()
    .typeError('Field newspaper has wrong type')
    .default(null)
    .nullable()
})

const NewsTip = model(
  'newsTip',
  {
    sno: attr('number'),
    title: attr('string'),
    points: attr('string'),
    status: hasOne('newsTipStatus'),
    newspaper: hasOne('newspaper')
  },
  validationSchema
)

export default NewsTip
