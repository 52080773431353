import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'
import {
  REWARDS_WITH_CONFIRMATION_MODAL,
  REWARD_OVERVIEW_STATUSES,
  REWARD_TYPES
} from '__constants__'
import {
  RewardRedeemConfirmationModal,
  RewardStoreModal
} from 'domains/Reward/components'
import { useMemo, useState } from 'react'

import { ImgStyled } from './RewardAdvancedView.styles'
import PropTypes from 'prop-types'
import { message } from 'antd'
import { useRedeemReward } from 'domains/Reward/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const RewardAdvancedView = (props) => {
  const { reward, store } = props

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)
  const [isStoreModalVisible, setIsStoreModalVisible] = useState(false)
  const { redeemReward, loading } = useRedeemReward()

  const { t } = useTranslations()
  const { points: userPoints } = useUser()

  const isRewardButtonDisabled = useMemo(
    () => !userPoints || reward?.points > userPoints,
    [reward?.points, userPoints]
  )

  const onRedeemButtonClick = async () => {
    try {
      if (REWARDS_WITH_CONFIRMATION_MODAL.includes(reward?.type)) {
        setIsConfirmationModalVisible(true)
      } else if (
        reward?.type === REWARD_TYPES.DONATION ||
        reward?.type === REWARD_TYPES.STORE
      ) {
        await redeemReward(
          { ...reward, _status: REWARD_OVERVIEW_STATUSES.PENDING },
          { userPoints }
        )

        reward?.type === REWARD_TYPES.STORE && setIsStoreModalVisible(true)
      }
    } catch (e) {
      message.error(t('Something went wrong during reward redeeming'))
    }
  }

  return (
    <Card>
      <Container>
        <Row noGutters>
          <Col cw={12} mb={3}>
            <ImgStyled src={reward?.imageUrl} />
          </Col>
          <Col cw={6} mb={2}>
            <Text type="secondary">{t('Store')}:</Text>
          </Col>
          <Col cw={6} h="right">
            <Text isEllipsis>{store?.name}</Text>
          </Col>
          <Col cw={6} mb={3}>
            <Text type="secondary">{t('Price')}:</Text>
          </Col>
          <Col cw={6} h="right">
            <Text>{reward?.points}</Text>
          </Col>
          <Col cw={12}>
            <Title level={5}>{t('Terms and Conditions to Apply')}</Title>
          </Col>
          <Col cw={12} mb={2}>
            <Text>{reward?.description}</Text>
          </Col>
          <Col cw={12}>
            <Title level={5}>{t('Instructions For Use')}</Title>
          </Col>
          <Col cw={12} mb={4}>
            <Text>{reward?.direction}</Text>
          </Col>
          <Col cw={12}>
            <Button
              loading={loading}
              onClick={onRedeemButtonClick}
              disabled={isRewardButtonDisabled}
              type="primary">
              {t('Redeem')}
            </Button>
          </Col>
        </Row>
        <RewardRedeemConfirmationModal
          {...props}
          userPoints={userPoints}
          isModalVisible={isConfirmationModalVisible}
          setIsModalVisible={setIsConfirmationModalVisible}
        />
        {isStoreModalVisible && (
          <RewardStoreModal
            {...props}
            isModalVisible={isStoreModalVisible}
            setIsModalVisible={setIsStoreModalVisible}
            userPoints={userPoints}
          />
        )}
      </Container>
    </Card>
  )
}

RewardAdvancedView.propTypes = {
  reward: PropTypes.object,
  store: PropTypes.object
}

export default RewardAdvancedView
