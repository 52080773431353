import {
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber
} from 'firebase/auth'

import PATHS from 'pages/paths'
import { message } from 'antd'
import { useConfirmation } from 'domains/Confirmation/context'
import { useHistory } from 'react-router-dom'
import { usePhoneNumber } from 'hooks'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'

const useConfirmPhone = () => {
  const [loading, setLoading] = useState(false)
  const { changeConfirmationObject } = useConfirmation()
  const history = useHistory()

  const { t } = useTranslations()
  const { transformPhoneNumber } = usePhoneNumber()

  const confirmPhone = async (credentials) => {
    try {
      setLoading(true)

      const phoneFormatted = transformPhoneNumber(credentials?.phone)
      const auth = getAuth()
      const appVerifier = new RecaptchaVerifier(
        'sign-in-button',
        {
          size: 'invisible'
        },
        auth
      )

      const confirmationObject = await signInWithPhoneNumber(
        auth,
        phoneFormatted,
        appVerifier
      )

      changeConfirmationObject(confirmationObject)

      history.push(PATHS.UNAUTHENTICATED.CONFIRM_PHONE, {
        ...credentials,
        phone: phoneFormatted
      })
    } catch (e) {
      message.error(t('Something went wrong during registration'))
    } finally {
      setLoading(false)
    }
  }

  return { confirmPhone, loading }
}

export default useConfirmPhone
