import { Box, Col, Container, Icon, Row, Text } from '@qonsoll/react-design'

import { Spinner } from 'components'
import { WarningOutlined } from '@ant-design/icons'
import { useGetExpiringPointsData } from 'domains/ExpiredPoints/hooks'
import { useTranslations } from 'contexts/Translation'

const ExpiredPointsSimpleView = () => {
  const { t } = useTranslations()

  const { dateOfExpirationPoints, removedPoints, loading } =
    useGetExpiringPointsData()

  return (
    <Container>
      <Row noGutters>
        <Col cw={12} mb={2} h="right">
          {loading ? (
            <Spinner height="3vh" />
          ) : (
            !!dateOfExpirationPoints &&
            !!removedPoints && (
              <Box display="flex" alignItems="center">
                <Icon
                  component={<WarningOutlined />}
                  mr={2}
                  color="var(--ql-color-warning)"
                />

                <Text
                  level={5}
                  style={{
                    textDecoration: 'underline',
                    textUnderlineOffset: '4px'
                  }}>
                  {`${t('Your oldest')} ${removedPoints} ${t(
                    'points expire on'
                  )}
                  ${dateOfExpirationPoints}`}
                </Text>
              </Box>
            )
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default ExpiredPointsSimpleView
