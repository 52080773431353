import { LS } from '__constants__'
import { useEffect } from 'react'
import { useLastSession } from 'domains/Session/hooks'

const usePutSessionFormInitialEmail = ({ form, isLogin }) => {
  const lastSession = useLastSession()

  useEffect(() => {
    // Try to get email that was written in login form,
    // it can differ from email from lastSession
    const loginAttemptEmail = localStorage.getItem(LS.LOGIN_ATTEMPT_EMAIL)

    // If user used Forgot Password button without passing any other correct emails
    // use last session email by default
    const computedInitialEmail = loginAttemptEmail || lastSession?.email

    // On Login page form item field that contain email named 'login'
    // On Forgot Password page such form item named 'email'
    // So compute which key to use according to screen where this hook was called
    const computedFormItemEmailKey = isLogin ? 'login' : 'email'

    if (computedInitialEmail) {
      form.setFieldsValue({ [computedFormItemEmailKey]: computedInitialEmail })
    }
  }, [form, lastSession?.email])
}

export default usePutSessionFormInitialEmail
