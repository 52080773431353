import { useLastSession, useSessionActions } from 'domains/Session/hooks'

import { AuthMethods } from 'domains/Session/components'
import PATHS from 'pages/paths'
import { PageWrapper } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useTranslations } from 'contexts/Translation'

const Login = (props) => {
  const { isGuestMode = false, guestModeActions = null } = props

  const { t } = useTranslations()

  const history = useHistory()
  const lastSession = useLastSession()
  const { googleLogin } = useSessionActions()
  const { login } = useNewspaperConfig()

  const redirectToLoginWithEmail = () => {
    lastSession && lastSession.email
      ? history.push(`${PATHS.UNAUTHENTICATED.LOGIN_WITH_EMAIL}?email`)
      : history.push(PATHS.UNAUTHENTICATED.LOGIN_WITH_EMAIL)
  }

  const lastSessionTitleTextComputed = lastSession
    ? t('Welcome back') + ' 👋'
    : t('Nice to see you') + ' 💪'

  const pageTitle = isGuestMode
    ? t('Sign up to continue')
    : login?.title || lastSessionTitleTextComputed

  const lastSessionDescriptionTextComputed = lastSession
    ? t(
        'alreadyKnowEachOther',
        'It seems that we already know each other. Choose any of the available authorization methods.'
      )
    : t('chooseMethodBelow', 'Choose any of the available methods below.')
  const pageDescription = isGuestMode
    ? t('Please authorize yourself to finish your tip submission')
    : login?.description || lastSessionDescriptionTextComputed

  return (
    <PageWrapper
      alignMiddle
      height="100%"
      headingProps={{
        title: pageTitle,
        subTitle: pageDescription,
        marginBottom: 40
      }}
      contentWidth={['100%', '100%', 400]}>
      <AuthMethods
        isGuestMode={isGuestMode}
        authTypeText="Login"
        googleAuth={googleLogin}
        redirectToFormWithEmail={redirectToLoginWithEmail}
        authSwitchText={t("Don't have an account") + '?'}
        authSwitchLinkText={t('Sign up')}
        authSwitchPath={PATHS.UNAUTHENTICATED.SIGNUP_WITH_EMAIL}
        lastSession={lastSession}
        guestModeActions={guestModeActions}
      />
    </PageWrapper>
  )
}

Login.propTypes = {
  isGuestMode: PropTypes.bool,
  guestModeActions: PropTypes.shape({
    showPhoneConfirmationForm: PropTypes.func,
    showLoginWithEmailForm: PropTypes.func,
    showSignUpForm: PropTypes.func
  })
}

export default Login
