import PATHS from 'pages/paths'
const { AUTHENTICATED } = PATHS

const PATHNAME_TO_SELECTED_MENU_ITEM = {
  [AUTHENTICATED.DASHBOARD]: AUTHENTICATED.DASHBOARD,

  [AUTHENTICATED.SETTINGS]: AUTHENTICATED.SETTINGS,

  [AUTHENTICATED.REWARDS_ALL]: AUTHENTICATED.REWARDS_ALL,
  [AUTHENTICATED.REWARD_SHOW]: AUTHENTICATED.REWARDS_ALL,

  [AUTHENTICATED.POINTS_HISTORIES_ALL]: AUTHENTICATED.POINTS_HISTORIES_ALL,

  [AUTHENTICATED.NEWS_TIP_WIZARD]: AUTHENTICATED.NEWS_TIP_WIZARD
}

export { PATHNAME_TO_SELECTED_MENU_ITEM }
