import {
  AppstoreOutlined,
  EditOutlined,
  PlusOutlined,
  SortAscendingOutlined,
  UnorderedListOutlined
} from '@ant-design/icons'
import { Box, Divider } from '@qonsoll/react-design'
import { Button, Popover } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { useTranslations } from 'contexts/Translation'

/**
 * It renders a set of buttons that are used to perform actions on a list of items
 * @param filterButtonText - The text that will be displayed when the user hovers over the filter
 * button
 * @param onFilterButtonClick - function that will be called when the filter button is clicked
 * @param filterButtonIcon - Icon to be displayed on the filter button
 * @param listView - boolean
 * @param setListView - a function that sets the listView state
 * @param onCreate - function to be called when the create button is clicked
 * @param onWizard - function to be called when the wizard button is clicked
 * @param onEdit - Function to be called when the edit button is clicked
 * @param actions - an object with the following properties:
 * @param createText - The text to be displayed on the create button
 * @returns A function that returns a JSX element
 */
const PageActions = (props) => {
  const {
    filterButtonText,
    onFilterButtonClick,
    filterButtonIcon,
    listView,
    setListView,
    onCreate,
    onWizard,
    onEdit,
    actions,
    createText,
    sortMenu
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Box display="flex">
      {actions.edit && (
        <Button icon={<EditOutlined />} onClick={onEdit}>
          {t('Edit')}
        </Button>
      )}
      {actions.filter && (
        <Tooltip title={filterButtonText}>
          <Button
            shape="circle"
            type="ghost"
            mr={2}
            onClick={onFilterButtonClick}
            icon={filterButtonIcon}
          />
        </Tooltip>
      )}
      {actions.sort && (
        <Tooltip title={t('Open sort menu')}>
          <Popover content={sortMenu} trigger="click" placement="bottomRight">
            <Button
              shape="circle"
              type="ghost"
              mr={2}
              icon={<SortAscendingOutlined />}
            />
          </Popover>
        </Tooltip>
      )}
      {actions.listView && (
        <Tooltip title={t('Change list view')} placement="topRight">
          <Button
            shape="circle"
            type="ghost"
            onClick={() => setListView(!listView)}
            icon={listView ? <AppstoreOutlined /> : <UnorderedListOutlined />}
          />
        </Tooltip>
      )}
      {actions.wizard && (
        <>
          <Divider mx={3} height="24px" alignSelf="center" type="vertical" />
          <Button type="primary" icon={<PlusOutlined />} onClick={onWizard}>
            {createText}
          </Button>
        </>
      )}
      {actions.create && (
        <>
          <Divider mx={3} height="24px" alignSelf="center" type="vertical" />
          <Button type="primary" icon={<PlusOutlined />} onClick={onCreate}>
            {createText}
          </Button>
        </>
      )}
    </Box>
  )
}

PageActions.propTypes = {
  actions: PropTypes.shape({
    create: PropTypes.bool,
    edit: PropTypes.bool,
    filter: PropTypes.bool,
    listView: PropTypes.bool,
    sort: PropTypes.bool,
    wizard: PropTypes.bool
  }),
  sortMenu: PropTypes.node,
  createText: PropTypes.string,
  filterButtonIcon: PropTypes.any,
  filterButtonText: PropTypes.string,
  listView: PropTypes.bool,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  onWizard: PropTypes.func,
  setListView: PropTypes.func
}

export default PageActions
