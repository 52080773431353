import { checkIfPointsSpent } from 'domains/ExpiredPoints/helpers'

const computeExpireData = ({ newsTipsByApprovedData, pointsHistory }) => {
  let removedPoints = null
  let approvedData = null
  let computedNewsTips = null

  /* Calculate sum points history with type spent by operation points. */
  let spentPointsSum = pointsHistory
    ?.map(({ operationPoints }) => operationPoints)
    ?.reduce((prev, curr) => prev + curr, 0)

  if (!newsTipsByApprovedData) return

  /* Transform news tips object by approved date, to array [key, value] */
  const transformedNewsTipsData = Object?.entries(newsTipsByApprovedData)

  for (const newsTipsData of transformedNewsTipsData) {
    if (!computedNewsTips?.length) {
      const [, newsTips] = newsTipsData

      /* Calculate sum points newsTips, approved on the same day. */
      const newsTipPointsSum = newsTips
        ?.map(({ points }) => points)
        ?.reduce((prev, curr) => prev + curr, 0)

      const { pointsToRemove, spentPoints } = checkIfPointsSpent({
        newsTipPointsSum,
        spentPointsSum,
        pointsHistory
      })

      spentPointsSum -= spentPoints || 0

      /* If have are points which not spent,
      then return information about the date of these points and points they should be expired. */
      if (pointsToRemove) {
        computedNewsTips = newsTips
        removedPoints = pointsToRemove
        approvedData = newsTips?.[0]?._approvedAt
      }
    }
  }

  return { removedPoints, approvedData }
}

export default computeExpireData
