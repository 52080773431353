import { Header } from '@qonsoll/react-design'
import styled from 'styled-components'

const StyledHeader = styled(Header)`
  background-color: var(--ql-header-bg);
  height: 64px;
  align-items: center;
  justify-content: center;
  color: var(--ql-typography-text-color-primary-reverse);
  min-height: auto;
  flex-direction: column;
  padding: 0;

  // there is no another way of rewriting avatar background styles then way below
  .accountWrapper {
    span.ant-avatar.ant-avatar-lg.ant-avatar-circle {
      background-color: var(--ql-header-avatar-bg);
    }
  }
`

export { StyledHeader }
