import { Image as AntdImage } from 'antd'
import styled from 'styled-components'

const Image = styled(AntdImage)`
  object-fit: cover;
  width: 100%;
  height: 180px;
  border-radius: var(--card-radius);
`

const VideoPlayerWrapper = styled.div`
  width: 100%;
  height: 180px;
`

export { Image, VideoPlayerWrapper }
