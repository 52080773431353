import { Box, Button, PageWrapper, Text } from '@qonsoll/react-design'
import { Form, Input, message } from 'antd'
import { generatePath, useHistory } from 'react-router-dom'

import {
  COLLECTIONS,
  SESSION_ERROR_CODES_AND_TEXT_MAP
} from '../../__constants__'
import PATHS from '../paths'
import firebase from 'firebase/compat/app'
import { updateDocument } from '../../services/api/firebase'
import { useConfirmation } from 'domains/Confirmation/context'
import { useEffect } from 'react'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { usePhoneNumber } from 'hooks'
import { useSignUpWithCredentials } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'

const codeRegexp = new RegExp(/^[0-9]{1,6}$/)

const ConfirmPhone = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const { signUpWithCredentials, loading } = useSignUpWithCredentials()
  const { isPhoneUpdate, setIsPhoneUpdate, setIsUserFinishedConfirmation } =
    useConfirmation()
  const { transformPhoneNumber } = usePhoneNumber()
  const newsPaperData = useNewspaperConfig()
  const { smsConfirmation } = newsPaperData

  const { phone, _id, data: userData } = history?.location?.state || {}
  const codeValidator = () => ({
    validator(_, value) {
      if (value?.length === 6 && codeRegexp.test(value)) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(t('Incorrect code format')))
    }
  })

  const onVerifyButtonClick = async (data) => {
    // If user is updating phone number
    if (isPhoneUpdate) {
      try {
        // Getting verification code from form
        const verificationCode = data?.code

        // If verification code is empty - show error
        if (!verificationCode)
          return message.error(t('Please enter verification code'))
        else {
          // If verification code is not empty - getting new auth credential
          const credential = await firebase.auth.PhoneAuthProvider.credential(
            isPhoneUpdate,
            verificationCode
          )

          // Updating user phone number
          await firebase.auth().currentUser.updatePhoneNumber(credential)

          // Updating user data in database
          await updateDocument(COLLECTIONS.USERS, _id, userData)

          message.success(t('Profile successfully updated'))

          setIsPhoneUpdate(false)

          setIsUserFinishedConfirmation(true)

          history.push(
            generatePath(PATHS.AUTHENTICATED.USER_SHOW, { userId: _id })
          )
        }
      } catch (error) {
        const errorMessage = SESSION_ERROR_CODES_AND_TEXT_MAP?.[error?.code]
        message.error(
          errorMessage
            ? t(errorMessage)
            : `${t('Something went wrong during phone confirmation')}, ${
                error?.message
              }`
        )
      }
    } else {
      signUpWithCredentials({
        ...data,
        ...history.location.state
      })
    }
  }

  useEffect(() => {
    return () => {
      if (isPhoneUpdate) {
        setIsPhoneUpdate(false)
        setIsUserFinishedConfirmation(true)
      }
    }
  }, [isPhoneUpdate, setIsPhoneUpdate, setIsUserFinishedConfirmation])

  const pageTitle = smsConfirmation?.title || t('Confirm your phone')
  const pageSubTitle =
    smsConfirmation?.description ||
    t(
      `We've sent you code. Please, check your phone and enter the verification code.`
    )

  return (
    <PageWrapper
      alignMiddle
      height="100%"
      headingProps={{
        title: pageTitle,
        titleVariant: ['h3', 'h1'],
        titleMarginBottom: [2, 3],
        subTitle: pageSubTitle,
        textAlign: 'center',
        marginBottom: [3, 24]
      }}
      contentWidth={['100%', '100%', 480]}>
      <Box display="flex" justifyContent="center">
        <Text code mb={[3, 40]}>
          {transformPhoneNumber(phone)}
        </Text>
      </Box>
      <Form validateTrigger={['submit']} onFinish={onVerifyButtonClick}>
        <Form.Item
          name="code"
          rules={[
            {
              required: true,
              type: 'string'
            },
            codeValidator
          ]}>
          <Input
            maxLength={6}
            size="large"
            placeholder={t('Confirmation code')}
          />
        </Form.Item>

        <Button
          loading={loading}
          type="primary"
          size="large"
          htmlType="submit"
          block>
          {t('Confirm')}
        </Button>
      </Form>
    </PageWrapper>
  )
}

export default ConfirmPhone
