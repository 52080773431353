import { FacebookAuthProvider, signInWithPopup } from 'firebase/auth'

import { SESSION_ERROR_CODES_AND_TEXT_MAP } from '__constants__'
import { auth } from 'services/firebase'
import { message } from 'antd'
import { useConfirmation } from 'domains/Confirmation/context'
import { useSignInWithProviderCredentials } from 'domains/Session/hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useFacebookLogin = () => {
  const [loading, setLoading] = useState(false)

  const provider = new FacebookAuthProvider()
  const signInWithProviderCredentials = useSignInWithProviderCredentials()
  const { t } = useTranslations()
  const { setIsUserFinishedConfirmation } = useConfirmation()

  const facebookLogin = async () => {
    try {
      setIsUserFinishedConfirmation(false)
      setLoading(true)

      const credentials = await signInWithPopup(auth, provider)

      await signInWithProviderCredentials(credentials)

      return
    } catch (error) {
      const errorDefaultText = `${t('Error during Facebook login')}, ${
        error?.message
      }`
      const computedErrorText = error?.code
        ? SESSION_ERROR_CODES_AND_TEXT_MAP?.[error?.code]
          ? t(SESSION_ERROR_CODES_AND_TEXT_MAP?.[error?.code])
          : errorDefaultText
        : errorDefaultText
      message.error(computedErrorText)
    } finally {
      setIsUserFinishedConfirmation(true)
      setLoading(false)
    }
    return
  }

  return { facebookLogin, loading }
}

export default useFacebookLogin
