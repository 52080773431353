const checkIfPointsSpent = ({
  newsTipPointsSum,
  pointsHistory,
  spentPointsSum
}) => {
  try {
    let spentPoints = pointsHistory
      ?.map(({ operationPoints }) => operationPoints)
      ?.reduce((prevItem, currentItem) => prevItem + currentItem, 0)

    if (spentPointsSum < spentPoints) spentPoints = spentPointsSum
    if (spentPointsSum < 0) spentPoints = 0

    const pointsToRemove = newsTipPointsSum - spentPoints

    if (pointsToRemove < 0) {
      const computedSpentPoints = newsTipPointsSum
      return { pointsToRemove: null, spentPoints: computedSpentPoints }
    }

    return {
      pointsToRemove,
      spentPoints
    }
  } catch (e) {
    console.error('Something during data in checkIfPointsSpent', e)
  }
}

export default checkIfPointsSpent
