import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { PageActions, Spinner } from 'components'
import { useGetNewsTips, useNewsTipBaseActions } from 'domains/NewsTip/hooks'

import { NewsTipsList } from 'domains/NewsTip/components'
import { TippsNoData } from 'components'
import { useMemo } from 'react'
import { useTranslations } from 'contexts'
import { STATUSES } from '__constants__'

const Dashboard = () => {
  const { t } = useTranslations()

  /* The `getNewsTipsConfig` constant is using the `useMemo` hook to memoize the configuration object for
fetching news tips. */
  const getNewsTipsConfig = useMemo(
    () => ({
      where: [['status', '!=', STATUSES.NEWS_TIP_STATUSES.DRAFT]],
      orderBy: [['status', 'asc']]
    }),
    []
  )
  const [newsTips, loading] = useGetNewsTips(getNewsTipsConfig)

  const { onCreate } = useNewsTipBaseActions()

  const actions = { create: true }

  /* Configuring page header */
  const headingProps = {
    title: t('My tipps list'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      action={<PageActions onCreate={onCreate} actions={actions} />}
      headingProps={headingProps}>
      {loading ? (
        <Spinner>{t('News tips loading')}</Spinner>
      ) : newsTips?.length ? (
        <Row noGutters>
          <Col cw={12}>
            <NewsTipsList newsTips={newsTips} />
          </Col>
        </Row>
      ) : (
        <Row h="center" noGutters>
          <Col cw={12} justifyContent="center">
            <TippsNoData />
          </Col>
        </Row>
      )}
    </PageWrapper>
  )
}

export default Dashboard
