import { UPLOADING_STATUSES } from '__constants__'
import { getDownloadURL } from 'firebase/storage'
import { message } from 'antd'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'

const useCompletedUploading = ({ setFiles, onChange, setLoadings }) => {
  /* A callback function that is called when the upload is successful. */
  const { t } = useTranslations()

  return useCallback(
    async (uploadMedia, file, onSuccess) => {
      try {
        const downloadUrl = await getDownloadURL(uploadMedia.snapshot.ref)

        const isVideo = file?.type?.includes('video')
        const computedThumbUrl = isVideo ? '' : downloadUrl

        const fileData = {
          id: file.uid,
          name: file.name,
          type: file.type,
          size: file.size,
          url: downloadUrl,
          thumbUrl: computedThumbUrl
        }

        setFiles((prevFiles) => {
          const newData = { ...prevFiles }

          const arr = newData?.files?.map((stateFile) => {
            const { uid } = stateFile

            if (uid === file.uid) {
              return {
                ...fileData,
                percent: 100,
                status: UPLOADING_STATUSES.DONE
              }
            }

            return stateFile
          })

          const computedUploadFiles = newData?.uploadFiles?.filter(
            (stateFile) => stateFile?.file?.uid !== file?.uid
          )

          const computedData = {
            files: [...arr],
            uploadFiles: [...computedUploadFiles]
          }

          onChange(computedData)
          return computedData
        })

        onSuccess?.()

        setLoadings((prev) => {
          let newData = [...prev]
          const index = newData?.indexOf(false)
          if (index !== -1) {
            newData[index] = true
          }
          return [...newData]
        })
      } catch (e) {
        message.error(`${t('Some problem with uploading file:')} ${file?.name}`)
      }
    },
    [onChange, t, setFiles]
  )
}

export default useCompletedUploading
