import { useTranslations } from '@qonsoll/translation'
import parsePhoneNumber from 'libphonenumber-js'

const usePhoneNumber = () => {
  const { t } = useTranslations()

  /**
   * Validates and formats phone number (default country code is NO)
   * @param {string} phone - validated phone number
   * @param {string|undefined} defaultCountry - default country code in uppercase
   * @return {object} - validated/corrected phone with conclusion
   */
  const validatePhoneNumber = (phone, defaultCountry = 'NO') => {
    const validatedPhoneNumber = {
      phone: !phone ? null : phone,
      valid: false
    }
    if (!phone) {
      return validatedPhoneNumber
    }

    const phoneNumberObj = parsePhoneNumber(phone, defaultCountry)
    if (!phoneNumberObj || !phoneNumberObj.isValid()) {
      if (!phone.includes('+')) {
        const phoneNumberRetryObj = parsePhoneNumber(
          `+${phone}`,
          defaultCountry
        )
        if (phoneNumberRetryObj && phoneNumberRetryObj.isValid()) {
          validatedPhoneNumber.phone = phoneNumberRetryObj.number
          validatedPhoneNumber.valid = true
        }
      }
    } else {
      validatedPhoneNumber.phone = phoneNumberObj.number
      validatedPhoneNumber.valid = true
    }
    return validatedPhoneNumber
  }

  /**
   * Validates and formats phone number to International format
   * @param {string} phoneNumber - validated phone number
   * @param {string|undefined} defaultCountry - default country code in uppercase
   * @return {{valid: boolean, phone: (null|string)}|string} - validated/corrected phone with conclusion or text that phone number is not valid wrapper in t function
   */
  const transformPhoneNumber = (phoneNumber, defaultCountry) => {
    if (!phoneNumber) return 'N/A'

    //  Validate and transform phone number
    let formattedPhoneNumber = validatePhoneNumber(phoneNumber, defaultCountry)

    if (formattedPhoneNumber?.valid) {
      const parsedPhoneNumber = parsePhoneNumber(formattedPhoneNumber.phone)

      formattedPhoneNumber = parsedPhoneNumber.formatInternational()
    } else {
      formattedPhoneNumber = t('Not valid phone number')
    }

    return formattedPhoneNumber
  }

  return { validatePhoneNumber, transformPhoneNumber }
}

export default usePhoneNumber
