import {
  Box,
  Col,
  Container,
  Option,
  Row,
  Select,
  Text,
  Title
} from '@qonsoll/react-design'
import { useCallback, useEffect, useMemo } from 'react'

import { PropTypes } from 'prop-types'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { useTranslations } from '@qonsoll/translation'

const PlacesAutocomplete = (props) => {
  const {
    value: formValue,
    onChange,
    withTitle = true,
    withSubtitle = true
  } = props
  const {
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      language: 'en',
      componentRestrictions: { country: 'no' }
    }
  })

  useEffect(() => {
    !value && formValue && setValue(formValue?.description)
  }, [value, formValue, formValue?.description, setValue])

  const { t } = useTranslations()

  // Update the keyword of the input element
  const handleInput = useCallback((value) => setValue(value), [setValue])

  const handleSelect = useCallback(
    (placeId) => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      const searchValue = data?.find((item) => item?.place_id === placeId)

      const { description, place_id: locationId } = searchValue
      setValue(searchValue?.description)
      onChange({ description, locationId })
      clearSuggestions()
    },
    [clearSuggestions, data, onChange, setValue]
  )

  const computedOptions = useMemo(
    () =>
      status === 'OK' &&
      data?.map(({ place_id, description }) => (
        <Option key={place_id} value={place_id}>
          {description}
        </Option>
      )),
    [data, status]
  )

  const selectProps = {
    style: { width: '100%' },
    onSearch: handleInput,
    onSelect: handleSelect,
    filterOption: false,
    placeholder: t('Please enter location'),
    value: value || null,
    showSearch: true
  }

  return (
    <Container mb={4}>
      <Row noGutters>
        <Col cw={12}>
          {withTitle ? (
            <Title textAlign="center" mb={1} level={4}>
              {t('Location')}
            </Title>
          ) : null}
          {withSubtitle ? (
            <Box mb={3} textAlign="center">
              <Text>{`${t(
                'Write the location of the tip you want to add'
              )}.`}</Text>
            </Box>
          ) : null}
          <Box>
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            <Select {...selectProps}>{computedOptions}</Select>
          </Box>
        </Col>
      </Row>
    </Container>
  )
}

PlacesAutocomplete.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  withTitle: PropTypes.string,
  withSubtitle: PropTypes.string
}

export default PlacesAutocomplete
