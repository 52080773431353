import { model, attr, hasOne } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .default(null)
    .nullable(),
  newspaper: yup
    .string()
    .typeError('Field newspaper has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable()
})

const Subadmin = model(
  'subadmin',
  {
    sno: attr('number'),
    name: attr('string'),
    email: attr('string'),
    newspaper: hasOne('newspaper'),
    status: hasOne('subadminStatus')
  },
  validationSchema
)

export default Subadmin
