import {
  ContentStep,
  LocationStep,
  MediaContentStep,
  SummaryStep,
  TagsStep,
  TitleStep
} from '../../../components/NewsTipWizard/components'

import { useMemo } from 'react'
import { STEP_NAMES } from 'domains/NewsTip/components/NewsTipWizard/constants'

const useNewsTipWizardSteps = ({
  value,
  setValue,
  setUploadLoading,
  uploadLoading,
  suggestionsLoading,
  setStep,
  isGuestMode
}) => {
  return useMemo(
    () => [
      {
        component: <TitleStep value={value} setValue={setValue} />,
        name: STEP_NAMES.TITLE
      },
      {
        component: (
          <MediaContentStep
            value={value}
            setValue={setValue}
            setUploadLoading={setUploadLoading}
            setStep={setStep}
            isGuestMode={isGuestMode} // necessary to pass this prop to the
            // uploader so it can set up appropriate naming for the files
          />
        ),
        name: STEP_NAMES.MEDIA
      },
      {
        component: <LocationStep value={value} setValue={setValue} />,
        name: STEP_NAMES.LOCATION
      },
      {
        component: (
          <TagsStep
            value={value}
            setValue={setValue}
            suggestionsLoading={suggestionsLoading}
          />
        ),
        name: STEP_NAMES.TAGS
      },
      {
        component: <ContentStep value={value} setValue={setValue} />,
        name: STEP_NAMES.CONTENT
      },
      {
        component: <SummaryStep value={value} setValue={setValue} />,
        name: STEP_NAMES.SUMMARY
      }
    ],
    [value, setValue, uploadLoading, setUploadLoading, suggestionsLoading]
  )
}

export default useNewsTipWizardSteps
