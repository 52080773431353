import { UPLOADING_STATUSES } from '__constants__'
import { useCallback } from 'react'

const useGetProcessUploading = ({ setFiles }) => {
  /* Updating the state of the file. */
  return useCallback(
    (snapshot, file) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setFiles((prevFiles) => {
        const newFiles = prevFiles?.files?.map((stateFile) => {
          const { uid } = stateFile

          if (uid === file.uid) {
            return {
              ...stateFile,
              percent: Number.parseInt(progress.toFixed(0)),
              status:
                snapshot.state === UPLOADING_STATUSES.RUNNING
                  ? UPLOADING_STATUSES.UPLOADING
                  : snapshot.state
            }
          }

          return stateFile
        })

        return { ...prevFiles, files: [...newFiles] }
      })
    },
    [setFiles]
  )
}

export default useGetProcessUploading
