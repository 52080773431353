import { UPLOADING_STATUSES } from '__constants__'
import { useCallback } from 'react'

const useErrorUploading = ({ setFiles, setUploadData }) =>
  useCallback(
    (file) => {
      setFiles((prevFiles) =>
        prevFiles
          ?.map((stateFile) => {
            const { uid } = stateFile

            if (stateFile?.file?.uid === file?.uid) {
              return null
            }

            if (uid === file?.uid) {
              return {
                ...stateFile,
                percent: 100,
                status: UPLOADING_STATUSES.ERROR
              }
            }
            return stateFile
          })
          ?.filter(Boolean)
      )
    },
    [setFiles, setUploadData]
  )

export default useErrorUploading
