import { Form, Input } from 'antd'

import { ImageUploader } from 'components'
import { MAX_LENGTH_FIELD } from '__constants__'
import { PhoneInput } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const UserSimpleForm = (props) => {
  const { group } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <>
      <Form.Item name={[...group, 'avatarUrl']}>
        <ImageUploader placeholder={t('Please upload your avatar')} />
      </Form.Item>

      <Form.Item
        label={t('First name')}
        name={[...group, 'firstName']}
        rules={[
          {
            required: true,
            message: t('Please enter your first name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('First name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user first name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name={[...group, 'lastName']}
        rules={[
          {
            required: true,
            message: t('Please enter your last name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('Last name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your last name')} />
      </Form.Item>
      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            required: true,
            message: t('Please enter your user email!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user email is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user email')} />
      </Form.Item>
      <Form.Item
        label={t('Phone number')}
        name={[...group, 'phoneNumber']}
        rules={[
          {
            required: true,
            message: t('Please enter your user phone number!')
          }
        ]}>
        <PhoneInput
          country="no"
          preferredCountries={['no']}
          specialLabel=""
          placeholder={t('Please enter your user phone number')}
        />
      </Form.Item>
    </>
  )
}

UserSimpleForm.propTypes = {
  group: PropTypes.array
}

export default UserSimpleForm
