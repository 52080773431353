import { COLLECTIONS } from '__constants__'
import { useNewspaperConfig } from 'contexts'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { collection, query, where } from 'firebase/firestore'
import { firestore } from 'services/firebase'

/**
 * It returns an array of values that are used to render the rewards
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetRewards = (props) => {
  const { newspaperId } = useNewspaperConfig()

  return useCollectionData(
    newspaperId &&
      query(
        collection(firestore, COLLECTIONS.REWARDS),
        where('newspaperId', '==', newspaperId),
        where('status', '==', 'Active')
      )
  )
}

export default useGetRewards
