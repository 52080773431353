import { useState } from 'react'
import PropTypes from 'prop-types'
import ConfirmationContext from './ConfirmationContext'

const ConfirmationProvider = (props) => {
  const { children } = props

  const [confirmationObject, setConfirmationObject] = useState(null)
  const [isUserFinishedConfirmation, setIsUserFinishedConfirmation] =
    useState(true)
  const [isPhoneUpdate, setIsPhoneUpdate] = useState(null)

  return (
    <ConfirmationContext.Provider
      value={{
        confirmationObject,
        changeConfirmationObject: setConfirmationObject,
        isUserFinishedConfirmation,
        setIsUserFinishedConfirmation,
        setIsPhoneUpdate,
        isPhoneUpdate
      }}>
      {children}
    </ConfirmationContext.Provider>
  )
}

ConfirmationProvider.propTypes = {
  children: PropTypes.node
}

export default ConfirmationProvider
