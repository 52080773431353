import { Spin } from '@qonsoll/react-design'
import styled from 'styled-components'

const StyledSpin = styled(Spin)`
  .ant-spin-dot {
    display: flex;
  }
`

export { StyledSpin }
