const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: [80, 100, 150]
  },
  imageWrapperTop: {
    alignSelf: 'flex-end'
  },
  image: {
    width: '100%'
  }
}

export default styles
