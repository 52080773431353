import { doc, updateDoc } from 'firebase/firestore'

import { LOG_TYPES } from '__constants__'
import { createLog } from 'services/logs'
import { firestore } from 'services/firebase'

/**
 * It updates a document in a collection
 * @param collectionPath - The path to the collection you want to update.
 * @param _id - The _id of the document you want to update.
 * @param data - The data to be updated.
 * @returns A promise that resolves to the data that was updated.
 */
const updateDocument = async (collectionPath, _id, data) => {
  const ref = doc(firestore, collectionPath, _id)

  await updateDoc(ref, data)
  createLog(LOG_TYPES.UPDATE, collectionPath, { ...data, _id })

  return { ...data, _id }
}

export default updateDocument
