import { COLLECTIONS, STATUSES } from '__constants__'
import { collection, query, where } from 'firebase/firestore'

import { firestore } from 'services/firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const useGetExpiringNewsTips = ({
  _pointsExpirationEnabledAt,
  arePointsExpiring,
  newspaperId,
  userId
}) => {
  const { NEWS_TIP_STATUSES } = STATUSES
  const { NEWS_TIPS } = COLLECTIONS

  let computedQuery
  if (
    arePointsExpiring &&
    newspaperId &&
    _pointsExpirationEnabledAt &&
    userId
  ) {
    computedQuery = query(
      collection(firestore, NEWS_TIPS),
      where('_createdAt', '>=', _pointsExpirationEnabledAt),
      where('newspaperId', '==', newspaperId),
      where('status', '==', NEWS_TIP_STATUSES.APPROVED),
      where('arePointsExpired', '==', false),
      where('_createdBy', '==', userId)
    )
  }

  const [newsTips, newsTipsLoading] = useCollectionData(computedQuery)

  /* Compute the oldest news tips, from received documents */
  const oldestNewsTip = newsTips?.reduce(
    (prev, curr) =>
      prev?._approvedAt?.seconds < curr?._approvedAt?.seconds ? prev : curr,
    0
  )

  return { newsTips, oldestNewsTip, newsTipsLoading }
}

export default useGetExpiringNewsTips
