import { Box, Col, Container, Row, Text, Title } from '@qonsoll/react-design'

import { Input } from 'antd'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { useNewspaperConfig } from 'contexts'
import { useDebounceUpdateTips } from 'domains/NewsTip/hooks'
import { STEP_NAMES } from '../constants'

const TitleStep = (props) => {
  const { value, setValue } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { sendTip, newspaperId } = useNewspaperConfig()

  // Getting  the debounce call
  const { debouncedSendRequest } = useDebounceUpdateTips()

  /* Handling changes */
  const handleChange = (event) => {
    const inputData = event.target.value
    setValue((prev) => ({ ...prev, title: inputData }))

    debouncedSendRequest({
      data: {
        ...value,
        title: inputData,
        newspaperId
      },
      step: STEP_NAMES.TITLE,
      newsTipId: value?._id
    })
  }

  const computedTitle = sendTip?.title || t('Please, add title')
  const computedDescription =
    sendTip?.description || t('It is mandatory to add a heading and title')

  return (
    <Container mb={4}>
      <Title textAlign="center" variant="h4" mb={1}>
        {computedTitle}
      </Title>
      <Box textAlign="center" mb={3}>
        <Text>{computedDescription}</Text>
      </Box>
      <Row noGutters h="center">
        <Col cw={12}>
          <Input
            value={value?.title}
            onChange={handleChange}
            placeholder={t('Please enter news tip title')}
          />
        </Col>
      </Row>
    </Container>
  )
}

TitleStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func
}

export default TitleStep
