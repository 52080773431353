import { Button, Col, Form, Input, Row } from 'antd'

import { Link } from 'react-router-dom'
import PATHS from 'pages/paths'
import { PhoneInput } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { StyledCheckbox } from './SignUpForm.styled'
import { useConfirmPhone } from 'domains/Session/hooks'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const SignupForm = ({ signup, onError, ...rest }) => {
  const { t } = useTranslations()
  const { confirmPhone, loading } = useConfirmPhone()
  const { validatePhoneNumber } = usePhoneNumber()

  const onFinish = (credentials) => {
    /* Setting the loading state to true, then calling the signup function, then setting the loading
    state to false. */
    confirmPhone(credentials)
  }

  return (
    <Form validateTrigger={['submit']} onFinish={onFinish} {...rest}>
      <Form.Item
        name="email"
        rules={[
          {
            type: 'email',
            required: true,
            message: t('Please enter valid email')
          }
        ]}>
        <Input size="large" autoFocus placeholder={t('Email')} />
      </Form.Item>
      <Row gutter={8}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            rules={[
              {
                required: true,
                message: t('Please enter first name')
              }
            ]}>
            <Input size="large" placeholder={t('First name')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            rules={[
              {
                required: true,
                message: t('Please enter last name')
              }
            ]}>
            <Input size="large" placeholder={t('Last name')} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="phone"
        initialValue="+47"
        rules={[
          {
            required: true,
            message: t('Please enter phone number')
          },
          () => ({
            validator(_, value) {
              if (validatePhoneNumber(value).valid) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(t('Invalid phone number')))
            }
          })
        ]}>
        <PhoneInput
          country="no"
          preferredCountries={['no']}
          specialLabel=""
          autoFormat
          size="lg"
          placeholder={t('Phone number')}
        />
      </Form.Item>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          { required: true, message: t('Please, enter your password') },
          { min: 6, message: t('Password must be at least 6 characters') }
        ]}>
        <Input.Password size="large" placeholder={t('Password')} />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        validateTrigger={['onBlur', 'onChange']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t('Please confirm your password!')
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (value && getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('The two passwords that you entered do not match!'))
              )
            }
          })
        ]}>
        <Input.Password size="large" placeholder={t('Confirm Password')} />
      </Form.Item>
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t('Should accept agreement')))
          }
        ]}>
        <StyledCheckbox>
          {t('I have read the')}{' '}
          <Link
            to={PATHS.UNAUTHENTICATED.TERMS_AND_CONDITIONS}
            target="_blank"
            rel="noopener noreferrer">
            {t('agreement')}
          </Link>
        </StyledCheckbox>
      </Form.Item>
      <Button
        id="sign-in-button"
        type="primary"
        htmlType="submit"
        size="large"
        block
        loading={loading}>
        {t('Sign up')}
      </Button>
    </Form>
  )
}

SignupForm.propTypes = {
  form: PropTypes.object,
  onError: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  signup: PropTypes.func.isRequired
}

export default SignupForm
