import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  subtitle: yup
    .string()
    .typeError('Field subtitle has wrong type')
    .default(null)
    .nullable(),
  description: yup
    .string()
    .typeError('Field description has wrong type')
    .default(null)
    .nullable(),
  content: yup
    .string()
    .typeError('Field content has wrong type')
    .default(null)
    .nullable()
})

const News = model(
  'news',
  {
    title: attr('string'),
    subtitle: attr('string'),
    description: attr('string'),
    content: attr('string')
  },
  validationSchema
)

export default News
