import { useStateWithStorage } from 'hooks'

/**
 * It returns a state value and a function to update that state value, and it uses the domain name to
 * store the state value in local storage
 * @param domain - The domain of the app. For example, if the app is for managing contacts, the domain
 * would be "contacts".
 * @returns A function that returns a state object and a function to update it.
 */
const useListView = (domain) => {
  /* Getting list view state from local storage */
  return useStateWithStorage(false, `${domain}-list-view`)
}

export default useListView
