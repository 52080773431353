const iconLanguageSelectedStyles = {
  name: 'CheckmarkFilled',
  size: 20,
  fill: 'currentColor'
}

const iconLanguageStyles = {
  fill: 'currentColor',
  name: 'LanguageOutlined',
  size: 20,
  mr: 2
}

export { iconLanguageSelectedStyles, iconLanguageStyles }
