import { GDPRPopup } from './domains/App/components'
import Navigator from './pages/Navigator'
import { TranslationsProvider } from 'contexts'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'

/**
 * It returns a React component that renders a TranslationsProvider and a Navigator.
 * @returns The <TranslationsProvider> component.
 */
function App() {
  return (
    <QueryParamProvider adapter={ReactRouter5Adapter}>
      <TranslationsProvider>
        <Navigator />
        <GDPRPopup />
      </TranslationsProvider>
    </QueryParamProvider>
  )
}

export default App
