import { AboutUs, PrivacyPolicy, Settings, TermsOfConditions } from './Settings'
import {
  NewsTipShow,
  NewsTipSuccessfullyCreated,
  NewsTipWizard
} from './NewsTip'
import { RewardShow, RewardsAll } from './Reward'
import { UserEdit, UserShow } from './User'

import Dashboard from './Dashboard/Dashboard'
import PATHS from '../paths'
import { PointsHistoriesAll } from './PointsHistory'
import { Statistics } from './Statistics'

const {
  DASHBOARD,
  USER_SHOW,
  USER_EDIT,
  SETTINGS,
  STATISTICS,
  REWARDS_ALL,
  REWARD_SHOW,
  NEWS_TIP_WIZARD,
  NEWS_TIP_SHOW,
  POINTS_HISTORIES_ALL,
  ABOUT_US,
  PRIVACY_POLICY,
  TERMS_OF_CONDITIONS,
  NEWS_TIP_SUCCESSFULLY_CREATED
} = PATHS.AUTHENTICATED

const MAIN_ROUTES = [
  { key: 'DASHBOARD', path: DASHBOARD, component: Dashboard, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },
  { key: 'USER_EDIT', path: USER_EDIT, component: UserEdit, exact: true },
  {
    key: 'SETTINGS',
    path: SETTINGS,
    component: Settings,
    exact: false
  },
  {
    key: 'STATISTICS',
    path: STATISTICS,
    component: Statistics,
    exact: false
  },
  { key: 'REWARDS_ALL', path: REWARDS_ALL, component: RewardsAll, exact: true },
  { key: 'REWARD_SHOW', path: REWARD_SHOW, component: RewardShow, exact: true },
  {
    key: 'NEWS_TIP_WIZARD',
    path: NEWS_TIP_WIZARD,
    component: NewsTipWizard,
    exact: true
  },
  {
    key: 'NEWS_TIP_SHOW',
    path: NEWS_TIP_SHOW,
    component: NewsTipShow,
    exact: true
  },
  {
    key: 'POINTS_HISTORIES_ALL',
    path: POINTS_HISTORIES_ALL,
    component: PointsHistoriesAll,
    exact: true
  },
  {
    key: 'ABOUT_US',
    path: ABOUT_US,
    component: AboutUs,
    exact: true
  },
  {
    key: 'PRIVACY_POLICY',
    path: PRIVACY_POLICY,
    component: PrivacyPolicy,
    exact: true
  },
  {
    key: 'TERMS_OF_CONDITIONS',
    path: TERMS_OF_CONDITIONS,
    component: TermsOfConditions,
    exact: true
  },
  {
    key: 'NEWS_TIP_SUCCESSFULLY_CREATED',
    path: NEWS_TIP_SUCCESSFULLY_CREATED,
    component: NewsTipSuccessfullyCreated,
    exact: true
  }
]

const SETTINGS_ROUTES = []

const STATISTICS_ROUTES = []

export { MAIN_ROUTES, SETTINGS_ROUTES, STATISTICS_ROUTES }
export default MAIN_ROUTES
