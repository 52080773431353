import { StyledImg, StyledUserBalance } from './UserBalanceSimpleView.styles'

import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import euro from 'assets/euro.png'
import { formatPointsNumbers } from 'helpers'
import { useMemo } from 'react'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'

const UserBalanceSimpleView = (props) => {
  const { pointsGiven } = props
  const { newspaperId } = useNewspaperConfig()

  /* Get total points given by newspaper */
  const computeTotalPointsGiven = useMemo(() => {
    if (pointsGiven && newspaperId) {
      return formatPointsNumbers(pointsGiven)
    } else return 0
  }, [pointsGiven, newspaperId])

  return (
    <StyledUserBalance>
      <StyledImg src={euro} />
      <Text fontSize={['var(--ql-font-size-coins-small-mobile)', 'inherit']}>
        {computeTotalPointsGiven || 0}
      </Text>
    </StyledUserBalance>
  )
}

UserBalanceSimpleView.propTypes = {
  pointsGiven: PropTypes.number
}

export default UserBalanceSimpleView
