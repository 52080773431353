import { Box, Card, Col } from '@qonsoll/react-design'

import loginBackground from '../../../../assets/loginBackground.png'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  background-image: url(${loginBackground});
  background-size: cover;
  background-repeat: repeat-y;
  border-radius: 24px;
  height: 636px;
  display: flex;
  .ant-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

const StyledBox = styled(Box)`
  background-color: var(--ql-color-white);
  margin-top: -30px;
  border-radius: var(--ql-border-radius-lg);
  flex: 1;
`
const StyledUserInfoBox = styled(Box)`
  text-align: center;
  background-color: var(--ql-color-accent1-t-lighten6);
  margin-bottom: 12px;
  padding: 16px;
  padding-top: 50px;
`
const styles = {
  newsPaperLogoStyles: {
    width: 40,
    height: 40,
    mr: 2,
    objectFit: 'contain',
    borderRadius: 'var(--card-radius)'
  },
  coinStyles: {
    width: 40,
    height: 40,
    mr: 2
  },
  textStyles: {
    fontWeight: 'var(--ql-typography-heading-font-weight)'
  }
}

export { StyledCard, StyledBox, StyledUserInfoBox, styles }
