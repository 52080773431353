import { useHandleError, useQueryParams } from 'hooks'

import { LoginForm } from 'domains/Session/components'
import PATHS from 'pages/paths'
import { PageWrapper } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { useSessionActions } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'

const LoginWithEmail = (props) => {
  const { isGuestMode = false, guestModeActions = null } = props

  const { t } = useTranslations()

  const history = useHistory()
  const onError = useHandleError()
  const { login, getLastSessionProvider } = useSessionActions()
  const isEmailExists = useQueryParams('email')
  const sessionProvider = getLastSessionProvider()

  const redirectToForgotPassword = () =>
    history.push(PATHS.UNAUTHENTICATED.FORGOT_PASSWORD)
  const handleForgotPasswordClick = () => {
    isGuestMode
      ? guestModeActions?.showForgotPasswordForm()
      : redirectToForgotPassword()
  }
  const goBack = () => history.goBack()

  const onBackActionComputed = isGuestMode
    ? guestModeActions?.handleLoginFormBack
    : goBack
  const backBtnPropsComputed = isGuestMode ? { margin: '24px 16px 0' } : null
  const isBackButtonDividedComputed = !isGuestMode

  return (
    <PageWrapper
      onBack={onBackActionComputed}
      backBtnProps={backBtnPropsComputed}
      divided={isBackButtonDividedComputed}
      alignMiddle
      height="100%"
      headingProps={{
        title: t('Login with email'),
        subTitle: t('Please, enter your email and password'),
        marginBottom: 40
      }}
      contentWidth={['100%', '100%', 400]}
      firstLevelHidden={isEmailExists && sessionProvider === 'email'}>
      <LoginForm
        login={login}
        onError={onError}
        onForgotPasswordClick={handleForgotPasswordClick}
      />
    </PageWrapper>
  )
}

LoginWithEmail.propTypes = {
  isGuestMode: PropTypes.bool,
  guestModeActions: PropTypes.shape({
    showPhoneConfirmationForm: PropTypes.func,
    showLoginWithEmailForm: PropTypes.func,
    showForgotPasswordForm: PropTypes.func,
    handleLoginFormBack: PropTypes.func
  })
}

export default LoginWithEmail
