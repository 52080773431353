import { Img } from '@qonsoll/react-design'
import { Tag } from 'antd'
import styled from 'styled-components'

const ImgStyled = styled(Img)`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  border-radius: var(--card-radius);
  object-fit: cover;
`

const VideoStyled = styled.video`
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
  border-radius: var(--card-radius);
  object-fit: cover;
`

const TagStyled = styled(Tag)`
  width: min-content;
`

const styles = {
  column: { flex: '1', justifyContent: 'flex-end' },
  title: {
    level: 4,
    isEllipsis: true,
    clamp: '3',
    whiteSpace: 'break-spaces',
    style: { wordBreak: 'break-word' }
  }
}

export { TagStyled, ImgStyled, VideoStyled, styles }
