import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsUserAdvancedForm,
  useInitializeUserAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import PropTypes from 'prop-types'
import { UserSimpleForm } from 'domains/User/components'
import { useTranslations } from 'contexts/Translation'
import { useChangeUserPhone } from 'domains/User/hooks'

const UserAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeUserAdvancedForm(initialData)

  const { changeUserPhone, loading: userPhoneConfirmationLoading } =
    useChangeUserPhone()

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      onFinish()
    }
  }
  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsUserAdvancedForm({
    initialData,
    form,
    changeUserPhone
  })
  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <UserSimpleForm showTitle={false} group={[...group, 'user']} />
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <UserSimpleForm group={['user']} />

      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button
        id="verify-phone-button"
        type="primary"
        htmlType="submit"
        loading={loading || userPhoneConfirmationLoading}>
        {t('Save')}
      </Button>
    </Form>
  )
}

UserAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  isSuperAdmin: PropTypes.bool
}

export default UserAdvancedForm
