import { createDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { useTranslations } from '@qonsoll/translation'
import { message } from 'antd'

const useCreateUser = () => {
  const { t } = useTranslations()
  const createUser = async (uid, data, additionalData) => {
    try {
      const { photoURL, email, firstName, lastName, phone } = data

      const userData = {
        avatarUrl: photoURL || null,
        email: email || null,
        firstName: additionalData?.firstName || firstName || null,
        lastName: additionalData?.lastName || lastName || null,
        phoneNumber: additionalData?.phoneNumber || phone || null,
        status: 'Active',
        _id: uid,
        // Service fields
        _updatedAt: null,
        _createdBy: uid,
        _isUpdated: false,
        _updatedBy: null,
        isBlocked: false,
        sno: null,
        notificationPermissions: false,
        isBiometricActive: false
      }

      await createDocument(COLLECTIONS.USERS, userData, uid)
    } catch (e) {
      message.error(t('Something went wrong during creation user'), e?.message)
    }
  }

  return createUser
}
export default useCreateUser
