import { Col, Row, Spin } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { CONTENT_TYPES } from '__constants__'
import { useGetContent } from 'domains/Content/hooks'
import { Fragment } from 'react'
import parser from 'html-react-parser'
import { Empty } from 'antd'
import { useTranslations } from '@qonsoll/translation'

const Content = (props) => {
  const { contentType } = props

  const [content, loading] = useGetContent(contentType)
  const { t } = useTranslations()

  return (
    <Row noGutters h="center">
      <Col>
        {loading ? (
          <Spin />
        ) : content?.content ? (
          <Fragment>{parser(content?.content)}</Fragment>
        ) : (
          <Empty description={t('No data')} />
        )}
      </Col>
    </Row>
  )
}

Content.propTypes = {
  contentType: PropTypes.oneOf(Object.values(CONTENT_TYPES))
}

export default Content
