import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import { useExtraBreadcrumbsItems } from './hooks'
import { useTranslations } from 'contexts/Translation'

/**
 * It returns a breadcrumb component with a link to the home page and a link to the current page
 * @returns A breadcrumb component with a link to the home page and any extra breadcrumbs that are
 * passed in.
 */
const HeaderBreadcrumbs = () => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting extra breadcrumbs */
  const [extraBreadcrumbItems] = useExtraBreadcrumbsItems()

  return (
    <Breadcrumb>
      <Breadcrumb.Item key={'/'}>
        <Link to="/">{t('Home')}</Link>
      </Breadcrumb.Item>
      {extraBreadcrumbItems?.map(({ key, path, name }) => (
        <Breadcrumb.Item key={key}>
          <Link to={path}>{name}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

export default HeaderBreadcrumbs
