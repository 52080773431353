import firebaseConfig from 'configs/firebase'

const FIREBASE_FUNCTION_URL = firebaseConfig.apiUrl

export const ENDPOINTS = {
  GET_IMAGE_SUGGESTIONS: `${FIREBASE_FUNCTION_URL}/onImageSuggestions/imageSuggestions`,
  LOGIN_WITH_PHONE: `${FIREBASE_FUNCTION_URL}/onSignInUserWithPhone`,
  ON_OLD_USER_LOGIN: `${FIREBASE_FUNCTION_URL}/onOldUserLogin`,
  DELETE_USER: `${FIREBASE_FUNCTION_URL}/deleteUser`,
  CREATE_USER_DOCUMENT_FOR_ADMIN_OR_EDITOR: `${FIREBASE_FUNCTION_URL}/createUserDocForUsersWithAdminOrEditorRole`,
  GET_USER_CUSTOM_CLAIMS_AND_UID: `${FIREBASE_FUNCTION_URL}/getUserCustomClaimsAndUid`,
  VERIFY_CAPTCHA: `${FIREBASE_FUNCTION_URL}/verifyCaptcha`,
  PUBLIC_NEWS_TIPS: `${FIREBASE_FUNCTION_URL}/publicNewsTips`
}
