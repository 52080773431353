import { Box, Col, Container, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { Tag } from 'antd'
import { useMemo } from 'react'
import { useTagsBaseActions } from 'domains/Tags/hooks'
import { useTranslations } from 'contexts/Translation'

const TagsForm = (props) => {
  const { value, setValue, suggestionsLoading } = props

  const tags = value?.tags
  const suggestions = value?.suggestions

  /* Getting translations instance */
  const { t } = useTranslations()

  const { handleAdd, handleRemove } = useTagsBaseActions({
    value,
    setValue
  })

  const computedChooseTags = useMemo(
    () =>
      tags?.map?.((tag, i) => (
        <Box key={`${tag}-${i}`} mb={2}>
          <Tag closable onClose={() => handleRemove(tag)}>
            {tag}
          </Tag>
        </Box>
      )),
    [tags, handleRemove]
  )

  const computedSuggestions = useMemo(
    () =>
      suggestions?.map?.((tag, i) => (
        <Box key={`${tag}-${i}`} mb={2} onClick={() => handleAdd(tag)}>
          <Tag>{tag}</Tag>
        </Box>
      )),
    [suggestions, handleAdd]
  )

  return (
    <Container mb={4}>
      <Row>
        <Col>
          <Title textAlign="center" level={4} mb={1}>
            {t('Tags')}
          </Title>
          <Text textAlign="center" mb={3}>{`${t(
            'Choose the most relevant tags for your tip'
          )}.`}</Text>
        </Col>
      </Row>

      {tags?.length ? (
        <Box display="flex" flexWrap="wrap" mb={1}>
          {computedChooseTags}
        </Box>
      ) : (
        <Box textAlign="center" mb={2}>
          <Text type="secondary">{t('Have not selected tags')}</Text>
        </Box>
      )}

      <Row noGutters h="center">
        <Col cw={12}>
          <Title mb={3} level={5}>
            {t('Suggestions:')}
          </Title>
          {suggestionsLoading ? (
            <Spinner text={t('Tags are loading')} />
          ) : computedSuggestions?.length ? (
            <Box display="flex" flexWrap="wrap">
              {computedSuggestions}
            </Box>
          ) : (
            <Box textAlign="center">
              <Text type="secondary">{t('No suggestions')}</Text>
            </Box>
          )}
        </Col>
      </Row>
    </Container>
  )
}

TagsForm.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func,
  suggestionsLoading: PropTypes.bool
}

export default TagsForm
