import { model, attr } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  title: yup
    .string()
    .typeError('Field title has wrong type')
    .default(null)
    .nullable(),
  addedOn: yup
    .string()
    .typeError('Field addedOn has wrong type')
    .default(null)
    .nullable()
})

const Notification = model(
  'notification',
  {
    sno: attr('number'),
    title: attr('string'),
    addedOn: attr('string')
  },
  validationSchema
)

export default Notification
