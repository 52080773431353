import { Img } from '@qonsoll/react-design'
import styled from 'styled-components'

const ImgStyled = styled(Img)`
  height: 230px;
  object-fit: cover;
  border-radius: var(--card-radius);
`

export { ImgStyled }
