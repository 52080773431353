import { StyledVideoPlayer, StyledVideoWrapper } from './VideoPlayer.styled'

import PropTypes from 'prop-types'

const VideoPlayer = ({ src }) => {
  return (
    <StyledVideoPlayer>
      <StyledVideoWrapper>
        <video controls src={src} />
      </StyledVideoWrapper>
    </StyledVideoPlayer>
  )
}
VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired
}

export default VideoPlayer
