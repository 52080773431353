import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { useEvent } from 'hooks'
import { useMemo } from 'react'

/* Path's */
const showPath = PATHS.AUTHENTICATED.REWARD_SHOW

const useRewardBaseActions = ({ rewardId, actions } = {}) => {
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { rewardId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onOpen: handleOpen,
      ...(actions || {}),
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions?.onBack, actions?.onOpen, handleBack, handleOpen]
  )
}

export default useRewardBaseActions
