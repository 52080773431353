import { useMemo } from 'react'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useUser } from 'domains/User/context'

const useGetQuery = ({ collectionName, where, orderBy, statuses }) => {
  const inequalityComparators = ['<', '<=', '!=', 'not-in', '>', '>=']
  /**
   * The function swaps two elements in an array based on their indices.
   * @param array - The array parameter is the array in which the elements will be swapped.
   * @param index1 - The index of the first element to be swapped in the array.
   * @param index2 - The `index2` parameter is the index of the second element that you want to swap in
   * the array.
   * @returns The function does not explicitly return anything.
   */
  function swapArrayElements(array, index1, index2) {
    // Check if the indices are within the bounds of the array
    if (
      index1 < 0 ||
      index1 >= array.length ||
      index2 < 0 ||
      index2 >= array.length
    ) {
      console.error('Invalid indices for swapping.')
      return
    }

    // Swap elements
    const temp = array[index1]
    array[index1] = array[index2]
    array[index2] = temp
  }

  const { newspaperId } = useNewspaperConfig()
  const { user } = useUser()

  const statusValues = useMemo(
    () => (statuses ? Object?.values?.(statuses) : []),
    [statuses]
  )

  const userId = user?._id || null
  const computedNewspaperId = newspaperId || null
  /* Query for getting collection reference */
  return useMemo(() => {
    const query = { ref: collectionName }

    const computedWhere = [
      !!statusValues?.length && ['status', 'in', statusValues],
      ['_createdBy', '==', userId],
      ['newspaperId', '==', computedNewspaperId],
      ...(where || [])
    ]?.filter(Boolean)

    const computedOrderBy = [['_createdAt', 'desc'], ...(orderBy || [])]

    // check if there any where filter with an inequality (<, <=, !=, not-in, >, or >=)
    // so we can form correct query and put the first orderBy() on such field
    // without the code below it will be an error in 100% cases when there is a filter (query) with an inequality.
    // TODO! To check how does this code behave with a couple of inequality filters (queries)
    const whereWithInequality = computedWhere.find((whereQuery) =>
      inequalityComparators.some((comparator) => whereQuery[1] === comparator)
    )
    if (whereWithInequality) {
      const field = whereWithInequality[0]

      // find field in the computedOrderBy which looks like ['_createdAt', 'desc']
      const indexOfOrderByForInequality = computedOrderBy.findIndex(
        (orderBy) => orderBy[0] === field
      )

      // Swap elements at index 0 (first orderBy) and 3
      swapArrayElements(computedOrderBy, 0, indexOfOrderByForInequality)
    }

    if (computedWhere?.length) query.where = computedWhere
    if (computedOrderBy.length) query.orderBy = computedOrderBy

    return query
  }, [collectionName, where, orderBy, statusValues, userId])
}

export default useGetQuery
