import { useEffect, useMemo, useRef, useState } from 'react'

const useStopwatchTime = (isModalVisible) => {
  const [secs, setSeconds] = useState(0)
  const [mins, setMinutes] = useState(0)

  const timerRef = useRef(null)

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setSeconds((sec) => sec + 1)
      if (secs === 59) {
        setMinutes((min) => min + 1)
        setSeconds(0)
      }
    }, 1000)

    return () => clearInterval(timerRef.current)
  }, [secs])
  useEffect(() => {
    if (!isModalVisible) {
      setMinutes(0)
      setSeconds(0)
      timerRef.current && clearInterval(timerRef.current)
    }
  }, [isModalVisible])

  return useMemo(
    () =>
      `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`,
    [secs, mins]
  )
}

export default useStopwatchTime
