import { ENDPOINTS } from '__constants__'

const useVerifyCaptchaPublicWizard = () => {
  const verifyToken = async (token) => {
    try {
      const response = await fetch(ENDPOINTS.VERIFY_CAPTCHA, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      })

      return await response.json()
    } catch (error) {
      return { data: { isValid: false }, message: error.message }
    }
  }

  return { verifyToken }
}

export default useVerifyCaptchaPublicWizard
