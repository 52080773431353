import { Col, Row } from '@qonsoll/react-design'
import { useCallback, useMemo } from 'react'

import { NewsTipAdvancedView } from 'domains/NewsTip/components'
import PropTypes from 'prop-types'
import moment from 'moment'

const SummaryStep = ({ value, setValue }) => {
  const { media = {}, title, tags = [], content, location } = value

  const getNewsTipData = useCallback(() => {
    const photoUrls = media?.files
      ?.map((item) => item?.type?.includes('image') && item?.url)
      ?.filter(Boolean)

    const videoUrl =
      media?.files?.find((item) => item?.type?.includes('video'))?.url ?? null

    const _createdAt = { seconds: moment(new Date()).unix() }

    return {
      _createdAt,
      photoUrls,
      videoUrl,
      title,
      tags,
      content
    }
  }, [])

  const computedLocation = useMemo(
    () => ({
      formattedAddress: location?.description
    }),
    []
  )

  const newsTip = getNewsTipData()

  return (
    <Row>
      <Col cw={12}>
        <NewsTipAdvancedView
          newsTip={newsTip}
          location={computedLocation}
          isPreview
          value={value}
          setValue={setValue}
        />
      </Col>
    </Row>
  )
}

SummaryStep.propTypes = {
  value: PropTypes.shape({
    media: PropTypes.object,
    title: PropTypes.string,
    tags: PropTypes.array,
    content: PropTypes.string,
    location: PropTypes.object
  }),

  setValue: PropTypes.func
}

export default SummaryStep
