import {
  Col,
  Container,
  Row,
  Text,
  TextArea,
  Title
} from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useDebounceUpdateTips } from 'domains/NewsTip/hooks'
import { STEP_NAMES } from '../constants'
import { useNewspaperConfig } from 'contexts'
const ContentStep = (props) => {
  const { value, setValue } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const { newspaperId } = useNewspaperConfig()

  // Getting  the debounce call
  const { debouncedSendRequest } = useDebounceUpdateTips()

  /* Handling changes */
  const handleChange = useCallback(
    (event) => {
      const content = event.target.value
      setValue((prev) => ({ ...prev, content }))

      debouncedSendRequest({
        data: {
          ...value,
          content,
          newspaperId
        },
        step: STEP_NAMES.CONTENT,
        newsTipId: value?._id
      })
    },
    [setValue]
  )

  return (
    <Container mb={41}>
      <Row>
        <Col cw={12}>
          <Title textAlign="center" variant="h4" mb={1}>
            {t('Content')}
          </Title>
          <Text textAlign="center" mb={3}>{`${t(
            'Describe what happened and add some details'
          )}.`}</Text>
        </Col>
      </Row>
      <Row noGutters>
        <Col cw={12}>
          <TextArea
            autoSize={{ minRows: 5, maxRows: 15 }}
            value={value?.content}
            onChange={handleChange}
            placeholder={`${t('Add details')}*`}
          />
        </Col>
      </Row>
    </Container>
  )
}

ContentStep.propTypes = {
  value: PropTypes.object,
  setValue: PropTypes.func
}

export default ContentStep
