import { Box, Button, Col, Input, Link, Row } from '@qonsoll/react-design'
import { LS, REGEXPS, WRONG_PASSWORD_ERROR_CODE } from '__constants__'
import {
  useLastSession,
  usePutSessionFormInitialEmail,
  useSessionActions,
  useSignInWithCredentials
} from 'domains/Session/hooks'

import { Form } from 'antd'
import { LastSessionUser } from 'domains/Session/components'
import PropTypes from 'prop-types'
import { useQueryParams } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const LoginForm = ({ login, onError, onForgotPasswordClick, ...rest }) => {
  const { t } = useTranslations()
  /* Using the useLastSession hook to store the last session in the local storage. */
  const lastSession = useLastSession()
  /* Using the useSessionActions hook to get the last session provider. */
  const { getLastSessionProvider } = useSessionActions()
  const { signInWithCredentials, loading } = useSignInWithCredentials()
  const [form] = Form.useForm()

  const isEmailExists = useQueryParams('email')

  const onFinish = async (credentials) => {
    const signInError = await signInWithCredentials(credentials)

    // When user entered differ from last session email
    // and received wrong password error, save this email for ForgotPassword Page
    if (signInError?.code === WRONG_PASSWORD_ERROR_CODE && credentials?.login) {
      localStorage.setItem(LS.LOGIN_ATTEMPT_EMAIL, credentials.login)
    }
    // After success login reset local storage item with this email
    // as this email will be saved to lastSession and it will be used
    // as initial email for login and forgot password screen
    if (!signInError) localStorage.removeItem(LS.LOGIN_ATTEMPT_EMAIL)
  }

  // Used to set initial email into login field if it exist
  usePutSessionFormInitialEmail({ form, isLogin: true })

  const sessionProvider = getLastSessionProvider()

  return (
    <Form
      validateTrigger={['submit']}
      form={form}
      onFinish={onFinish}
      {...rest}>
      <Form.Item
        name="login"
        hidden={isEmailExists && sessionProvider === 'email'}
        normalize={(value) => value.trim()}
        rules={[
          {
            required: true,
            message: t('Please enter valid email or phone number')
          },
          () => ({
            validator(_, value) {
              if (
                REGEXPS.EMAIL_REGEXP.test(value) ||
                REGEXPS.PHONE_REGEXP.test(value)
              ) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('Invalid email or phone number'))
              )
            }
          })
        ]}>
        <Input
          size="large"
          autoFocus
          placeholder={`${t('Email')}/${t('Phone number')}`}
        />
      </Form.Item>
      {lastSession?.email && isEmailExists && sessionProvider === 'email' && (
        <LastSessionUser vertical lastSession={lastSession} />
      )}
      <Form.Item
        name="password"
        rules={[
          { required: true, message: t('Please, enter your password') },
          { min: 6, message: t('Password must be at least 6 characters') }
        ]}>
        <Input.Password
          size="large"
          autoFocus={isEmailExists}
          placeholder={t('Password')}
        />
      </Form.Item>
      <Row noGutters h="between" v="center">
        <Col cw="auto">
          <Box textAlign="center">
            <Link onClick={onForgotPasswordClick}>{t('Forgot password?')}</Link>
          </Box>
        </Col>
        <Col cw={6}>
          <Button
            block
            htmlType="submit"
            type="primary"
            size="large"
            loading={loading}>
            {t('Login')} 🚀
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

LoginForm.propTypes = {
  form: PropTypes.object,
  login: PropTypes.func.isRequired,
  onError: PropTypes.any.isRequired,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  onForgotPasswordClick: PropTypes.func
}

export default LoginForm
