const EMAIL_TO_EXISTENCE = 'userEmailExistance'
const STATISTICS = 'statistic'
const STATISTICS_NEWS_TIPS_BY_NEWSPAPER = 'newsTipsByNewspaper'
const STATISTIC_TOTAL_GIVEN_POINTS = 'totalGivenPoints'
const STATISTIC_POINTS_NOT_USED = 'pointsNotUsed'
const LANGUAGES = 'languages'
const TRANSLATIONS_LOADING = 'translationsLoading'
const TRANSLATIONS = 'translations'

export const RDB_COLLECTIONS = {
  EMAIL_TO_EXISTENCE,
  STATISTICS,
  STATISTICS_NEWS_TIPS_BY_NEWSPAPER,
  STATISTIC_TOTAL_GIVEN_POINTS,
  STATISTIC_POINTS_NOT_USED,
  LANGUAGES,
  TRANSLATIONS_LOADING,
  TRANSLATIONS
}

export default RDB_COLLECTIONS
