import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { PageActions, Spinner } from 'components'
import {
  PointsHistoryFilter,
  PointsHistoryList,
  PointsHistorySortMenu
} from 'domains/PointsHistory/components'
import { useFilter, useListView } from 'hooks'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useGetPointsHistories } from 'domains/PointsHistory/hooks'
import { useTranslations } from 'contexts/Translation'

const PointsHistoriesAllPage = (props) => {
  const { withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* State for controlling list view with localStorage remembering */
  const [listView, setListView] = useListView('pointsHistory')
  /* Getting filter params */
  const filterParams = useFilter('pointsHistory')
  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterData } = filterParams

  /* Fetching pointsHistories */
  const [pointsHistories, loading] = useGetPointsHistories()

  /* Configuring page header */
  const headingProps = {
    title: t('Points histories'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: false,
    sort: false,
    listView: false,
    create: Boolean(
      PATHS.AUTHENTICATED.POINTS_HISTORY_CREATE ||
        PATHS.AUTHENTICATED.POINTS_HISTORY_WIZARD
    )
  }

  return (
    <PageWrapper
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            listView={listView}
            setListView={setListView}
            actions={actions}
            createText={t('Create points history')}
            sortMenu={
              <PointsHistorySortMenu
                filteredData={filterData}
                setFilteredData={setFilterData}
              />
            }
          />
        ) : null
      }
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {filterVisibility && (
          <Col mr={filterVisibility ? 4 : undefined} cw="auto">
            <PointsHistoryFilter {...filterParams} />
          </Col>
        )}
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Points history loading')} />
          </Col>
        ) : (
          <Col>
            <PointsHistoryList pointsHistories={pointsHistories} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

PointsHistoriesAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default PointsHistoriesAllPage
