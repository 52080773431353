import { Menu, MenuItem } from '@qonsoll/react-design'

import styled from 'styled-components'

const StyledMenuItem = styled(MenuItem)`
  box-shadow: none !important;
  height: auto !important;
  color: var(--ql-menu-text-color);

  &:hover {
    background-color: transparent !important;
    color: var(--ql-menu-text-color-active) !important;
  }
`
const StyledMenu = styled(Menu)`
  .ant-menu-item:nth-child(1) {
    padding-left: 0 !important;
  }
`

const MainMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  background-color: var(--ql-menu-bg);
`
export { StyledMenuItem, StyledMenu, MainMenuWrapper }
