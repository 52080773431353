import { Tag } from 'antd'
import styled from 'styled-components'

const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`
const StyledTag = styled(Tag)`
  margin-right: 0;
  color: var(--ql-color-white);
  padding: 4px 8px;
  background-color: var(--ql-color-accent1);
`

export { TagListWrapper, StyledTag }
