import { DOMAIN_NAME, LOCAL_SERVER_HOST_NAME } from '__constants__'
import {
  useGetDomainName,
  useSaveDomainToLocalStorage
} from 'domains/NewspaperConfig/hooks'

import NewspaperConfigContext from './NewspaperConfigContext'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useGetNewspaperConfigInitialData } from 'domains/NewspaperConfig/hooks'
import { useMemo } from 'react'

const domainNameFromHost = window.location.hostname

const isDomainNameFromHostExist =
  typeof domainNameFromHost === 'string' &&
  domainNameFromHost?.startsWith(DOMAIN_NAME) &&
  domainNameFromHost?.length > 0

const isDev = domainNameFromHost === LOCAL_SERVER_HOST_NAME
const isProd = domainNameFromHost !== LOCAL_SERVER_HOST_NAME

const NewspaperConfigProvider = (props) => {
  const { children } = props

  const {
    newspaperConfig,
    isLoading,
    isNewspaperDomainNameExist,
    isNewspaperDomainNameInStorageExist,
    setNewspaperDomainNameInStorage,
    isNewspaperConfigDataLoaded
  } = useGetDomainName({
    domainNameFromHost,
    isDomainNameFromHostExist,
    isDev,
    isProd
  })

  useSaveDomainToLocalStorage(
    newspaperConfig?.domainName,
    setNewspaperDomainNameInStorage
  )

  const isWebTippsEnabled = useMemo(
    () => newspaperConfig?.isWebTippsEnabled || false,
    [newspaperConfig?.isWebTippsEnabled]
  )

  const isUserHasAccessDEV = useMemo(
    () => isDev && isNewspaperConfigDataLoaded && isWebTippsEnabled,
    [isNewspaperConfigDataLoaded, isWebTippsEnabled]
  )

  const isUserHasAccessPROD = useMemo(
    () =>
      isProd &&
      isNewspaperConfigDataLoaded &&
      isDomainNameFromHostExist &&
      isWebTippsEnabled,
    [isNewspaperConfigDataLoaded, isWebTippsEnabled]
  )

  const isUserHasAccess = useMemo(
    () => (isDev ? isUserHasAccessDEV : isUserHasAccessPROD),
    [isUserHasAccessDEV, isUserHasAccessPROD]
  )

  const computedValue = useMemo(
    () => ({
      ...newspaperConfig,
      isUserHasAccess,
      isNewspaperDomainNameExist:
        (isProd && isDomainNameFromHostExist) || isNewspaperDomainNameExist,
      isNewspaperDomainNameInStorageExist,
      isNewspaperConfigDataLoaded
    }),
    [
      isNewspaperConfigDataLoaded,
      isNewspaperDomainNameExist,
      isNewspaperDomainNameInStorageExist,
      newspaperConfig,
      isUserHasAccess
    ]
  )

  useGetNewspaperConfigInitialData(newspaperConfig, isDev)
  return (
    <NewspaperConfigContext.Provider value={computedValue}>
      {isLoading || !isNewspaperConfigDataLoaded ? (
        <Spinner
          backgroundColor="var(--ql-body-bg)"
          text={'Newspaper config is loading'}
        />
      ) : (
        children
      )}
    </NewspaperConfigContext.Provider>
  )
}

NewspaperConfigProvider.propTypes = {
  children: PropTypes.node
}

export default NewspaperConfigProvider
