import { PageActions } from 'components'
import { PageWrapper } from '@qonsoll/react-design'
import { UserAdvancedView } from 'domains/User/components'
import { useProfileBaseActions } from 'domains/User/hooks'
import { useTranslations } from 'contexts/Translation'

const UserShow = () => {
  const { t } = useTranslations()
  const { onEdit } = useProfileBaseActions()

  /* Configuring page actions */
  const actions = { edit: true }

  /* Configuring page header */
  const headingProps = {
    title: t('User profile'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      action={<PageActions actions={actions} onEdit={onEdit} />}
      headingProps={headingProps}>
      <UserAdvancedView />
    </PageWrapper>
  )
}

export default UserShow
