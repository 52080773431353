import { useListVals } from 'react-firebase-hooks/database'
import { ref, update } from 'firebase/database'
import { database } from 'services/firebase'
import { RDB_COLLECTIONS } from '__constants__'
import { useEffect, useMemo } from 'react'
import { useEvent, useLoading } from 'hooks'
import { LANGUAGES } from 'domains/Translation/__constants__'

const useGetLanguages = () => {
  const [languages = [], languagesLoading, languagesError] = useListVals(
    ref(database, RDB_COLLECTIONS.LANGUAGES)
  )

  //no need try catch section here as addDefaultLanguages already has try/catch
  const writeLanguageData = async (defaultLanguage, shortCode) => {
    // Write the new language data in the languages list.
    await update(ref(database, RDB_COLLECTIONS.LANGUAGES), {
      [shortCode]: defaultLanguage
    })
  }

  const addDefaultLanguages = useEvent(async () => {
    try {
      const addDefaultLanguagesPromises = LANGUAGES.map(
        async (language) =>
          await writeLanguageData(language, language.shortCode)
      )

      await Promise.all(addDefaultLanguagesPromises)
    } catch (error) {
      return error
    }
  })

  useEffect(() => {
    if (!languages?.length && !languagesLoading && !languagesError) {
      addDefaultLanguages()
    }
  }, [languages, languagesLoading, languagesError, addDefaultLanguages])

  /* Merge all loading states */
  const loadings = useMemo(
    () => [languagesLoading, !!languagesError],
    [languagesError, languagesLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [languages, loading]
}

export default useGetLanguages
