const breakpoints = {}

breakpoints.breakpoints = [
  '576px',
  '768px',
  '1024px',
  '1280px',
  '1440px',
  '1600px',
  '1920px'
]

export default breakpoints
