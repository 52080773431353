const USERS = 'users'
const LOGS = 'logs'
const EDITORS = 'editors'
const NEWS_TIPS = 'newsTips'
const NEWS_TIPS_COMMENTS = 'tipsComments'
const NEWSPAPERS = 'newspapers'
const STORES = 'stores'
const REWARDS = 'rewards'
const NOTIFICATIONS = 'notifications'
const REJECT_REASONS = 'rejectReasons'
const SUBADMINS = 'subadmins'

// POTENTIALLY USELESS BUT USED IN SOME HOOKS
const TIP_STATUSES = 'tipStatuses'
const USER_STATUSES = 'userStatuses'
const REWARD_STATUSES = 'rewardStatuses'
const REWARD_TYPES = 'rewardTypes'
// POTENTIALLY USELESS BUT USED IN SOME HOOKS

const COUPONS = 'coupons'
const POINTS_HISTORIES = 'pointsHistory'
const WEB_TIPS_CONFIGURATIONS = 'webTippsConfigurations'
const REWARD_OVERVIEW = 'rewardOverview'
const CONTENT = 'contents'
const LOCATIONS = 'locations'

export const COLLECTIONS = {
  USERS,
  LOGS,
  EDITORS,
  NEWS_TIPS,
  NEWS_TIPS_COMMENTS,
  NEWSPAPERS,
  STORES,
  REWARDS,
  NOTIFICATIONS,
  REJECT_REASONS,
  SUBADMINS,
  TIP_STATUSES,
  USER_STATUSES,
  REWARD_STATUSES,

  COUPONS,
  REWARD_TYPES,
  POINTS_HISTORIES,
  WEB_TIPS_CONFIGURATIONS,
  REWARD_OVERVIEW,
  CONTENT,
  LOCATIONS
}

export const USER_EMAIL_EXISTENCE_RDB = 'emailToDraftRecordsExistence'
export default COLLECTIONS
