import { useEffect } from 'react'
import ENVIRONMENTS from '__constants__/environments'

const isDev = ENVIRONMENTS.IS_DEV
const useSaveDomainToLocalStorage = (
  domainName,
  setNewspaperDomainNameInStorage
) => {
  /**
   * @description A hook that sets the newspaper domain name in storage if the isDev flag is true and the newspaperConfig.domainName is a string with a length greater than 0.
   * @param {boolean} isDev - A boolean flag that indicates if the environment is a development environment.
   * @param {Object} newspaperConfig - An object containing the newspaper configuration.
   * @param {Function} setNewspaperDomainNameInStorage - A function that sets the newspaper domain name in storage.
   */
  useEffect(() => {
    if (isDev && typeof domainName === 'string' && domainName?.length > 0) {
      setNewspaperDomainNameInStorage?.(domainName)
    }
  }, [domainName, setNewspaperDomainNameInStorage])
}

export default useSaveDomainToLocalStorage
