import { matchPath, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { PATHNAME_TO_SELECTED_MENU_ITEM } from '__constants__'
import PATHS from 'pages/paths'

const { AUTHENTICATED } = PATHS

const useGetSelectedMenuItem = () => {
  /* Saving selected menu item */
  const [selectedMenuItem, setSelectedMenuItem] = useState()

  /* Get current pathname */
  const { pathname } = useLocation()

  /* Get all routes like array */
  const allRoutesFormatted = Object.values(AUTHENTICATED)

  useEffect(() => {
    /* Find a match current pathname with route */
    const currentPathToRouteArray = allRoutesFormatted.filter(
      (routeFormatted) => matchPath(pathname, routeFormatted)
    )
    /* Get a current pathname */
    const currentPathname = currentPathToRouteArray?.[0]

    /* Set selected menu item */
    setSelectedMenuItem(PATHNAME_TO_SELECTED_MENU_ITEM?.[currentPathname])
  }, [pathname, allRoutesFormatted])

  return selectedMenuItem
}

export default useGetSelectedMenuItem
