import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import {
  useGetRewardInitialValues,
  useRewardBaseActions
} from 'domains/Reward/hooks'

import { RewardAdvancedView } from 'domains/Reward/components'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const RewardShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { rewardId } = useParams()
  /* Fetching initial data from useDocument hook */
  const [initialValues, loading] = useGetRewardInitialValues(rewardId)
  /* Getting base actions for page*/
  const { onBack } = useRewardBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: initialValues?.reward?.name || t('Reward show'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Reward loading')} />
          </Col>
        ) : (
          <Col cw={[12, 10, 6, 6, 4]}>
            <RewardAdvancedView {...initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default RewardShowPage
