import { COLLECTIONS, MOMENT_FORMATS } from '__constants__'
import {
  computeExpireData,
  sortNewsTipsByApprovedDate
} from 'domains/ExpiredPoints/helpers'
import {
  useGetExpiringNewsTips,
  useGetSpentPointsHistory
} from 'domains/ExpiredPoints/hooks'

import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import moment from 'moment'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { useNewspaperConfig } from 'contexts/NewspaperConfig'
import { useUser } from 'domains/User/context'

const useGetExpiringPointsData = () => {
  const { newspaperId } = useNewspaperConfig()
  const { user } = useUser()

  /* Get newspaper document data */
  const newspaperData =
    newspaperId && doc(firestore, COLLECTIONS.NEWSPAPERS, newspaperId)

  const [newspaper, newspaperLoading] = useDocumentData(newspaperData)

  const _pointsExpirationEnabledAt = newspaper?._pointsExpirationEnabledAt
  const arePointsExpiring = newspaper?.arePointsExpiring
  const daysToExpiration = newspaper?.daysToExpiration

  /* Get news tips by user, newspaper, with expiring points */
  const { newsTips, oldestNewsTip, newsTipsLoading } = useGetExpiringNewsTips({
    _pointsExpirationEnabledAt,
    arePointsExpiring,
    newspaperId,
    userId: user?._id
  })

  /* Get newsTips objects by approval date type. Key: approved date, value: array of news tips approved of key. */
  const newsTipsByApprovedData = sortNewsTipsByApprovedDate(newsTips)

  /* Get points history with type spent, by userId, newspaperId, and created after the approval of the oldest type. */
  const [pointsHistory, pointsHistoryLoading] = useGetSpentPointsHistory({
    oldestNewsTip,
    newspaperId,
    userId: user?._id
  })

  /* Get the necessary data to display expired data. */
  const computedExpiredData =
    newsTipsByApprovedData &&
    computeExpireData({ newsTipsByApprovedData, pointsHistory })

  const approvedData = computedExpiredData?.approvedData
  const removedPoints = computedExpiredData?.removedPoints

  /* Computed date of expiration points */
  const dateOfExpirationPoints = moment
    .unix(approvedData?.seconds)
    ?.add(daysToExpiration, 'd')
    .format(MOMENT_FORMATS.MONTH_DAYS_YEAR)

  const computedLoading = useMemo(
    () => newspaperLoading || newsTipsLoading || pointsHistoryLoading,
    [newspaperLoading, newsTipsLoading, pointsHistoryLoading]
  )

  return { dateOfExpirationPoints, removedPoints, loading: computedLoading }
}

export default useGetExpiringPointsData
